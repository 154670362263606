import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/who_wmhd_high_1920px.tmb-1200v.jpg";

function WorldMentalHealth() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="World Mental Health Day" breadcrumbTitle="Events" />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="October 10th"
          endDate="October 10th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/* World Mental Health Day */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            World Mental Health Day
          </h3>
          <p className="text-base font-normal">
            World Mental Health Day is observed annually on October 10. This day
            serves as a global platform to emphasize the significance of mental
            health and to advocate for better mental health practices worldwide.
            The theme for this year underscores the critical need to address
            mental health and well- being.
          </p>
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Importance of World Mental Health Day
          </h3>
          <p className="text-base font-normal">
            World Mental Health Day plays a vital role in raising awareness
            about mental health issues and promoting mental health advocacy.
            This global observance highlights the importance of mental
            well-being, not only for individuals but also for the broader
            community. The theme emphasizes the urgent need to prioritize mental
            health in all domains, which can lead to improved morale, better
            physical & mental health, healthier interpersonal relationships,
            increased productivity and a more supportive society.
          </p>

          {/* Key Facts and Determinants of Mental Health */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Key Facts and Determinants of Mental Health
          </h3>
          <p className="text-base font-normal">
            Mental health is defined as a state of well-being in which
            individuals are able to manage life’s stresses, realize their
            potential, work productively, and contribute to their community. It
            is an essential component of overall health and well-being,
            underpinning our ability to make decisions, build relationships, and
            engage in societal activities. Mental health is influenced by a
            range of determinants:
          </p>
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Individual Factors:
            </span>{" "}
            Psychological and biological elements, such as emotional skills,
            substance use, and genetics, play a role in mental health. For
            instance, strong emotional skills can enhance resilience, while
            genetic predispositions can increase vulnerability to mental health
            issues.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Social Factors:
            </span>{" "}
            Social determinants, including exposure to violence, economic
            instability, and inequality, significantly impact mental health. For
            example, living in poverty or facing violence can heighten the risk
            of developing mental health conditions.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Structural Factors:
            </span>{" "}
            Environmental deprivation, such as poor living conditions and lack
            of access to essential resources, can also undermine mental health.
            These structural issues often exacerbate existing vulnerabilities.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Developmentally Sensitive Risks:
            </span>{" "}
            Risks that occur during sensitive developmental periods, such as
            early childhood, can have profound effects on mental health. Harsh
            parenting and bullying are examples of such risks that can
            negatively impact a child’s mental well-being.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Protective Factors:
            </span>{" "}
            On the flip side, factors such as positive social interactions,
            quality education, and safe neighborhoods serve to strengthen mental
            health and build resilience. These protective factors can help
            individuals navigate and overcome challenges.
          </li>
          </ul>

          {/* Promotion and prevention */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Promotion and Prevention
          </h3>
          <p className="text-base font-normal">
            Promotion and prevention efforts aim to address the determinants of
            mental health by reducing risks, building resilience, and creating
            supportive environments. These interventions can be targeted at
            various levels:
          </p>
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Individual Level:
            </span>{" "}
            Strategies include enhancing personal skills and providing emotional
            support to build individual resilience.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Group Level:
            </span>{" "}
            Programs can be designed for specific populations or communities to
            address their unique mental health needs.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Population Level:
            </span>{" "}
            Broad-based strategies aim to improve mental health across entire
            populations by addressing widespread issues and creating supportive
            environments.
          </li>
      
          </ul>
          <p className="text-base font-normal mt-2">
            Effective mental health promotion and prevention often require a
            multisectoral approach. This involves collaboration across various
            sectors such as education, labor, justice, transport, environment,
            housing, and welfare. The health sector plays a crucial role by
            integrating mental health promotion and prevention into health
            services and advocating for collaborative efforts across different
            sectors.
          </p>

          {/* Key areas of focus include */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Key areas of focus include
          </h3>
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Suicide Prevention:
            </span>{" "}
            Strategies such as limiting access to means, responsible media
            reporting, and implementing social and emotional learning programs
            for adolescents can significantly reduce suicide rates.
            Additionally, banning highly hazardous pesticides is a cost-
            effective intervention for suicide prevention.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Child and Adolescent Mental Health:
            </span>{" "}
            Promoting mental health in children and adolescents involves
            supportive policies, nurturing caregiving, and implementing
            school-based social and emotional learning programs. Enhancing the
            quality of community and online environments also plays a critical
            role.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Workplace Mental Health:
            </span>{" "}
            Addressing mental health in the workplace can be supported through
            legislation, organizational strategies, manager training, and
            providing access to mental health resources for employees.
          </li>
          </ul>

          {/* Mental Health Care and Treatment */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Mental Health Care and Treatment
          </h3>
          <p className="text-base font-normal">
            Effective mental health care involves a community-based approach
            that is accessible, acceptable, and integrated into general health
            services. This approach offers several advantages over institutional
            care, including better recovery outcomes and reduced human rights
            violations. Community-based mental health care typically includes:
          </p>
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Suicide Prevention:
            </span>{" "}
            Strategies such as limiting access to means, responsible media
            reporting, and implementing social and emotional learning programs
            for adolescents can significantly reduce suicide rates.
            Additionally, banning highly hazardous pesticides is a cost-
            effective intervention for suicide prevention.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Child and Adolescent Mental Health:
            </span>{" "}
            Promoting mental health in children and adolescents involves
            supportive policies, nurturing caregiving, and implementing
            school-based social and emotional learning programs. Enhancing the
            quality of community and online environments also plays a critical
            role.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Workplace Mental Health:
            </span>{" "}
            Addressing mental health in the workplace can be supported through
            legislation, organizational strategies, manager training, and
            providing access to mental health resources for employees.
          </li>
          </ul>

          {/* WHO Response and Recommendations */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            WHO Response and Recommendations
          </h3>
          <p className="text-base font-normal">
            The World Health Organization (WHO) has developed the Comprehensive
            Mental Health Action Plan 2013-2030, which outlines key objectives
            for improving mental health globally:
          </p>
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
              Leadership and Governance:
            </span>{" "}
            Strengthen mental health leadership and policy development to ensure
            effective mental health strategies.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Integrated Community-Based Care:
            </span>{" "}
            Enhance the accessibility and coordination of mental health services
            within communities.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Promotion and Prevention:
            </span>{" "}
            Implement strategies to promote mental well-being and prevent mental
            health conditions across populations.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
              Information Systems and Research:
            </span>{" "}
            Improve data collection and research to better understand and
            address mental health needs.
          </li>
          <li className="text-base font-normal mt-2">
            The WHO’s “World Mental Health Report: Transforming Mental Health
            for All” emphasizes three primary paths to transformation:
          </li>
          </ul>
          

          {/* 1. Deepen the Value: */}
          <ul className="list-disc list-inside mt-3">
          <li className="text-base text-black leading-relaxed mt-4">
            <span className="text-base mb-2 text-black font-semibold">
               Deepen the Value:
            </span>{" "}
            Increase the importance given to mental health by individuals,
            communities, and governments, and align this value with commitment
            and investment.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
            Reshape Environments:
            </span>{" "}
            Modify the physical, social, and economic characteristics of
            environments to better support mental health and prevent conditions.
          </li>
          <li className="text-base text-black leading-relaxed">
            <span className="text-base mb-2 text-black font-semibold">
            Strengthen Care:
            </span>{" "}
            Develop a comprehensive network of accessible, affordable, and
            quality mental health services to meet the full spectrum of mental
            health needs.
          </li>
          </ul>
          <p className="text-base font-normal mt-2">
            The WHO continues to work both nationally and internationally to
            provide strategic leadership, evidence, tools, and technical support
            to enhance mental health responses and achieve better mental health
            outcomes for all.
          </p>

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
            On World Mental Health Day, and beyond, it is crucial to engage in
            conversations about mental health. Checking in with friends, family,
            peers, and colleagues can provide support and strengthen
            connections. Additionally, participating in initiatives such as Tea
            & Talk events helps raise vital funds and awareness for mental
            health services, contributing to the broader effort of improving
            mental health for everyone.
          </p>

          {/* Event Hashtags*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Event Hashtags</h3>
          <p className="text-base font-normal">
            When participating in Mental Health Day and sharing your support on
            social media, consider using event hashtags to connect with others
            who are passionate about raising awareness:
          </p>
          <ul>
            <li className="text-base font-semibold mt-4 mb-2">#WMHD2024</li>
            <li className="text-base font-semibold mb-2">
              #MentalHealthInTheWorkplace
            </li>
            <li className="text-base font-semibold mb-2">#TeaAndTalk2024</li>
            <li className="text-base font-semibold mb-2">
              #PrioritizeMentalHealth
            </li>
            <li className="text-base font-semibold mb-2">
              #MentalHealthAwarenessDay
            </li>
            <li className="text-base font-semibold mb-2">
              #MentalHealthMatters
            </li>
            <li className="text-base font-semibold mb-2">
              #WorldMentalHealthDay
            </li>
            <li className="text-base font-semibold mb-2">
              #SupportMentalHealth
            </li>
            <li className="text-base font-semibold mb-2">
              #MentalHealthForAll
            </li>
            <li className="text-base font-semibold mb-2">#WellbeingAtWork</li>
          </ul>
        </EventsText>
        <Pagination
          prevLink="/national-mental-health-awareness"
          prevText="National Mental Health Awareness Month"
          nextLink="/mental-illness-awareness"
          nextText="Mental Illness Awareness Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default WorldMentalHealth;
