import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import img from "../assets/logoo-2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { motion } from "framer-motion";
export default function TopNav() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const dropdownTimeout = useRef(null); // Ref to handle delay

  // Function to check if the link is active
  const isActive = (path) =>
    location.pathname === path || location.pathname.startsWith(`${path}/`);

  return (
    <nav className="fixed top-0 z-50 w-full bg-white shadow-lg  items-center justify-between  ">
      <div className=" bg-green-700 text-white text-sm py-2 px-6 flex items-center justify-between w-full ">
        {/* Left - Social Media Links */}
        <div className="flex gap-3 hidden lg:flex">
          <a
            href="https://www.facebook.com/profile.php?id=61574094790029"
            className="hover:text-gray-400"
            target="_blank"
          >
            <FaFacebook />
          </a>

          <a
            href="https://www.instagram.com/habibanazimkhan/"
            className="hover:text-gray-400"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/mindful-connection-hub"
            className="hover:text-gray-400"
            target="_blank"
          >
            <FaLinkedin />
          </a>
        </div>

        {/* Middle - Offer Marquee */}
        <motion.div
          className="overflow-hidden whitespace-nowrap"
          animate={{ x: ["100%", "-100%"] }}
          transition={{ ease: "linear", duration: 10, repeat: Infinity }}
        >
          <a href="Contact-MCH-By-Habiba-Nazim-Khan" className="font-semibold ">
            🎉 60% Discount: Awareness Initiatives Offer 🎉
          </a>
        </motion.div>

        {/* Right - Contact / Other Info */}
        <div className="flex gap-4">
          <a
            href="tel:+923040186444"
            className="hidden md:block hover:underline font-semibold"
          >
            📞 +923040186444
          </a>

          <a
            href="mailto:contact@mindfulch.com"
            className="hidden md:block hover:underline font-semibold"
          >
            ✉️ contact@mindfulch.com
          </a>
        </div>
      </div>
      <div className="container  mx-auto flex flex-wrap items-center justify-between px-6">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="/"
          >
            <img
              src={img}
              alt="Mindful Connection Hub Logo"
              className="h-12 w-52 inline-block"
              width="128"
              height="48"
            />
          </a>

          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
            aria-label="Toggle navigation menu"
            aria-expanded={navbarOpen}
          >
            <FontAwesomeIcon icon={faBars} className="text-gray-800" />
          </button>
        </div>

        <div
          className={`lg:flex flex-grow items-center  ml-auto ${
            navbarOpen ? "block rounded shadow-lg" : "hidden"
          }`}
          id="example-navbar"
        >
          <ul className="flex flex-col lg:flex-row items-center list-none lg:ml-auto">
            {/* Home, Services, Blogs, Contact Us */}
            {[
              { path: "/", name: "Home" },
              { path: "/services", name: "Services" },

              { path: "/blogs", name: "Blogs" },
              { path: "/about", name: "About Us" },
              { path: "/Contact-MCH-By-Habiba-Nazim-Khan", name: "Contact Us" },
            ].map((item, index) => (
              <li className="flex items-center" key={index}>
                <a
                  href={item.path}
                  className={`px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold ${
                    isActive(item.path)
                      ? "text-[#71a371]"
                      : "text-gray-800 hover:text-[#71a371]"
                  }`}
                >
                  {item.name}
                </a>
              </li>
            ))}

            {/* Bio Dropdown (Contains About, Gallery, Events) */}
            {/* <li
              className="relative lg:block hidden"
              onMouseEnter={() => {
                clearTimeout(dropdownTimeout.current);
                setDropdownOpen(true);
              }}
              onMouseLeave={() => {
                dropdownTimeout.current = setTimeout(() => {
                  setDropdownOpen(false);
                }, 200); 
              }}
            >
              
              <button className="px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold text-gray-800 hover:text-[#71a371]">
                More
              </button>

             
              {dropdownOpen && (
                <ul
                  className="absolute -left-4 mt-4 w-28  bg-white shadow-lg rounded-bl-md rounded-br-md"
                  onMouseEnter={() => {
                    clearTimeout(dropdownTimeout.current); 
                    setDropdownOpen(true);
                  }}
                  onMouseLeave={() => {
                    dropdownTimeout.current = setTimeout(() => {
                      setDropdownOpen(false);
                    }, 200);
                  }}
                >
                  {[
                    { path: "/about", name: "About" },
                    { path: "/gallery", name: "Gallery" },
                    { path: "/events", name: "Events" },
                  ].map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.path}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-[#71a371] text-sm font-medium"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li> */}

            {/* Mobile View: Bio Menu without Dropdown */}
            {/* <li className="lg:hidden flex flex-col ">
              <a
                href="/about"
                className="px-3 py-4 text-xs uppercase font-bold text-gray-800 hover:text-[#71a371]"
              >
                About
              </a>
              <a
                href="/gallery"
                className="px-3 py-4 text-xs uppercase font-bold text-gray-800 hover:text-[#71a371]"
              >
                Gallery
              </a>
              <a
                href="/events"
                className="px-3 py-4 text-xs uppercase font-bold text-gray-800 hover:text-[#71a371]"
              >
                Events
              </a>
            </li> */}

            {/* Make Appointment Button */}
            <li className="flex items-center">
              <a
                href="/Contact-MCH-By-Habiba-Nazim-Khan"
                aria-label="Book an appointment"
                className="bg-gradient-to-r from-[#71a371] to-[#61987a] text-white font-semibold py-3 px-6 rounded-full text-sm shadow-md transition-all duration-300 ease-in-out 
  hover:from-[#61987a] hover:to-[#4b7b65] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 inline-block mb-2 lg:mb-0"
              >
                Make Appointment
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
