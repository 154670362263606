"use client";
import React, { useState } from "react";
import GalleryModal from "../components/GalleryModal";
import Footer from "./Footer";
import InfoSection from "./Comp/InfoSection";
import TopNav from "./TopNav";
import Header from "./Comp/Header";
import img0 from "../assets/flowers-crop-4.webp";

// Import images
import galleryImage1 from "../assets/psychology counseling in Multan-3.webp";
import galleryImage2 from "../assets/Mental Health psychologist in-Multan.webp";
import galleryImage3 from "../assets/IMG_1855.webp";
import galleryImage4 from "../assets/psychology counseling in Multan-2.webp";
import galleryImage5 from "../assets/WhatsApp Image 2025-02-03 at 6.19.09 PM.webp";
import galleryImage6 from "../assets/Habiba Nazim Khan MCH Clinic.webp";
import galleryImage7 from "../assets/Psychologist - Habiba Nazim Khan.webp";
import galleryImage8 from "../assets/mamabout.webp";
import galleryImage9 from "../assets/IMG_1860.webp";
import galleryImage10 from "../assets/3d190386-12b4-4cae-8bff-1b0e273c4928.webp";
import galleryImage11 from "../assets/1738669413836.jpg";
import WhatsAppButton from "./Comp/WhatsAppButton";

export const galleryFilterData = [
  {
    id: 1,
    src: galleryImage1,
    filterCategory: ["design"],
    col: 6,
    lg: 3,
  },
  {
    id: 2,
    src: galleryImage2,
    filterCategory: ["photography"],
    col: 6,
    lg: 3,
  },
  {
    id: 3,
    src: galleryImage3,
    filterCategory: ["design"],
    col: 6,
    lg: 3,
  },
  {
    id: 4,
    src: galleryImage4,
    filterCategory: ["photography"],
    col: 6,
    lg: 3,
  },
  {
    id: 5,
    src: galleryImage5,
    filterCategory: ["branding"],
    col: 6,
    lg: 3,
  },
  {
    id: 6,
    src: galleryImage6,
    filterCategory: ["photography", "branding"],
    col: 6,
    lg: 3,
  },
  {
    id: 7,
    src: galleryImage7,
    filterCategory: ["design"],
    col: 6,
    lg: 3,
  },
  {
    id: 8,
    src: galleryImage8,
    filterCategory: ["development"],
    col: 6,
    lg: 3,
  },
  {
    id: 9,
    src: galleryImage9,
    filterCategory: ["photography"],
    col: 6,
    lg: 6,
  },
  {
    id: 10,
    src: galleryImage10,
    filterCategory: ["development"],
    col: 6,
    lg: 3,
  },
  {
    id: 10,
    src: galleryImage11,
    filterCategory: ["development"],
    col: 6,
    lg: 3,
  },
];

export const filterCategories = [
  { name: "All", filter: "filter-item" },
  { name: "Photography", filter: "photography" },
  { name: "Design", filter: "design" },
  { name: "Branding", filter: "branding" },
  { name: "Development", filter: "development" },
];

const getFilteredProjects = (filterData) =>
  filterData === "filter-item"
    ? galleryFilterData
    : galleryFilterData.filter((project) =>
        project.filterCategory.includes(filterData)
      );

function GalleryFilter() {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);
  const [activeFilter, setActiveFilter] = useState("filter-item");
  const filteredProjects = getFilteredProjects(activeFilter);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleClick = (src, index) => {
    setCurrentIndex(index);
    setClickedImg(src);
  };

  const handelRotationRight = () => {
    const totalLength = galleryFilterData.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = galleryFilterData[0].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = galleryFilterData.filter((item) => {
      return galleryFilterData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = galleryFilterData.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = galleryFilterData[totalLength - 1].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = galleryFilterData.filter((item) => {
      return galleryFilterData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <>
      <TopNav />
      <Header leftTitle="Gallary" breadcrumbTitle="Gallery" />
      <section>
      <div className="flex items-center justify-center space-x-4 relative ">
            {/* Image on the left, fixed in place */}
            <div className="absolute inset-0 -z-10">
              <img
                src={img0}
                alt="Background"
                className="w-auto h-auto object-cover opacity-40 lg:opacity-50 "
              />
            </div>
          {/* Text in the center */}
          <div className="relative z-10 text-center w-full max-w-2xl px-8 lg:py-4">
            <h2 className=" mt-20 text-2xl lg:text-3xl py-1 text-transparent bg-clip-text bg-gradient-to-r from-[#68d468] to-[#5aa37b] font-sans font-semibold rounded-lg mb-2">
              Our Visual Journey
            </h2>
            <p className="text-base lg:text-lg font-semibold text-gray-600 px-4 ">
              Join us for Awareness, Empowerment, Transformation & Mental
              Well-being
            </p>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto px-4 mt-10 md:mt-0">
          {/* Filter Buttons */}
          {/* <div className="text-center mb-8">
            <ul className="flex flex-wrap justify-center gap-4">
              {filterCategories.map(({ name, filter }) => (
                <li
                  key={name}
                  className={`cursor-pointer px-4 py-2 rounded-full text-xs md:text-sm font-medium uppercase transition-colors duration-300 ${
                    filter === activeFilter
                      ? "bg-[#67a86b] text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  onClick={() => handleFilterChange(filter)}
                >
                  {name}
                </li>
              ))}
            </ul>
          </div> */}

          {/* Gallery Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-1 px-8 md:px-6 lg:px-20">
            {filteredProjects.map((item, index) => (
              <div
                key={item.id}
                className="relative overflow-hidden  hover:shadow-xl transition-shadow duration-300"
              >
                <img
                  src={item.src}
                  alt={`Gallery ${item.id}`}
                  width={400}
                  height={300}
                  className="w-full h-64 object-cover transform scale-100 transition-transform duration-300 hover:scale-105"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <div
                    onClick={() => handleClick(item.src, index)}
                    className="cursor-pointer p-4 text-white rounded-full"
                  >
                    <span className="text-7xl font-extralight">+</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Gallery Modal */}
        {clickedImg && (
          <GalleryModal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
            currentIndex={currentIndex}
            length={galleryFilterData.length}
          />
        )}
      </section>
      <InfoSection />
      <WhatsAppButton />
      <Footer />
    </>
  );
}

export default GalleryFilter;
