import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/pexels-pixabay-163431.jpg";
import imgy from "../../assets/GettyImages-1335839577-43aabea707074ae59e49d709f83c77b4.jpg";
import imgz from "../../assets/360_F_477102217_aP7E3WdgPKK3sxv9beylmt7rDWEgIPvw.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/young-adult.jpg";
import img2 from "../../assets/360_F_82442519_3dyKdM0EXzhBO027kDLkeJHuPLeplLlf.jpg";
import img3 from "../../assets/hand-drawn-step.avif";

function CareerCounseling() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Anger Management",
      description:
        "Anger Management refers to techniques and strategies designed to help individuals control...",
      link: "/angermanage",
    },
    {
      imgSrc: imgy,
      title: "Interpersonal Therapy",
      description:
        "Interpersonal Therapy (IPT) is a structured and time-limited psychotherapy that focuses on...",
      link: "/interpersonal",
    },
    {
      imgSrc: imgz,
      title: "Speech Therapy",
      description:
        "Speech therapy is a specialized field focused on diagnosing and treating communication and swallowing...",
      link: "/speechtherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Career Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            Career counseling is a professional service that helps individuals
            understand their strengths, interests, and values to make informed
            decisions about their career paths. It involves guidance on
            education and career options, support in developing job search
            strategies, and assistance with resume writing and interview
            preparation. Career counselors use various assessments and toolsto
            help clients identify suitable careers and develop skills necessary
            for career success. This process aims to align an individual’s goals
            with their professional aspirations, enhancing job satisfaction and
            overall well-being. Career counseling is valuable for people at any
            stage of their career, whether they are entering the workforce,
            seeking a career change, or advancing in their current field.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Elements of Career Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Person-Centered Approach:
            </span>{" "}
            Focus on understanding the individual’s unique interests, values,
            strengths, and personality traits. Encourage self-reflection and
            self-discovery to align career choices with personal identity.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Assessment Tools:
            </span>{" "}
            Use standardized assessments and psychometric tests to provide
            insights into suitable career paths.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Cognitive Behavioral Techniques:
            </span>{" "}
            Address negative thought patterns and beliefs that may hinder career
            progress.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Motivational Interviewing:
            </span>{" "}
            Use motivational interviewing techniques to enhance the client’s
            intrinsic motivation. Help them explore and resolve ambivalence
            about career decisions and develop a commitment to change.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Solution-Focused Approach:
            </span>{" "}
            Focus on identifying solutions and strengths rather than problems.
            Help clients envision their desired career outcomes and explore
            practical steps to achieve them.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Work-Life Balance:
            </span>{" "}
            Address issues related to work-life balance and overall well- being.
            Help clients find careers that support their lifestyle preferences
            and personal values.
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Career Counseling
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">1.</span>{" "}
            Self-Awareness
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">2.</span>{" "}
            Clarified Goals
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">3.</span>{" "}
            Informed Decision-Making
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">4.</span>{" "}
            Increased Confidence
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">5.</span>{" "}
            Career Transition Support
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">6.</span>{" "}
            Enhanced Motivation
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">7.</span>{" "}
            Adaptability
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">8.</span>{" "}
            Mental Health Support
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Career Counseling" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/counseling"
          prevText="Counseling"
          nextLink="/speechtherapy"
          nextText="Speech Therapy"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default CareerCounseling;
