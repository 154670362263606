/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import TopNav from "./TopNav";
import Footer from "./Footer";
import imgNature from "../assets/Best Mental health services Multan.webp";
import {
  FaMailBulk,
  FaMapMarkerAlt,
  FaRegClock,
  FaPhoneAlt,
  FaTimes,
} from "react-icons/fa";
import Header from "./Comp/Header";
import WhatsAppButton from "./Comp/WhatsAppButton";
import { Helmet } from "react-helmet-async";
import BookingSectionContact from "./Comp/AppointmentFormContact";
function Contact() {
  const [isMapOpen, setIsMapOpen] = useState(false);

  const openMap = () => {
    setIsMapOpen(true);
  };

  const closeMap = () => {
    setIsMapOpen(false);
  };
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id":
          "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan#webpage",
        url: "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan",
        name: "Contact Us",
        isPartOf: {
          "@id": "https://www.mindfulch.com/#website",
        },
        breadcrumb: {
          "@id":
            "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan#breadcrumb",
        },
        inLanguage: "en",
      },
      {
        "@type": "WebSite",
        "@id": "https://www.mindfulch.com/#website",
        url: "https://www.mindfulch.com/",
        name: "Mindful Connection Hub",
        publisher: {
          "@id": "https://www.mindfulch.com/#organization",
        },
      },
      {
        "@type": "BreadcrumbList",
        "@id":
          "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.mindfulch.com/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Contact Us",
            item: "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan",
          },
        ],
      },
      {
        "@type": "Organization",
        "@id": "https://www.mindfulch.com/#organization",
        name: "Mindful Connection Hub",
        url: "https://www.mindfulch.com/",
        logo: "https://www.mindfulch.com/static/media/Logoo.1797d109abd253ceea64.webp",
        sameAs: [
          "https://www.facebook.com/profile.php?id=61561362483687",
          "https://www.linkedin.com/company/mindful-connection-hub",
          "https://www.instagram.com/habibanazimkhan/",
          "https://www.tiktok.com/@habibanazimkhan1",
          "https://www.youtube.com/@HabibaNazimKhan",
        ],
        contactPoint: [
          {
            "@type": "ContactPoint",
            telephone: "+92 3040186444",
            email: "Contact@mindfulch.com",
            contactType: "customer support",
            areaServed: "Worldwide",
            availableLanguage: ["English", "Urdu"],
          },
        ],
      },
      {
        "@type": "ContactPage",
        "@id":
          "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan#contactpage",
        url: "https://www.mindfulch.com/Contact-MCH-By-Habiba-Nazim-Khan",
        name: "Contact Mindful Connection Hub",
        about: {
          "@id": "https://www.mindfulch.com/#organization",
        },
        inLanguage: "en",
      },
    ],
  };

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9zf32n4", // Your EmailJS Service ID
        "template_fe0s3qt", // Your EmailJS Template ID
        form.current,
        "Jm-LJ2CPrEQ_7f06V" // Your EmailJS Public Key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
          form.current.reset();
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Error sending message. Please try again.");
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>
          Contact MCH By Habiba Nazim Khan | Mental health clinic Multan
        </title>
        <meta
          name="description"
          content="Book a session with Mental Health psychologist in Multan or get in touch with MCH by Habiba Nazim Khan regarding Multan Mental health services."
        />
        <link rel="canonical" href="https://www.mindfulch.com/" />

        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      {/* <MetaTags page="Contact-MCH-By-Habiba-Nazim-Khan" /> */}
      <TopNav />

      <main>
        <Header
          leftTitle="Contact MCH By Habiba Nazim Khan"
          breadcrumbTitle="Contact us"
        />
        <BookingSectionContact />
        <section
          className="py-12 bg-fixed bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('${imgNature}')` }}
        >
          <div className="text-center ">
            <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-100 ">
              For General{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
                Inquiries
              </span>
            </h2>
          </div>
          <div className="flex items-center justify-center p-6 ">
            <div className="w-full max-w-5xl p-2 md:p-4 lg:p-6 bg-[#f3f7f4] shadow-lg rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Form Section */}
              <div className="p-8">
                <h2 className="text-3xl font-semibold mb-6 text-stone-800">
                  Send a Message
                </h2>
                <form ref={form} onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <input
                      type="text"
                      name="from_name"
                      placeholder="Name"
                      className="w-full p-3 border border-gray-400 rounded-md focus:outline-none focus:border-[#4a7d64]"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email_id"
                      placeholder="Email Address"
                      className="w-full p-3 border border-gray-400 rounded-md focus:outline-none focus:border-[#4a7d64]"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      className="w-full p-3 border border-gray-400 rounded-md focus:outline-none focus:border-[#4a7d64]"
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      name="message"
                      placeholder="Message"
                      rows="4"
                      className="w-full p-3 border border-gray-400 rounded-md focus:outline-none focus:border-[#4a7d64]"
                      required
                    />
                  </div>
                  <button
                    className="bg-[#4a7d64] text-white active:bg-lime-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mt-10 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>

              {/* Contact Information Section */}
              <div className="p-8 bg-[#e1ece1] text-stone-700 rounded-lg shadow-md md:mt-0 mt-6">
                <h3 className="text-lg font-semibold flex items-center text-stone-800">
                  <span className="text-[#4a7d64] text-lg mr-2">
                    <FaPhoneAlt />
                  </span>
                  Call
                </h3>
                <div className="mb-6 text-stone-800 hover:underline">
                <a href="tel:0616510016" >061-6510016</a>
                </div>

                <h3 className="text-lg font-semibold flex items-center text-stone-800">
                  <span className="text-[#4a7d64] text-lg mr-2">
                    <FaMailBulk />
                  </span>
                  Email
                </h3>
                <div className="mb-6">
                  <a
                    href="mailto:info@mindfulch.com"
                    className="text-stone-700 hover:underline"
                  >
                    Contact@mindfulch.com
                  </a>
                </div>

                <h3 className="text-lg font-semibold flex items-center text-stone-800">
                  <span className="text-[#4a7d64] text-lg mr-2">
                    <FaMapMarkerAlt />
                  </span>
                  Address
                </h3>
                <p className="mb-6 text-stone-700">
                  42-43 B, Model Town, Haseeb Street,
                  <br />
                  Multan, Pakistan.
                </p>

                <h3 className="text-lg font-semibold flex items-center text-stone-800">
                  <span className="text-[#4a7d64] text-lg mr-2">
                    <FaRegClock />
                  </span>
                  Hours
                </h3>
                <p className="text-stone-700">Mon – Sat: 11am – 9pm</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center py-12">
            <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-700 ">
              Location To Mental Health{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
                <br /> Mental Health Clinic Multan
              </span>
            </h2>
          </div>
          <div id="direction" classname="google-map-container">
            <iframe
              width="100%"
              height="450"
              frameborder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.540169040174!2d71.49348654902591!3d30.24473063565357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b35d1895d240f%3A0xf4f27e5b3de72dbb!2sMindfulConnection%20Hub!5e0!3m2!1sen!2s!4v1699195768584!5m2!1sen!2s"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section aria-labelledby="appointment-section">
          <div className="w-full flex flex-col md:flex-row items-center">
            {/* Schedule Appointment Section */}
            <div
              className="flex flex-col items-center bg-[#579c54] hover:bg-[#538c51] w-full px-6 py-10 space-y-6 md:space-y-0 md:space-x-6 min-h-[300px]"
              role="region"
              aria-labelledby="schedule-heading"
            >
              {/* Schedule Your Appointment Today */}
              <div className="flex flex-col items-center space-y-2 text-center">
                <span className="text-white text-3xl" aria-hidden="true">
                  <FaRegClock />
                </span>
                <div className="text-white">
                  <h2
                    id="schedule-heading"
                    className="text-xl sm:text-2xl md:text-3xl font-semibold leading-relaxed mb-4 mt-4"
                  >
                    Schedule Your Meeting!
                  </h2>
                  <p className="text-lg   leading-relaxed text-center mb-4">
                    Mon - Sat: 11AM - 9PM
                    <br />
                    Online Session: 45 minutes
                  </p>
                </div>
                {/* Make Appointment */}
                <a
                  href="/booknow"
                  aria-label="Book an appointment online"
                  className="bg-white text-sm font-semibold py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition focus:ring-2 focus:ring-[#579c54] focus:outline-none"
                >
                  Make Appointment
                </a>
              </div>
            </div>

            {/* Visit Clinic Section */}
            <div
              className="flex flex-col items-center bg-[#477a61] hover:bg-[#456f57] w-full px-6 py-10 space-y-6 md:space-y-0 md:space-x-6 min-h-[300px]"
              role="region"
              aria-labelledby="visit-heading"
            >
              {/* Visit Clinic */}
              <div className="flex flex-col items-center space-y-2 text-center">
                <span className="text-white text-3xl" aria-hidden="true">
                  <FaMapMarkerAlt />
                </span>
                <div className="text-white">
                  <h2
                    id="visit-heading"
                    className="text-xl sm:text-2xl md:text-3xl font-semibold leading-relaxed mb-4 mt-4"
                  >
                    Visit Your Mental Health psychologist in Multan
                  </h2>
                  <p className="text-lg   leading-relaxed text-center mb-4">
                    42 - 43 B, Haseeb Street, Model Town,
                    <br />
                    Multan, Pakistan.
                  </p>
                </div>
                {/* Get Direction Button */}
                <a
                  href="https://www.google.com/maps/dir//MCH+by+Habiba+Nazim+Khan+Model+Haseeb+Street+B,+Town,+Multan/@30.244726,71.4952878,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x393b35d1895d240f:0xf4f27e5b3de72dbb!2m2!1d71.4952878!2d30.244726?entry=ttu&g_ep=EgoyMDI1MDMzMS4wIKXMDSoASAFQAw%3D%3D"
                  aria-label="Open Google Maps for directions"
                  target="_blank"
                  className="bg-white text-sm font-semibold py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition focus:ring-2 focus:ring-[#477a61] focus:outline-none"
                >
                  Get Direction
                </a>
              </div>

              {/* Fullscreen Map Modal */}
              {isMapOpen && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                  role="dialog"
                  aria-labelledby="map-title"
                  aria-describedby="map-description"
                  aria-live="polite"
                >
                  <div className="relative w-full h-full">
                    <h2 id="map-title" className="sr-only">
                      Location Map
                    </h2>
                    <p id="map-description" className="sr-only">
                      Google Maps showing the location of Mindful Connection
                      Hub.
                    </p>
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.540169040174!2d71.49348654902591!3d30.24473063565357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b35d1895d240f%3A0xf4f27e5b3de72dbb!2sMindfulConnection%20Hub!5e0!3m2!1sen!2s!4v1699195768584!5m2!1sen!2s"
                      allowFullScreen
                      aria-hidden="false"
                    ></iframe>
                    <button
                      onClick={closeMap}
                      aria-label="Close map"
                      className="absolute top-4 right-4 bg-white text-black py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition focus:ring-2 focus:ring-gray-500 focus:outline-none"
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <WhatsAppButton />
      </main>

      <Footer />
    </>
  );
}

export default Contact;
