import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/360_F_82442519_3dyKdM0EXzhBO027kDLkeJHuPLeplLlf.jpg";
import imgy from "../../assets/istockphoto-472977300-612x612.jpg";
import imgz from "../../assets/pexels-olly-3755761.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/6-techniques-for-stress-web.jpg";
import img2 from "../../assets/1695048915502.jpg";
import img3 from "../../assets/GettyImages-1339242968-47adc76d745c4d9c824497799562c94c.jpg";
import img4 from "../../assets/360_F_835531648_0dXBBYqVujtXM0LRjnlGd6Rsc8gtJPQw.jpg";
import img5 from "../../assets/Meditation007-676x451.jpg";

function Relaxation() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Career Counseling",
      description:
        "Career counseling is a professional service that helps individuals understand their strengths, interests...",
      link: "/career",
    },
    {
      imgSrc: imgy,
      title: "Special Needs Issues",
      description:
        "Special needs issues refer to a variety of physical, developmental, behavioral, or emotional conditions...",
      link: "/specialneeds",
    },
    {
      imgSrc: imgz,
      title: "Stress Management",
      description:
        "Stress is a natural physical and emotional response to challenges, demands, or threats...",
      link: "/stressmanage",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Relaxation Techniques
          </h1>
          <p className="text-base text-black leading-relaxed">
            Relaxation techniques in the therapeutic process refer to a set of
            practices and strategies used to help individuals reduce stress,
            anxiety, and physical tension, thereby promoting mental and
            emotional well-being. These techniques include methods such as deep
            breathing, progressive muscle relaxation, guided imagery,
            mindfulness meditation, and biofeedback, among others. By
            incorporating these practices into therapy, clients can learn to
            manage their stress responses, improve their ability to cope with
            challenging situations, and enhance their overall quality of life.
            These techniques are often tailored to individual needs and can be
            practiced both within therapy sessions and independently by clients
            in their daily lives.{" "}
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Deep Breathing
          </h1>
          <p className="text-base text-black leading-relaxed">
            Deep breathing, also known as diaphragmatic breathing, is a
            relaxation technique that involves taking slow, deep, and deliberate
            breaths to reduce stress and promote relaxation. This practice
            focuses on using the diaphragm to fully expand the lungs, allowing
            more oxygen to enter the body and promoting a sense of calm. To
            perform deep breathing, one should sit or lie down comfortably,
            place one hand on the abdomen and the other on the chest, and inhale
            deeply through the nose, feeling the abdomen rise. Then, exhale
            slowly through the mouth, allowing the abdomen to fall. Repeating
            this process for several minutes can help lower heart rate, decrease
            muscle tension, and reduce anxiety, making it a simple yet effective
            tool for managing stress and enhancing overall well-being.{" "}
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Progressive Muscle Relaxation
          </h1>
          <p className="text-base text-black leading-relaxed">
            Progressive Muscle Relaxation (PMR) is a relaxation technique that
            involves systematically tensing and then slowly releasing different
            muscle groups in the body to reduce physical tension and promote
            relaxation. This practice typically starts from the feet and
            progresses upwards to the head, or vice versa, allowing individuals
            to focus on the sensation of releasing tension from each specific
            muscle group. The process enhances body awareness and helps to
            interrupt the cycle of stress and tension. The benefits of PMR
            include reduced anxiety and stress levels, improved sleep quality,
            decreased muscle tension and pain, enhanced concentration and focus,
            and an overall sense of well-being. By practicing PMR regularly,
            individuals can develop a greater ability to recognize and manage
            physical stress responses, leading to improved mental and physical
            health.
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            Mindfulness Meditation
          </h1>
          <p className="text-base text-black leading-relaxed">
            Mindfulness meditation is a practice that involves focusing on the
            present moment with an attitude of non-judgmental awareness. This
            technique encourages individuals to observe their thoughts,
            feelings, and bodily sensations without attachment or reaction,
            fostering a state of calm and clarity. Practitioners typically sit
            quietly, focus on their breathing, and gently bring their attention
            back to the present whenever the mind wanders. The benefits of
            mindfulness meditation include reduced stress and anxiety, improved
            emotional regulation, enhanced concentration and cognitive function,
            better sleep quality, and greater overall well- being. By
            cultivating a mindful approach to daily life, individuals can
            develop resilience to stress, increase self-awareness, and
            experience a deeper sense of peace and fulfillment.
          </p>
        </>
      ),
    },
    {
      img: img5,
      text: (
        <>
          <h1 className="text-2xl font-base mb-4 text-black leading-relaxed">
            Visualization
          </h1>
          <p className="text-base text-black leading-relaxed">
            Visualization, also known as guided imagery, is a relaxation
            technique that involves creating vivid mental images of calming and
            peaceful settings or scenarios to promote relaxation and reduce
            stress. This practice engages the imagination to transport the mind
            to a serene place, such as a beach, forest, or any location that
            evokes tranquility. By focusing on detailed sensory
            experiences—sights, sounds, smells, and textures—individuals can
            immerse themselves in the imagined environment, which helps to
            distract from stressors and elicit a relaxation response. The
            benefits of visualization include reduced anxiety and stress levels,
            improved mood, enhanced ability to cope with difficult situations,
            increased motivation and goal attainment, and overall better mental
            health. Regular practice of visualization can also improve focus and
            concentration, making it a valuable tool for both relaxation and
            mental performance.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Relaxation Techniques" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/specialneeds"
          prevText="Special Needs Issues"
          nextLink="/psychotherapy"
          nextText="Psychotherapy"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default Relaxation;
