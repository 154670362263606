import React from 'react'


function Header({ leftTitle, breadcrumbTitle }) {
  return (
   <>
      <div className="bg-gradient-to-r from-[#99b6a9] to-[#75c87a] py-5 mt-24 px-4 md:px-10   relative z-5">
  <div className="container mx-auto  py-4">
    <div className="flex justify-between items-center">
      {/* Left Title */}
      <div className="w-full md:w-1/2">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold text-white">
          {leftTitle}
        </h1>
      </div>

      {/* Right Breadcrumb */}
      <div className="w-full md:w-1/2 text-right">
        <div className="breadcrumbs-title text-sm text-gray-600">
          <span className="text-white">
            <a
              href="/"
              className="home text-white text-sm md:text-base font-medium hover:text-[#455e46]"
            >
              Home
            </a>
          </span>
          <span className="mx-2 text-white">&gt;</span>
          <span className="post post-page current-item text-white text-sm md:text-base font-medium">
            {breadcrumbTitle}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

    
   </>
  )
}

export default Header