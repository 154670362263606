import { useEffect } from "react";

const AnalyticsScript = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://analytics.ahrefs.com/analytics.js";
    script.setAttribute("data-key", "oLE8A1RHciAmod/Y/NvH1w");
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default AnalyticsScript;
