import React from "react";
import { motion } from "framer-motion";
import Img1 from "../assets/Stress Therapy Multan.webp";
import Img2 from "../assets/Depression treatment Multan.webp";

const HeroSection = ({ scrollToAppointment }) => {
  return (
    <section
      className="relative w-full max-h-[650px] bg-cover bg-center flex items-center "
      style={{ backgroundImage: `url('${Img1}')` }}
    >
      {/* Hidden Image for Accessibility */}
      <img
        src={Img1}
        alt="Stress Therapy Multan"
        className="hidden"
        aria-hidden="true"
      />
      {/* Overlay for Better Readability */}
      <div className="absolute inset-0 bg-black/50"></div>

      <motion.div
        initial={{ scale: 0.8, opacity: 0 }} // Zoom-out effect
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
        className="relative z-10 flex flex-col lg:flex-row items-center justify-between w-full max-w-7xl px-8 py-24 mx-auto mt-24"
      >
        {/* Animated Text Content */}
        <motion.div className="text-white max-w-xl px-4 sm:px-6 md:px-0">
          <span className="bg-white/20 text-white px-3 py-1 rounded-full text-xs sm:text-sm font-medium uppercase tracking-widest font-[Manrope]">
            Clinical Psychologist
          </span>

          <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mt-3 sm:mt-4 leading-tight tracking-wide font-[DM Serif Display]">
            Multan Walo! <br /> Ab{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
              Tension
            </span>{" "}
            Na Lo{" "}
          </h1>

          <p className="text-lg sm:text-2xl mt-3 sm:mt-4 opacity-100 font-light font-[Manrope] leading-relaxed">
            Professional Psychology Counseling in Multan for Stress, Anxiety &
            Depression. Feel lighter, happier, and in control with expert stress
            therapy.
          </p>

          {/* Book Now Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToAppointment}
            id="HeroCTA"
            className="mt-4 sm:mt-6 px-6 sm:px-8 py-2 sm:py-3 bg-gradient-to-r from-[#71a371] to-[#61987a] hover:bg-green-600 text-white font-semibold rounded-full shadow-lg transition tracking-wide font-[Space Grotesk] text-base sm:text-lg"
          >
            Book a Session Now!
          </motion.button>

          {/* Limited Time Offer */}
          <motion.p
            animate={{ y: [0, -7, 0] }} // Smooth up & down motion
            transition={{ duration: 1.5, ease: "easeInOut", repeat: Infinity }}
            className="mt-2 sm:mt-3 text-sm sm:text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#ff8c00] to-[#ff4500] italic"
          >
            60% OFF! Awareness Initiative Offer 🎉
          </motion.p>
        </motion.div>

        {/* Animated Image Section (Subtle Zoom In-Out) */}
        <motion.div
          animate={{ scale: [1, 1.03, 1] }} // Smooth zoom effect
          transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
          className="relative mt-10 lg:mt-32 hidden lg:block"
        >
          <img
            src={Img2}
            alt="Depression treatment Multan"
            className="w-[430px] h-auto rounded-lg shadow-2xl transition duration-300"
          />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
