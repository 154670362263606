import React from 'react';
import img1 from "../../assets/female-patients-talking-hospital_23-2148981275.jpg";
import img2 from "../../assets/top-view-hardworking-motivated-skilled-workers-brainstorming-one-important-issue-office_140725-106208.jpg";

function ArticleSection() {
  const articles = [
    {
      imgSrc: img1,
      title: 'Relationship between Caregiver Burden and Psychological Well-being among the Caregivers of Cancer Patients',
      description: 'Short description of the article content goes here.',
      link: 'https://bbejournal.com/BBE/article/view/903',
    },
    {
      imgSrc: img2,
      title: 'Role of Gender between the relationship of Academic Stress and Academic Procrastination',
      description: 'Short description of the article content goes here.',
      link: 'https://bbejournal.com/index.php/BBE/article/view/913',
    },

  ];

  return (
    <div className="w-full  px-4 md:px-16  py-8">
      {/* Section Title */}
      <div className="flex flex-col w-full  py-10">
          <h2 className="text-3xl sm:text-3xl text-[#61987a]  font-extrabold bg-border border-b-4 border-b-[#61987a]  py-5">
            Insights & Articles
          </h2>
        </div>
      
      {/* Cards Grid Layout */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  gap-10 ">
        {articles.map((article, index) => (
          <div
            key={index}
            className="flex flex-col transition ease-in-out duration-300 "
          >
              <a
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex flex-col overflow-hidden">
                <img
                  src={article.imgSrc}
                  alt={article.title}
                  loading="lazy"
                  className="h-50 transition-transform duration-300 transform hover:scale-110"
                />
              </div>
              <p className="font-semibold text-base text-stone-800 pt-3 text-left px-2 mb-4">
                {article.title}
              </p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArticleSection;
