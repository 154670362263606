import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import img1 from "../../assets/concept-person-suffering-from-cybersickness.jpg";
import SocialMediaLinks from "../Comp/SocialMediaLinks";
import WhatsAppButton from "../Comp/WhatsAppButton";
import Sidebar from "../Comp/BlogSideBar";

function DoomScrolling() {
  const pageTitle = "Welcome to Our Website!";
  const pageDescription = "This is a brief description of the homepage.";
  const pageUrl = window.location.href; // You can dynamically fetch the URL
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Doomscrolling & Mental Health"
          breadcrumbTitle="Blogs"
        />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1] h-[300px]  md:h-[400px] lg:h-[500px]"
          />
        </div>
        <div className="flex py-10 px-4 sm:px-8 md:px-16 text-gray-700">
        {/* Introduction */}
        <div className="content w-full md:w-3/4 p-8">
          <h2 className="text-2xl font-semibold mb-2 mt-4">
            Doomscrolling & Mental Health: Why We Can’t Stop and How It’s
            Messing with Us{" "}
          </h2>
          <p className="text-base font-normal">
            You open your phone to “just check something real quick,” and
            suddenly—BAM—you’re an hour deep into news about global disasters,
            political chaos, celebrity scandals, and random tragedies. Your
            brain feels like mush, your anxiety’s through the roof, and you’re
            wondering, <strong>“Why do I do this to myself?”</strong>
          </p>
          <p className="text-base font-normal">
            Congratulations. You’ve officially entered the doomscrolling
            vortex—and you’re not alone.
          </p>
          <p className="text-base font-normal">
            Congratulations. You’ve officially entered the doomscrolling
            vortex—and you’re not alone. But why can’t we stop? And how is it
            low-key ruining our mental health without us even noticing? Let’s
            break it down.
          </p>

          {/*  Doomscrolling 101: What Is It, Really?  */}
          <h2 className="text-2xl font-semibold mb-2 mt-6">
            Doomscrolling 101: What Is It, Really?
          </h2>
          <p className="text-base font-normal">
            Doomscrolling is when you mindlessly scroll through negative,
            depressing, or anxiety-inducing content—even though it’s making you
            feel worse. It’s like binge-watching bad news instead of Netflix.
          </p>
          <p className="text-base font-normal">We do it on autopilot:</p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <strong>"Just one more article."</strong>
            </li>
            <li className="text-base text-black leading-relaxed">
              <strong>"Let me see the latest update."</strong>
            </li>
            <li className="text-base text-black leading-relaxed">
              <strong>"Maybe the next post will make me feel better."</strong>
            </li>
          </ul>
          <p className="text-base font-normal mt-2">Spoiler: It never does.</p>
          <p className="text-base font-normal">
            Unlike casual scrolling, doomscrolling is fueled by an emotional
            loop. The more you read, the more anxious you feel. The more anxious
            you feel, the more you scroll—hoping to find that one piece of news
            that will give you closure or make you feel in control. But that
            moment never comes.
          </p>

          {/*   Why We Can’t Stop Doomscrolling (It’s Not Just You, It’s Science)  */}
          <h2 className="text-2xl font-semibold mb-2 mt-6">
            Why We Can’t Stop Doomscrolling (It’s Not Just You, It’s Science)
          </h2>
          <p className="text-base font-normal">
            It’s not just about having “no self-control.” There’s actual brain
            science behind this behavior:
          </p>

          {/*  Your Brain Loves Drama (Thanks, Evolution)  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            1. Your Brain Loves Drama (Thanks, Evolution)
          </h2>
          <p className="text-base font-normal">
            Our brains are wired to pay more attention to danger. Back in
            caveman days, noticing threats (like a hungry tiger) meant survival.
            Today, that “tiger” is breaking news notifications and apocalyptic
            headlines.
          </p>

          <p className="text-base font-normal">
            This is called the negativity bias—bad news sticks like glue, while
            good news slips right off. Our ancestors’ survival depended on
            remembering threats, not sunsets. Now, your brain reacts to a scary
            headline the same way it would’ve reacted to a predator in the wild.
            The problem? There’s no tiger to run from—just your phone.
          </p>

          {/*   The Fake Sense of Control  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            2. The Fake Sense of Control
          </h2>
          <p className="text-base font-normal">
            We doomscroll because we think if we know everything that’s
            happening, we’ll feel more in control. But instead of feeling
            informed, we just end up overloaded and anxious.
          </p>

          <p className="text-base font-normal">
            It’s like trying to put out a fire by throwing more fire at it. We
            believe that if we consume enough information, we’ll predict or
            prevent bad outcomes. But the reality? You can’t control global
            events by reading every article about them.
          </p>

          {/*   You’re Addicted… But Not Your Fault  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            3. You’re Addicted… But Not Your Fault
          </h2>
          <p className="text-base font-normal">
            Social media apps are literally designed to keep you hooked.
            Algorithms track what you engage with, then feed you more of the
            same.
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Read one sad story? Here’s five more.
            </li>
            <li className="text-base text-black leading-relaxed">
              Click on a disaster post? Here’s a whole thread.
            </li>
          </ul>
          <p className="text-base font-normal mt-2">
            It’s like the internet saying, “Oh, you’re sad? Here’s some more
            sadness.”
          </p>
          <p className="text-base font-normal">
            The endless scroll feature? That’s not an accident. It’s engineered
            to create an infinite loop so your brain never hits a natural
            stopping point. No “The End.” Just endless content.
          </p>

          {/*   How Doomscrolling Is Wrecking Your Mental Health  */}
          <h2 className="text-2xl font-semibold mb-2 mt-6">
            How Doomscrolling Is Wrecking Your Mental Health
          </h2>
          <p className="text-base font-normal">
            You might think, “Okay, it’s just scrolling. What’s the big deal?”
            Well… here’s the deal:
          </p>

          {/*   1. It Supercharges Your Anxiety  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            1. It Supercharges Your Anxiety
          </h2>
          <p className="text-base font-normal">
            Every headline is like a little jolt of stress. Over time, your
            brain stays stuck in fight-or-flight mode, making you feel
            constantly on edge—even when you’re not reading the news.
          </p>
          <p className="text-base font-normal">
            Imagine your brain as a phone battery. Doomscrolling is like leaving
            a bunch of apps running in the background—it drains your mental
            energy fast. You feel restless, irritable, and on edge without
            knowing why
          </p>

          {/*    2. It Feeds Depression  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            2. It Feeds Depression
          </h2>
          <p className="text-base font-normal">
            Constant exposure to bad news makes the world feel dark and
            hopeless, even if your actual life is okay. This can lead to
            feelings of sadness, helplessness, and full-on doom-and-gloom vibes.
          </p>
          <p className="text-base font-normal">
            It skews your perspective. If all you see is bad news, you start to
            believe that’s all there is. It’s like wearing glasses that only let
            you see the worst parts of life.
          </p>

          {/*   3. Say Goodbye to Sleep  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            3. Say Goodbye to Sleep
          </h2>
          <p className="text-base font-normal">
            Scrolling at 2 AM? Your brain is overstimulated, your eyes are fried
            from blue light, and your mind is racing. Result? Terrible sleep and
            waking up feeling like a zombie.
          </p>
          <p className="text-base font-normal">
            Your brain needs downtime before bed to process the day.
            Doomscrolling floods your mind with stress right before you’re
            supposed to relax. No wonder you’re staring at the ceiling at 3 AM,
            overthinking everything.
          </p>

          {/*   4. It Kills Your Focus  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            4. It Kills Your Focus
          </h2>
          <p className="text-base font-normal">
            After a doomscrolling session, your brain feels foggy. You struggle
            to concentrate, forget things, and your productivity tanks—whether
            it’s work, studying, or even just getting stuff done around the
            house.
          </p>
          <p className="text-base font-normal">
            It’s like opening 100 tabs on your computer. Eventually, everything
            slows down. That’s your brain on doomscrolling.
          </p>

          {/*    5. It Makes You Feel Helpless and Alone */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            5. It Makes You Feel Helpless and Alone
          </h2>
          <p className="text-base font-normal">
            Ironically, while doomscrolling connects you to global events, it
            can make you feel disconnected from your own life. You’re so focused
            on the outside world that you forget to engage with the people and
            experiences right in front of you.
          </p>

          {/*  How to Break the Doomscrolling Cycle (Without Going Full Digital Detox)  */}
          <h2 className="text-2xl font-semibold mb-2 mt-6">
            How to Break the Doomscrolling Cycle (Without Going Full Digital
            Detox)
          </h2>
          <p className="text-base font-normal">
            No, you don’t have to throw your phone into the ocean. Here’s how to
            take control without deleting everything:
          </p>

          {/*  1. Set Boundaries Like a Boss  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            1. Set Boundaries Like a Boss
          </h2>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Use screen time limits on your phone.
            </li>
            <li className="text-base text-black leading-relaxed">
              Schedule “scroll-free” hours, especially before bed.
            </li>
            <li className="text-base text-black leading-relaxed">
              Try the “one-tab rule”—no endless link-clicking rabbit holes.
            </li>
          </ul>
          <p className="text-base font-normal">
            <strong>Pro tip:</strong> Move news or social media apps off your
            home screen. Out of sight, out of mind.
          </p>

          {/*  2. Clean Up Your Feed  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            2. Clean Up Your Feed
          </h2>
          <p className="text-base font-normal">
            Unfollow accounts that make you feel anxious or overwhelmed.
            Instead, follow pages that bring you joy, teach you new things, or
            make you laugh. (Meme therapy &gt; doomscroll therapy.)
          </p>

          <p className="text-base font-normal">
            Curate your content like you’d curate your playlist. If it doesn’t
            uplift, inform meaningfully, or inspire you—it’s gotta go.
          </p>

          {/*  3. Swap Scrolling for Something Better  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            3. Swap Scrolling for Something Better
          </h2>
          <p className="text-base font-normal">
            When you catch yourself doomscrolling, pause and do something
            different:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Listen to a feel-good playlist
            </li>
            <li className="text-base text-black leading-relaxed">
              Go outside for fresh air
            </li>
            <li className="text-base text-black leading-relaxed">
              Doodle, journal, or even dance it out
            </li>
          </ul>
          <p className="text-base font-normal mt-2">
            Build a “go-to” list of activities you enjoy that don’t involve
            screens. When the urge hits, switch gears.
          </p>

          {/*  4. Get Mindful (Without the Cringe)  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            3. Swap Scrolling for Something Better
          </h2>
          <p className="text-base font-normal">
            No need to sit cross-legged humming “om.” Just breathe.
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Focus on your surroundings.
            </li>
            <li className="text-base text-black leading-relaxed">
              Feel your feet on the ground
            </li>
            <li className="text-base text-black leading-relaxed">
              Take 5 deep breaths.
            </li>
          </ul>
          <p className="text-base font-normal mt-2">
            Mindfulness helps you break the “scroll trance” and reconnect with
            the present moment. Think of it as a mental reset button.
          </p>

          {/*  5. Ask Yourself: “Is This Helping Me Right Now?”  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            5. Ask Yourself: “Is This Helping Me Right Now?”
          </h2>
          <p className="text-base font-normal">
            Before diving into another doomscroll session, pause and reflect:
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              Am I looking for useful information or just feeding my anxiety?
            </li>
            <li className="text-base text-black leading-relaxed">
              Is this helping me feel better or making me feel worse?
            </li>
            <li className="text-base text-black leading-relaxed">
              Could I spend this time doing something that actually makes me
              feel good?
            </li>
          </ul>
          <p className="text-base font-normal mt-2">
            Mindfulness helps you break the “scroll trance” and reconnect with
            the present moment. Think of it as a mental reset button.
          </p>

          {/*  The Big Picture: Your Mental Health > Breaking News  */}
          <h2 className="text-2xl font-semibold mb-2 mt-6">
            The Big Picture: Your Mental Health &gt; Breaking News
          </h2>
          <p className="text-base font-normal ">
            It’s okay to stay informed. The world’s a lot sometimes. But your
            brain isn’t built to handle non-stop doom.
          </p>

          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              You deserve breaks.
            </li>
            <li className="text-base text-black leading-relaxed">
              You deserve peace.
            </li>
            <li className="text-base text-black leading-relaxed">
              You deserve to feel okay, even when the world isn’t.
            </li>
          </ul>
          <p className="text-base font-normal mt-2">
            Remember: “What you feed your mind shapes your mood.” Choose wisely.
          </p>

          {/*   Relatable? Let’s Talk!  */}
          <h2 className="text-xl font-semibold mb-2 mt-6">
            Relatable? Let’s Talk!
          </h2>
          <p className="text-base font-normal">
            Ever caught yourself deep in a doomscroll spiral? What’s helped you
            snap out of it?
            <strong>
              Drop your thoughts in the comments—we’re in this together
            </strong>.
            
          </p>
        </div>
        <Sidebar />
        </div>
        <SocialMediaLinks
          url={pageUrl}
          title={pageTitle}
          description={pageDescription}
        />
         <WhatsAppButton />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default DoomScrolling;
