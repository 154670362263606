import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Header from "../Comp/Header";
import EventsText from "../Comp/EventsText";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import EventDetails from "../Comp/EventDetails";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/preview-page.jpg";

function IllnesWeek() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Mental Illness Awareness Week"
          breadcrumbTitle="Events"
        />
        <EventDetails
          eventImage={img1}
          title="Events"
          startDate="October 5th"
          endDate="October 11th"
          // doorTime="10:00 am"
          // status="Showing"
          address="Model Town, Haseeb Street, 42-43 B, Multan, Pakistan."
        />
        <EventsText>
          {/* World Mental Health Day */}

          <h3 className="text-2xl font-semibold mb-2 mt-4">
            Mental Illness Awareness Week
          </h3>
          <p className="text-base font-normal">
            Mental Illness Awareness Week (MIAW) is observed annually during the
            first week of October, and this year, it falls from October 5 to
            October 11. This dedicated week provides an opportunity to educate
            the public, reduce stigma, and encourage understanding and support
            for those living with mental illnesses. Millions of people worldwide
            face the challenges of mental health conditions, and this week
            serves as a reminder of the importance of prioritizing mental health
            and fostering compassionate communities.
          </p>
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Importance of Mental Illness Awareness Week
          </h3>
          <p className="text-base font-normal">
            Mental Illness Awareness Week plays a pivotal role in breaking the
            stigma surrounding mental health conditions. It aims to spark
            conversations, increase awareness, and promote advocacy for better
            mental health resources. By understanding the struggles faced by
            individuals with mental illnesses, society can create a more
            inclusive and supportive environment. This week also encourages
            early intervention, proper diagnosis, and access to effective
            treatment, paving the way for better mental health outcomes.
          </p>

          {/* Key Facts About Mental Illness*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Key Facts About Mental Illness
          </h3>
          <p className="text-base font-normal">
            Mental illness encompasses a wide range of conditions that affect an
            individual's thoughts, emotions, and behavior. Key facts include:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Prevalence:
              </span>{" "}
              One in five adults experiences a mental health condition each
              year.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Impact:
              </span>{" "}
              Mental illness not only affects individuals but also their
              families, workplaces, and communities.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Treatability:
              </span>{" "}
              Many mental health conditions are treatable with proper care,
              therapy, and medication.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Stigma:
              </span>{" "}
              A significant barrier to treatment is the stigma surrounding
              mental illness, which prevents individuals from seeking help.
            </li>
          </ul>

          {/*  Determinants of Mental Illness */}
          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Determinants of Mental Illness
          </h3>
          <p className="text-base font-normal">
            Similar to mental health, mental illness is shaped by a complex
            interplay of factors, including:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Biological Factors:
              </span>{" "}
              Genetics, brain chemistry, and medical conditions can predispose
              individuals to mental health challenges.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Environmental Stressors:
              </span>{" "}
              Traumatic events, poverty, and exposure to violence can increase
              the risk of developing mental illnesses.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Social Influences:
              </span>{" "}
              Lack of social support, discrimination, and isolation can
              exacerbate mental health conditions.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Lifestyle Choices:
              </span>{" "}
              Substance abuse, lack of physical activity, and poor nutrition can
              negatively impact mental health.
            </li>
          </ul>

          {/* Advocacy and Support During MIAW */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Advocacy and Support During MIAW
          </h3>
          <p className="text-base font-normal">
            Mental Illness Awareness Week encourages advocacy and support
            through several initiatives, such as:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Community Events:
              </span>{" "}
              Workshops, mental health screenings, and awareness campaigns.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Story Sharing:
              </span>{" "}
              Platforms for individuals to share their experiences to inspire
              and educate others.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Fundraising Activities:
              </span>{" "}
              Efforts to support mental health organizations and services.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Educational Resources:
              </span>{" "}
              Dissemination of materials that inform the public about mental
              health conditions and treatment options.
            </li>
          </ul>

          {/* Mental Health Promotion and Preventiont */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
            Mental Health Promotion and Prevention
          </h3>
          <p className="text-base font-normal">
            Promoting mental health and preventing mental illness requires a
            comprehensive and inclusive approach:
          </p>
          <ul className="list-disc list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Individual Level:
              </span>{" "}
              Encourage self-care, resilience-building, and stress management
              techniques.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Community Level:
              </span>{" "}
              Foster supportive environments, provide resources, and promote
              open discussions about mental health.
            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
                Policy Level:
              </span>{" "}
              Advocate for better mental health policies, increased funding for
              research, and improved access to care.
            </li>
          </ul>

          {/* Key Areas of Focus During MIAW */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">
          Key Areas of Focus During MIAW
          </h3>
          <ul className="list-decimal list-inside mt-3">
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
              Youth Mental Health
              </span>{" "}
              Educating young people about mental health and providing school-based programs to support their well-being.

            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
              Workplace Mental Health:
              </span>{" "}
              Highlighting the importance of mental health in professional settings and encouraging employers to offer mental health resources.

            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
              Access to Care:
              </span>{" "}
              Promoting affordable, equitable, and accessible mental health services for all.

            </li>
            <li className="text-base text-black leading-relaxed">
              <span className="text-base mb-2 text-black font-semibold">
              Stigma Reduction:
              </span>{" "}
              Campaigns that challenge misconceptions about mental illness and foster empathy.


            </li>
          </ul>

     

          {/* Call to Action */}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Call to Action</h3>
          <p className="text-base font-normal">
          During Mental Illness Awareness Week, take the opportunity to engage in meaningful conversations about mental health. Whether it’s sharing your story, attending an event, or supporting someone in need, your actions can make a significant impact. By working together, we can create a more understanding and compassionate world for those affected by mental illnesses.
          </p>

          {/* Event Hashtags*/}

          <h3 className="text-2xl font-semibold mb-2 mt-6">Event Hashtags</h3>
          <p className="text-base font-normal">
          Show your support for <strong>Mental Illness Awareness Week</strong> by participating in online discussions and using the following hashtag
          </p>
          <ul>
            <li className="text-base font-semibold mt-4 mb-2">#MIAW2024</li>
            <li className="text-base font-semibold mb-2">
            #EndTheStigma
            </li>
            <li className="text-base font-semibold mb-2">#MentalHealthAdvocacy</li>
            <li className="text-base font-semibold mb-2">
            #ItsOkayToNotBeOkay
            </li>
            <li className="text-base font-semibold mb-2">
            #MentalIllnessAwarenessWeek
            </li>
            <li className="text-base font-semibold mb-2">
            #BreakTheSilence
            </li>
            <li className="text-base font-semibold mb-2">
            #SupportMentalHealth
            </li>
            <li className="text-base font-semibold mb-2">
            #TogetherForMentalHealth
            </li>
          </ul>
        </EventsText>
        <Pagination
          prevLink="/mental-health-day"
          prevText="World Mental Health Day"
          nextLink="/eating-disorders-awareness"
          nextText="Eating Disorder Awareness Week"
        />
        <Footer />
      </div>
    </>
  );
}

export default IllnesWeek;
