import React from "react";
import { motion } from "framer-motion";
import imgNature from "../../assets/Best Mental health services Multan.webp";
import { FaCheck, FaFire, FaClock, FaStar, FaBolt } from "react-icons/fa";

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const PricingPlans = ({ scrollToAppointment }) => {
  return (
    <div className="relative py-16 px-4 md:px-10">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-fixed bg-cover bg-center"
        style={{ backgroundImage: `url('${imgNature}')` }}
        role="img"
        aria-label="Best Mental health services Multan"
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
      </div>

      {/* Content (Positioned Above Overlay) */}
      <div className="relative z-10 text-center text-white">
        <h3 className="text-lg font-semibold uppercase">Our Package</h3>
        <h2 className="text-4xl font-bold">
          Pricing{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#7bae7b] to-[#6ba284]">
            Plans
          </span>
        </h2>
        <p className="max-w-2xl mx-auto text-xl mt-2 text-white">
          Unbelievable Charges for Best Psychology counseling in Multan
        </p>
      </div>

      {/* Pricing Cards with Animations */}
      <div className="relative z-10 mt-10 flex flex-col lg:flex-row justify-center items-center gap-6 ">
        {/* 2-Month Therapy Program */}
        <motion.div
          className="w-[300px] lg:w-[350px] h-auto bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between border px-4 hidden lg:block"
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <h3 className="bg-[#579c54] text-white text-lg font-semibold py-3 px-4 rounded-t-lg text-center">
            2-Month Therapy Program
          </h3>
          <p className="text-green-800 text-2xl font-bold text-center mt-7">
            12,000 PKR
          </p>
          <p className="text-gray-500 text-lg font-semibold text-center mt-3">
            A structured <b>8-week therapy plan</b> designed for long-term
            emotional and mental well-being.
          </p>
          <ul className="mt-6 text-gray-800 space-y-2">
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Weekly 1-on-1 Therapy
            </li>
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Personalized Care Plan
            </li>
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Lasting Results
            </li>
          </ul>
          <button className=" w-full bg-gray-200 text-gray-800 py-2 rounded-lg font-semibold hover:bg-[#3a7257] hover:text-white mt-10">
            <FaStar className="inline-block mr-2" /> Enroll Now
          </button>
        </motion.div>

        {/* Limited-Time Special Offer */}
        <motion.div
  className="w-[300px] sm:w-[340px] lg:w-[400px] h-auto bg-white rounded-lg shadow-xl p-4 sm:p-6 flex flex-col justify-between border"
  variants={cardVariants}
  initial="hidden"
  whileInView="visible"
  viewport={{ once: true, amount: 0.2 }}
>
  <h3 className="bg-[#3a7257] text-white text-base sm:text-lg font-semibold py-1 px-3 sm:px-4 rounded-t-lg flex items-center justify-center gap-2">
    <FaFire /> Limited-Time Offer
  </h3>
  <p className="text-green-900 text-base sm:text-lg font-bold text-center mt-4 sm:mt-7">
    <span className="line-through text-gray-500 text-lg sm:text-xl">
      5,000 PKR
    </span>{" "}
    →
    <span className="text-green-800 text-2xl sm:text-3xl font-bold">
      {" "}
      2,000 PKR
    </span>
  </p>
  <p className="text-gray-500 text-sm sm:text-lg font-semibold text-center mt-3">
    As part of our <b>Awareness Campaign in Multan,</b> get{" "}
    <b>60% Off</b> on a <b>45-Minute Counseling Session</b>.
  </p>
  <p className="text-red-700 text-lg sm:text-xl font-bold text-center flex items-center justify-center gap-2 mt-4 sm:mt-6">
    <FaClock /> Offer Ends in **3 Days**
  </p>
  <ul className="mt-4 sm:mt-6 text-gray-900 space-y-1 sm:space-y-2">
    <li className="flex items-center gap-2 text-base sm:text-lg font-semibold">
      <FaCheck className="text-green-800" /> Expert Counseling Session
    </li>
    <li className="flex items-center gap-2 text-base sm:text-lg font-semibold">
      <FaCheck className="text-green-800" /> Confidential & Personalized
    </li>
    <li className="flex items-center gap-2 text-base sm:text-lg font-semibold">
      <FaCheck className="text-green-800" /> Limited Slots Available
    </li>
  </ul>
  <button
    onClick={scrollToAppointment}
    id="CTAPackages"
    className="mt-6 sm:mt-10 w-full bg-[#3a7257] text-white text-base sm:text-lg font-semibold py-2 sm:py-3 px-3 sm:px-4 rounded-t-lg flex items-center justify-center gap-2"
  >
    <FaBolt className="inline-block mr-2" /> Grab This Offer
  </button>
</motion.div>


        {/* Corporate Workshop Plan */}
        <motion.div
          className="w-[300px] lg:w-[350px] h-auto bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between border  hidden lg:block"
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <h3 className="bg-[#579c54] text-white text-lg font-semibold py-3 px-4 rounded-t-lg text-center">
            Corporate Workshop
          </h3>
          <p className="text-green-800 text-2xl font-bold text-center mt-7">
            50,000 PKR
          </p>
          <p className="text-gray-500 text-lg font-semibold text-center mt-3">
            Professional Mental Health training for corporate teams, boosting{" "}
            <b>productivity and well-being</b>.
          </p>
          <ul className="mt-6 text-gray-800 space-y-2">
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Stress & Anxiety Relief
            </li>
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Employee Wellness Training
            </li>
            <li className="flex items-center gap-2 text-lg font-semibold">
              <FaCheck className="text-green-700" /> Interactive Q&A Sessions
            </li>
          </ul>
          <button className="mt-10 w-full bg-gray-200 text-gray-800 py-2 rounded-lg font-semibold hover:bg-[#3a7257] hover:text-white">
            Book Workshop
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default PricingPlans;
