import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import img1 from "../../assets/young-people-using-reels_23-2150038634.jpg";
import SocialMediaLinks from "../Comp/SocialMediaLinks";
import WhatsAppButton from "../Comp/WhatsAppButton";
import Sidebar from "../Comp/BlogSideBar";

function TheTiktokEffect() {
  const pageTitle = "Welcome to Our Website!";
  const pageDescription = "This is a brief description of the homepage.";
  const pageUrl = window.location.href; // You can dynamically fetch the URL
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="The Tiktok Effect" breadcrumbTitle="Blogs" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16  h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1] h-[300px]  md:h-[400px] lg:h-[500px]"
          />
        </div>
        <div className="flex py-10 px-4 sm:px-8 md:px-16 text-gray-700">
          {/* The TikTok Effect */}
          <div className="content w-full md:w-3/4 p-8 ">
            <h2 className="text-2xl font-semibold mb-2 mt-4">
              The TikTok Effect: How Social Media is Reshaping Mental Health in
              Youth
            </h2>
            <p className="text-base font-normal">
              In today’s digital age, social media platforms have become
              integral to daily life. Among them, TikTok stands out as a
              powerhouse in shaping trends, providing entertainment, and
              connecting people across the globe. However, as this platform
              continues to grow in popularity, it is important to examine its
              impact, especially on the mental health of youth. From its
              addictive nature to the exposure to unrealistic beauty standards,
              TikTok has a significant influence—both positive and negative—on
              the well-being of young people.
            </p>

            {/*  The Allure of TikTok: Instant Gratification and Addiction  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              The Allure of TikTok: Instant Gratification and Addiction
            </h2>
            <p className="text-base font-normal">
              TikTok’s short-form videos, catchy music, and viral challenges are
              designed to grab attention and hold it. This “instant
              gratification” culture can have profound effects on the youth’s
              mental health. The app’s algorithm is finely tuned to provide a
              continuous stream of content that matches users’ interests,
              creating an addictive cycle. This leads to prolonged screen time,
              which can cause issues such as:
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Decreased attention span:
                </span>{" "}
                Constant exposure to quick, engaging content can make it harder
                for young users to focus on tasks that require sustained
                attention, such as studying or reading..
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Sleep disruptions:
                </span>{" "}
                Spending hours on TikTok late into the night can interfere with
                sleep schedules, leading to sleep deprivation, which in turn
                exacerbates mental health issues like anxiety and depression.
              </li>
            </ul>

            {/*  The Impact of Comparison: Unrealistic Beauty Standards and FOMO */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              The Impact of Comparison: Unrealistic Beauty Standards and FOMO
            </h2>
            <p className="text-base font-normal">
              TikTok, like many other social media platforms, fosters an
              environment where comparisons are inevitable. Users often share
              glimpses of their most exciting or polished moments, leading to a
              skewed perception of reality. For young people, this constant
              comparison can contribute to:
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Body image issues:
                </span>{" "}
                Filters and editing tools that enhance one’s appearance can
                create unrealistic beauty standards. Young users may feel
                pressured to conform to these ideals, leading to body
                dissatisfaction, low self-esteem, and eating disorders.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Fear of Missing Out (FOMO):
                </span>{" "}
                The highlight reels on TikTok often show people attending
                parties, vacations, or events that young viewers may not be a
                part of, intensifying feelings of loneliness and isolation.
              </li>
            </ul>

            {/*  The Rise of Mental Health Advocacy on TikTok) */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              The Rise of Mental Health Advocacy on TikTok
            </h2>
            <p className="text-base font-normal">
              While TikTok has its drawbacks, it also provides a platform for
              users to discuss mental health in an open and accessible way. Over
              the past few years, mental health advocates, influencers, and even
              professionals have turned to TikTok to share coping strategies,
              raise awareness, and reduce stigma surrounding mental health
              issues. This has led to:
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Increased mental health awareness:
                </span>{" "}
                Many TikTok creators openly discuss their struggles with
                anxiety, depression, and other mental health issues, normalizing
                these conversations for youth who may otherwise feel isolated or
                misunderstood.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Supportive communities:
                </span>{" "}
                TikTok provides a sense of community where young people can find
                support from others who are experiencing similar challenges.
                Mental health-focused hashtags like #mentalhealthawareness or
                #itsokaynottobeokay have garnered millions of views, offering
                resources, advice, and solidarity.
              </li>
            </ul>

            {/*  The Dark Side of TikTok: Cyberbullying and Toxicity */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              The Dark Side of TikTok: Cyberbullying and Toxicity
            </h2>
            <p className="text-base font-normal">
              As with any social media platform, TikTok is not immune to the
              negative aspects of digital interaction. The anonymity of the
              internet allows people to leave hurtful comments, spreading
              toxicity and contributing to cyberbullying. For young users, this
              can be devastating:
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Cyberbullying:
                </span>{" "}
                Harassment and bullying are unfortunately common on TikTok,
                whether through hurtful comments, mean-spirited videos, or
                direct messages. This can severely affect a young person's
                mental health, leading to depression, anxiety, and in extreme
                cases, self-harm.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Toxic comparison culture:
                </span>{" "}
                In addition to the pressure of beauty standards, users may also
                face the toxic expectation of living up to certain lifestyles,
                success, or popularity. These unrealistic portrayals of life can
                lead to feelings of inadequacy and anxiety.
              </li>
            </ul>

            {/*  TikTok and Mental Health: Striking a Balance */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              TikTok and Mental Health: Striking a Balance
            </h2>
            <p className="text-base font-normal">
              Given TikTok’s massive influence on the youth, it is important to
              strike a balance between its positive and negative effects.
              Parents, educators, and mental health professionals can help young
              users navigate this landscape more mindfully by:
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Encouraging digital detoxes:
                </span>{" "}
                Encouraging periodic breaks from social media, particularly from
                platforms like TikTok, can help young people maintain a healthy
                relationship with technology and reduce feelings of overwhelm or
                burnout.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Promoting positive content:
                </span>{" "}
                Actively seeking out and sharing content that promotes body
                positivity, mental health awareness, and self-love can help
                counterbalance the harmful effects of comparison culture.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Setting boundaries:
                </span>{" "}
                Encouraging healthy boundaries for screen time, such as limiting
                the amount of time spent on TikTok each day, can help mitigate
                its negative impact on mental health.
              </li>
            </ul>

            {/* Conclusion */}
            <h2 className="text-xl font-semibold mb-2 mt-6">Conclusion</h2>
            <p className="text-base font-normal">
              TikTok is undeniably shaping the way youth experience the world,
              both online and offline. While it can provide entertainment and a
              sense of community, it also introduces challenges, particularly
              when it comes to mental health. The key is to promote awareness,
              balance, and responsibility—empowering young users to enjoy TikTok
              in ways that are healthy and mindful. By being proactive and
              intentional about their social media consumption, youth can
              harness the benefits of TikTok while minimizing its risks.
            </p>
            <p className="text-base font-normal">
              As we move further into the digital age, it’s crucial that we
              continue to have open conversations about the role social media
              plays in shaping mental health, and how we can best protect and
              nurture the well-being of young people..
            </p>
          </div>
          <Sidebar />
        </div>
        <SocialMediaLinks
          url={pageUrl}
          title={pageTitle}
          description={pageDescription}
        />
        <WhatsAppButton />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default TheTiktokEffect;
