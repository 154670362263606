import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/imageforentry13-ft9.webp";
import imgy from "../../assets/GettyImages-1339242968-47adc76d745c4d9c824497799562c94c.jpg";
import imgz from "../../assets/6.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/pexels-thatguycraig000-1608113.jpg";
import img2 from "../../assets/pexels-pixabay-163431.jpg";
import img3 from "../../assets/depositphotos_95793324-stock-photo.jpg";

function AngerManage() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Couple Therapy",
      date: "May 30, 2024",
      description:
        "Couple therapy, also known as couples counseling or marriage therapy, is a type of psychotherapy...",
      link: "/coupletherapy",
    },
    {
      imgSrc: imgy,
      title: "Relaxation Techniques",
      description:
        "Relaxation techniques in the therapeutic process refer to a set of practices and strategies used...",
      link: "/relaxationtechniques",
    },
    {
      imgSrc: imgz,
      title: "Speech Therapy",
      description:
        "Speech therapy is a specialized field focused on diagnosing and treating communication and...",
      link: "/speechtherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Anger Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            Anger Management refers to techniques and strategies designed to
            help individuals control their anger and handle conflict in a
            constructive manner. Effective anger management can prevent harmful
            behaviors, improve relationships, and enhance overall well-being.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Components of Anger Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Understanding Anger
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Identifying Triggers and Patterns
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive Restructuring
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Relaxation Techniques
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Problem-Solving Skills
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Communication Skills
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Behavioral Strategies
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Building Emotional Intelligence
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of Effective Anger Management
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Improved Relationships
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Enhanced Emotional Health
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Increased Self-Control
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Better
            Problem-Solving
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Overall Well-being
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Anger Management" breadcrumbTitle="Services" />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/stressmanage"
          prevText="Stress Management"
          nextLink="/disorder"
          nextText="Psychological Disorder Treatments"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default AngerManage;
