import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { initGA, trackPageView } from "./analytics";
import Home from "./components/Home";
import AnalyticsScript from "./components/AnalyticsScript";
import About from "./components/About";
import Services from "./components/Services";
import Resources from "./components/Resources";
import Events from "./components/Events";
import BookNow from "./components/BookNow";
import Psychotherapy from "./components/ServicePages/Psychotherapy";
import CoupleTherapy from "./components/ServicePages/CoupleTherapy";
import Interpersonal from "./components/ServicePages/Interpersonal";
import Counseling from "./components/ServicePages/Counseling";
import CareerCounseling from "./components/ServicePages/CareerCounseling";
import SpeechTherapy from "./components/ServicePages/SpeechTherapy";
import Dbt from "./components/ServicePages/Dbt";
import StressManage from "./components/ServicePages/StressManage";
import AngerManage from "./components/ServicePages/AngerManage";
import Disorder from "./components/ServicePages/Disorder";
import SpecialNeeds from "./components/ServicePages/SpecialNeeds";
import SpeechIssues from "./components/ServicePages/SpeechIssues";
import Relaxation from "./components/ServicePages/Relaxation";
import WorldMentalHealth from "./components/EventsPages/WorldMentalHealth";
import IllnesWeek from "./components/EventsPages/IllnesWeek";
import EatingDisorder from "./components/EventsPages/EatingDisorder";
import Schizophrenia from "./components/EventsPages/Schizophrenia";
import Biopolar from "./components/EventsPages/Biopolar";
import PtsdAwareness from "./components/EventsPages/PtsdAwareness";
import ChildrenWeek from "./components/EventsPages/ChildrenWeek";
import Happiness from "./components/EventsPages/Happiness";
import MentalHealth from "./components/EventsPages/MentalHealth";
import WorldSuicide from "./components/EventsPages/WorldSuicide";
import NationalSuicide from "./components/EventsPages/NationalSuicide";
import NationalMental from "./components/EventsPages/NationalMental";
import Psychological from "./components/ExpertisePages/Psychological";
import Childhood from "./components/ExpertisePages/Childhood";
import Special from "./components/ExpertisePages/Special";
import DigitalDetox from "./components/BlogsPages/DigitalDetox";
import VirtualAutism from "./components/BlogsPages/VirtualAutism";
import Contact from "./components/Contact";
import Cbt from "./components/ServicePages/Cbt";
import Emdr from "./components/ServicePages/Emdr";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TopNav from "./components/TopNav";
import CounselingExpertise from "./components/ExpertisePages/CounselingExpertise";
import HearingLoss from "./components/BlogsPages/HearingLoss";
import GenAlpha from "./components/BlogsPages/GenAlpha";
import SocialMediaInfluencers from "./components/BlogsPages/SocialMediaInfluencers";
import GalleryFilter from "./components/GalleryFilter";
import SilentEpidemic from "./components/BlogsPages/SilentEpidemic";
import DoomScrolling from "./components/BlogsPages/DoomScrolling";
import PowerOfMicroAction from "./components/BlogsPages/PowerOfMicroAction";
import TheTiktokEffect from "./components/BlogsPages/TheTiktokEffect";
import RiseOfSituationship from "./components/BlogsPages/RiseOfSituationship";
import ScienceOfHappiness from "./components/BlogsPages/ScienceOfHappiness";



const GAListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname); // Track page views on route change
  }, [location]);

  return children;
};



function App() {
   useEffect(() => {
    // Tawk.to script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/67ab07ff825083258e13291b/1ijq1eftg";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);



  useEffect(() => {
    initGA(); // Initialize Google Analytics
  }, []);


  return (
    <Router>
      <GAListener>
      
<TopNav />
       

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Resources />} />
        <Route path="/events" element={<Events />} />
        <Route path="/booknow" element={<BookNow />} />
        <Route path="/Contact-MCH-By-Habiba-Nazim-Khan" element={<Contact />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/gallery" element={<GalleryFilter />} />
        



        {/* Expertise Pages */}
        <Route path="/psychological-disorder" element={<Psychological />} />
        <Route path="/childhood-disorder" element={<Childhood />} />
        <Route path="/special-needs-disorder" element={<Special />} />
        <Route path="/counseling-exp" element={<CounselingExpertise />} />


        {/* Service Pages */}
        <Route path="/psychotherapy" element={<Psychotherapy />} />
        <Route path="/coupletherapy" element={<CoupleTherapy />} />
        <Route path="/interpersonal" element={<Interpersonal />} />
        <Route path="/counseling" element={<Counseling />} />
        <Route path="/career" element={<CareerCounseling />} />
        <Route path="/speechtherapy" element={<SpeechTherapy />} />
        <Route path="/dbt" element={<Dbt />} />
        <Route path="/stresstherapymultan" element={<StressManage />} />
        <Route path="/angermanage" element={<AngerManage />} />
        <Route path="/disorder" element={<Disorder />} />
        <Route path="/speechissues" element={<SpeechIssues />} />
        <Route path="/specialneeds" element={<SpecialNeeds />} />
        <Route path="/relaxationtechniques" element={<Relaxation />} />
        <Route path="/cbt" element={<Cbt />} />
        <Route path="/emdr" element={<Emdr />} />



        {/* Resourses */}
        <Route path="/digital-detox" element={<DigitalDetox />} />
        <Route path="/virtual-autism" element={<VirtualAutism />} />
        <Route path="/hearing-loss" element={<HearingLoss />} />
        <Route path="/gen-alpha-and-emotional-intelligence" element={<GenAlpha />} />
        <Route path="/rise-of-social-media-influencers" element={<SocialMediaInfluencers />} />
        <Route path="/the-silent-epidemic" element={<SilentEpidemic />} />
        <Route path="/doomscrolling-and-mental-health" element={<DoomScrolling />} />
        <Route path="/the-power-of-micro-actions" element={<PowerOfMicroAction />} />
        <Route path="/the-tiktok-effect" element={<TheTiktokEffect />} />
        <Route path="/the-rise-of-situationships" element={<RiseOfSituationship />} />
        <Route path="/the-science-of-happiness" element={<ScienceOfHappiness />} />






        {/* Events Pages */}
        <Route path="/mental-health-day" element={<WorldMentalHealth />} />
        <Route path="/mental-illness-awareness" element={<IllnesWeek />} />
        <Route path="/eating-disorders-awareness" element={<EatingDisorder />} />
        <Route path="/schizophrenia-awareness" element={<Schizophrenia />} />
        <Route path="/bipolar-awareness" element={<Biopolar />} />
        <Route path="/ptsd-awareness" element={<PtsdAwareness />} />
        <Route path="/child-awareness" element={<ChildrenWeek />} />
        <Route path="/happiness-day-awareness" element={<Happiness />} />
        <Route path="/mental-health-awareness" element={<MentalHealth />} />
        <Route path="/world-suicide-awareness" element={<WorldSuicide />} />
        <Route path="/national-suicide-prevention" element={<NationalSuicide />} />
        <Route path="/national-mental-health-awareness" element={<NationalMental />} />

      </Routes>
      </GAListener>
      
    </Router>
  );
}

export default App;
