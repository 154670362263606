"use client";
import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight, PlayCircle } from "lucide-react";

import img1 from "../../assets/Psychologist and therapist near me-1.webp";
import img2 from "../../assets/Best Mental health services Multan-1.webp";
import img3 from "../../assets/psychology counseling in multan.png";
import img4 from "../../assets/Multan-Mental-health-clinic.webp";
import img5 from "../../assets/anxiety treatment.webp";
import img6 from "../../assets/Depression-Treatment.webp";

const videos = [
  {
    id: "1",
    title: "All About MindfulConnection Hub",
    thumbnail: img1, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/vKFqukLT9C4",
  },
  {
    id: "2",
    title: "Discussion About Digital Ditox",
    thumbnail: img2, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/52KkhPOghnw",
  },
  {
    id: "3",
    title: "Struggling With Stress and Relationship...",
    thumbnail: img3, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/k5qohMn17_I",
  },
  {
    id: "4",
    title: "How to overcome relationship issues...",
    thumbnail: img4, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/oY940B4jnic",
  },
  {
    id: "5",
    title: "How to overcome relationship issues...",
    thumbnail: img5, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/I8mJ1oKcPq4",
  },
  {
    id: "6",
    title: "How to Cope with Stress After Breakup...",
    thumbnail: img6, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/N6s9ZItWRKI",
  },
  {
    id: "7",
    title: "All About MindfulConnection Hub",
    thumbnail: img1, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/vKFqukLT9C4",
  },
  {
    id: "8",
    title: "Discussion About Digital Ditox",
    thumbnail: img2, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/52KkhPOghnw",
  },
  {
    id: "9",
    title: "Struggling With Stress and Relationship...",
    thumbnail: img3, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/k5qohMn17_I",
  },
  {
    id: "10",
    title: "How to overcome relationship issues...",
    thumbnail: img4, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/oY940B4jnic",
  },
  {
    id: "11",
    title: "How to overcome relationship issues...",
    thumbnail: img5, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/I8mJ1oKcPq4",
  },
  {
    id: "12",
    title: "How to Cope with Stress After Breakup...",
    thumbnail: img6, // Replace with actual image paths
    videoUrl: "https://www.youtube.com/embed/N6s9ZItWRKI",
  },
];

const VideoSlider = () => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const sliderRef = useRef(null);
  const videoIndex = useRef(0); // Track the current index of videos

  const scroll = (direction) => {
    if (sliderRef.current) {
      const cardWidth = sliderRef.current.children[0]?.offsetWidth + 40; // Add gap (px-4 + gap-10 ~ 40px)
      const { scrollLeft } = sliderRef.current;
      const scrollTo =
        direction === "left" ? scrollLeft - cardWidth : scrollLeft + cardWidth;

      sliderRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  const openVideo = (video) => {
    setCurrentVideo(video);
  };

  // Function to automatically scroll to the next video
  const autoScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = sliderRef.current;
      const cardWidth = sliderRef.current.children[0].offsetWidth + 48;

      const nextScrollPosition = scrollLeft + cardWidth;

      if (nextScrollPosition >= scrollWidth - clientWidth) {
        sliderRef.current.scrollTo({ left: 0, behavior: "instant" }); // Reset instantly
      } else {
        sliderRef.current.scrollTo({
          left: nextScrollPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(autoScroll, 3000); // Adjust speed as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="max-w-xl mx-auto text-center  ">
        <div className="w-24 h-2 bg-[#5d9676] mb-4 mx-auto"></div>

        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
          Video Gallery
        </h2>
      </div>

      <div
        className="relative w-full h-[300px] md:h-[400px] py-10 flex items-center px-20 md:px-32 mt-9 bg-fixed bg-cover bg-center"
        style={{
          backgroundImage: `url(${require("../../assets/gadgets-office.jpg")})`,
        }}
        aria-labelledby="video-gallery-heading"
      >
        {/* Optional: Adds a dark overlay */}
        <div className="absolute inset-0 bg-black/50"></div>{" "}
        {/* Video slider */}
        <div
          ref={sliderRef}
          className="flex w-full overflow-x-auto gap-10 pb-4 snap-x snap-mandatory scrollbar-hidden justify-start"
          role="list"
          aria-label="Video slider"
        >
          {videos.map((video) => (
            <div
              key={video.id}
              className="inline-flex items-center justify-center flex-shrink-0 snap-center cursor-pointer"
              role="listitem"
              onClick={() => openVideo(video)}
              aria-label={`Open video: ${video.title}`}
            >
              <div className="relative group">
                <img
                  src={video.thumbnail || "/placeholder.svg"}
                  alt={`Thumbnail of ${video.title}`}
                  className="w-full h-[150px] md:h-[200px] object-cover px-8 md:px-4 "
                />
                {/* Play button with hover effect */}
                <div
                  className="absolute inset-0 flex items-center justify-center"
                  aria-hidden="true"
                >
                  <PlayCircle className="w-12 h-12 bg-red-700 text-white rounded-full opacity-80 group-hover:scale-110 group-hover:opacity-100 transition-transform duration-200" />
                </div>
                {/* Video title below thumbnail */}
                <p
                  className="mt-2 px-9 md:px-5 text-sm sm:text-base md:text-lg text-center font-medium text-white group-hover:text-[#d6e8d6]"
                  aria-hidden="true"
                >
                  {video.title}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* Control buttons */}
        <div className="absolute top-1/2 left-2 z-10 flex flex-col gap-2 px-2 md:px-4  transform -translate-y-1/2">
          <button
            onClick={() => scroll("left")}
            className="p-2 rounded-full border bg-[#5d9676] transition-colors hover:bg-[#d6e8d6] hover:border-[#5d9676]"
            aria-label="Previous Slide" // Adds an accessible name
            title="Previous Slide" // Provides a tooltip
          >
            <ChevronLeft className="w-6 h-6 text-white hover:text-[#5d9676]" />
          </button>
        </div>
        <div className="absolute top-1/2 right-2 z-10 flex flex-col gap-2 px-2 md:px-4 transform -translate-y-1/2">
          <button
            onClick={() => scroll("right")}
            className="p-2 rounded-full border bg-[#5d9676] transition-colors hover:bg-[#d6e8d6] hover:border-[#5d9676]"
            aria-label="Next Slide"
            title="Next Slide"
          >
            <ChevronRight className="w-6 h-6 text-white hover:text-[#5d9676]" />
          </button>
        </div>
        {/* Video modal */}
        {currentVideo && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            role="dialog"
            aria-labelledby="video-title"
            aria-modal="true"
          >
            <div className="relative p-8 rounded-lg max-w-6xl w-full h-auto md:h-[80vh]">
              {/* Cross button */}
              <button
                onClick={() => setCurrentVideo(null)}
                className="absolute top-4 -right-1 text-3xl text-gray-200 hover:text-gray-900 transition-colors z-10"
                aria-label="Close video"
              >
                &times; {/* HTML entity for a cross symbol */}
              </button>

              {/* Video iframe with better size */}
              <div className="relative w-full h-full mb-6">
                <iframe
                  src={currentVideo.videoUrl}
                  title={currentVideo.title}
                  className="w-full h-[70vh] rounded-lg"
                  allowFullScreen
                ></iframe>
              </div>

              {/* Video Title */}
              <h3
                id="video-title"
                className="text-2xl font-semibold text-center"
              >
                {currentVideo.title}
              </h3>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoSlider;
