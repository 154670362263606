import React, { useRef } from "react";
import Footer from "../Footer";
import { Helmet } from "react-helmet-async";
import HeroSection from "../ServiceHero";
import AreYouStruggling from "../AreYouStruggling";
import WhyChooseUs from "../WhyChooseUS";
import PsychologistProfile from "../PsychologistProfile";
import InfiniteCarousel from "../ImageCarousel";
import YourFirstPsychotherapy from "../YourFirstPsychotherapy";
import PricingPlans from "../Comp/PricingPlans";
import FaqForStress from "../Comp/FaqForStress";
import WhatsAppButton from "../Comp/WhatsAppButton";
import TopNav from "../TopNav";
import AppointmentForm from "../Comp/AppointmentForm";
import InfoSection from "../Comp/InfoSection";
function StressManage() {
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.mindfulch.com/#website",
        name: "Mindful Connection Hub",
        url: "https://www.mindfulch.com/",
        publisher: {
          "@id": "https://www.mindfulch.com/#organization",
        },
      },
      {
        "@type": "WebPage",
        "@id": "https://www.mindfulch.com/stresstherapymultan#webpage",
        url: "https://www.mindfulch.com/stresstherapymultan",
        name: "Stress Therapy Multan",
        isPartOf: {
          "@id": "https://www.mindfulch.com/#website",
        },
        breadcrumb: {
          "@id": "https://www.mindfulch.com/stresstherapymultan#breadcrumb",
        },
        inLanguage: "en",
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.mindfulch.com/stresstherapymultan#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.mindfulch.com/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Stress Therapy Multan",
            item: "https://www.mindfulch.com/stresstherapymultan",
          },
        ],
      },
      {
        "@type": "Organization",
        "@id": "https://www.mindfulch.com/#organization",
        name: "Mindful Connection Hub",
        url: "https://www.mindfulch.com/",
        logo: "https://www.mindfulch.com/static/media/Logoo.1797d109abd253ceea64.webp",
        sameAs: [
          "https://www.facebook.com/profile.php?id=61574094790029",
          "https://www.linkedin.com/company/mindful-connection-hub",
          "https://www.instagram.com/habibanazimkhan/",
          "https://www.tiktok.com/@habibanazimkhan1",
          "https://www.youtube.com/@HabibaNazimKhan",
        ],
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "+92 3040186444",
          contactType: "customer support",
          areaServed: "Worldwide",
          availableLanguage: ["English", "Urdu"],
        },
      },
      {
        "@type": "Service",
        "@id": "https://www.mindfulch.com/stresstherapymultan#service",
        name: "Stress Therapy Multan",
        url: "https://www.mindfulch.com/stresstherapymultan",
        description:
          "Experience expert Stress Therapy Multan to overcome anxiety and regain peace of mind. Our proven techniques help you relax and refocus. Book your session today!",
        provider: {
          "@id": "https://www.mindfulch.com/#organization",
        },
        offers: {
          "@type": "Offer",
          priceCurrency: "PKR",
          price: "Contact for Pricing",
          url: "https://www.mindfulch.com/stresstherapymultan",
        },
      },
    ],
  };
  const appointmentRef = useRef(null);
  const scrollToAppointment = () => {
    if (appointmentRef.current) {
      appointmentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        <title>Multan Walo! Ab Tension Na Lo - Stress Therapy Multan</title>
        <meta
          name="description"
          content="Get stress, anxiety, and depression related psychology counseling in Multan. Private sessions by the best Mental Health psychologist Habiba Nazim Khan to help you feel lighter, happier, and more in control. Book now for inner peace."
        />
        <link
          rel="canonical"
          href="https://www.mindfulch.com/stresstherapymultan"
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div>
        <TopNav />
        <HeroSection scrollToAppointment={scrollToAppointment} />
        <PsychologistProfile  />
        <InfiniteCarousel />
        <AreYouStruggling />
        <WhyChooseUs />
        <YourFirstPsychotherapy />
        <PricingPlans scrollToAppointment={scrollToAppointment} />
        {/* <CallToAction /> */}
        <AppointmentForm ref={appointmentRef} />
        <FaqForStress />
        <WhatsAppButton />
        <InfoSection />
        <Footer />
      </div>
    </>
  );
}

export default StressManage;
