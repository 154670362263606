import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import img2 from "../assets/mamabout.webp";
import img1 from "../assets/top-view.webp";
import img3 from "../assets/story.png";
import img4 from "../assets/values-beliefs.png";
import img5 from "../assets/illustration-of-an-open-book.webp";
import img6 from "../assets/flag_15367728.png";
import InfoSection from "./Comp/InfoSection";
import WhatsAppButton from "./Comp/WhatsAppButton";
import { Helmet } from "react-helmet-async";
const About = () => {

  const schemaData ={
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": "https://www.mindfulch.com/about#founder",
    "name": "Habiba Nazim Khan",
    "url": "https://www.mindfulch.com/about",
    "image": "https://www.mindfulch.com/static/media/HabibaNazimKhan.jpg",
    "jobTitle": "Founder & Mindfulness Coach",
    "worksFor": {
      "@type": "Organization",
      "name": "Mindful Connection Hub",
      "url": "https://www.mindfulch.com/"
    },
    "sameAs": [
      "https://www.linkedin.com/in/habiba-nazim-khan-6a42188b/",
      "https://www.instagram.com/habibanazimkhan/",
      "https://www.tiktok.com/@habibanazimkhan1",
      "https://www.youtube.com/@HabibaNazimKhan"
    ],
    "description": "Habiba Nazim Khan is the founder of Mindful Connection Hub, dedicated to mental well-being, mindfulness coaching, and personal development.",
    "knowsAbout": [
      "Mindfulness Coaching",
      "Stress Management",
      "Self-Development",
      "Mental Health Awareness",
      "Personal Growth"
    ],
  
     "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+92 3040186444",
          "contactType": "customer support",
          "areaServed": "Worldwide",
          "availableLanguage": ["English", "Urdu"]
        }
  }
  
  ;
  
  return (
    <>
     <Helmet>
     <link rel="canonical" href="https://www.mindfulch.com/about" />

     <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
    {/* <MetaTags page="about" /> */}
      <TopNav />
      <main>
        <div
          className="relative pt-16 pb-32 mt-10 flex content-center items-center h-[400px] md:h-[600px] justify-center"
          style={{
            minHeight: "80vh",
            backgroundImage: `url(${img1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="absolute top-0 w-full h-full bg-black opacity-60"></div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <h1 className="text-white font-semibold  text-2xl sm:text-3xl md:text-6xl">
                    Dr. Habiba
                  </h1>
                  <h2 className="mt-4 text-white font-semibold text-xl sm:text-2xl md:text-3xl">
                    Life Coach
                  </h2>
                  <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-300">
                    Providing Innovative and Effective Mental Health Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section>
          <div className="container mx-auto px-4 pt-4 mt-10">
            <div className="items-center flex flex-wrap md:flex-row-reverse">
              <div className="w-full md:w-2/3 lg:w-5/12  ml-auto mr-auto px-2">
                <img
                  src={img2}
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg lg:h-[600px] "
                />
              </div>
              <div className="w-full lg:w-5/12 ml-auto mr-auto leading-relaxed px-2 md:px-6 pr-2 md:pr-6">
                <div className="xl:pr-8 h-full">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mt-20 lg:mt-0 mb-6  shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img5} alt="icon" className="h-12 w-16" />
                  </div>
                  <h3 className="text-xl md:text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
                    My Vision
                  </h3>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    My vision is to build a world where mental health
                    and well-being are within reach for everyone, offering care
                    that speaks to both the heart and the mind. I am dedicated
                    to guiding others on their journey to healing, growth, and
                    inner peace by blending proven therapeutic practices with
                    the profound wisdom of spirituality, particularly the
                    timeless principles of Islamic philosophy. My hope is to
                    help individuals find balance, uncover their purpose, and
                    nurture self-awareness in a way that truly honors their
                    unique struggles and aspirations.
                  </p>

                  <div className="mt-20 lg:mt-10 p-3 text-center inline-flex items-center justify-center w-16 h-16   mb-6  shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img6} alt="icon" className="h-10 w-16" />
                  </div>
                  <h3 className=" text-xl md:text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
                    My Mission
                  </h3>
                  <p className="mt-4 text-base md:text-lg leading-relaxed text-gray-600">
                    My mission is to help individuals find true peace of mind,
                    build resilience, and realize their full potential by
                    blending the best of psychological care, intuitive
                    technology, and heartfelt spiritual guidance. With
                    compassion at the core of everything I do, I aim to offer
                    care that not only heals but uplifts, providing a safe space
                    for emotional and psychological growth. By creating
                    accessible and easy-to- use tools, I hope to walk alongside
                    individuals on their mental health journeys, supporting them
                    every step of the way toward a life of balance, strength,
                    and fulfillment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto px-4 py-14">
            <div className="flex flex-wrap items-start py-5">
              <div className="w-full lg:w-5/12 ml-auto mr-auto flex flex-col h-full px-2 md:px-6">
                <div className="xl:pr-8 h-full ">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6  shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img3} alt="icon" />
                  </div>
                  <h3 className="text-xl md:text-3xl  font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
                    Core Values
                  </h3>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Compassion
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Offering a listening ear and understanding, creating a safe
                    space for healing and growth.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Holistic Support
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Fusing psychology, technology, and spirituality to nurture
                    the mind, body, and soul in a balanced way.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Respect for You
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Honoring your individuality and journey, recognizing that
                    everyone’s path to well-being is unique.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Peaceful Presence
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Using mindfulness and calming practices to help restore
                    peace and emotional balance.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Shared Growth
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Committed to continuous learning, with a focus on evolving
                    together to foster healing and progress.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Spiritual Guidance
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Drawing from Islamic wisdom to inspire patience, resilience,
                    and a deeper sense of inner peace.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-5/12 ml-auto mr-auto  flex flex-col h-full px-2 md:px-6">
                <div className="xl:pr-8 h-full">
                  <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 mt-16 lg:mt-0 shadow-lg rounded-full bg-[#9ccdb1]">
                    <img src={img4} alt="icon" />
                  </div>
                  <h3 className="text-xl md:text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">Goals</h3>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Bringing Technology and Psychology Together
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Create simple, helpful digital tools like apps or websites
                    that combine therapy techniques, relaxation exercises, and
                    spiritual wisdom to support mental health and personal
                    growth.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Supporting Whole-Person Well-Being
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Make services available that blend mindfulness, breathing
                    exercises, and relaxation with traditional mental health
                    treatments to help people feel more balanced and at peace.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Building a Supportive Community
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Create a safe online space where people can connect, share
                    their experiences, and support each other on their mental
                    health journeys, building a sense of community and healing
                    together.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Continuing Learning and Growth
                  </h4>
                  <p className="mt-1 text-base md:text-lg leading-relaxed text-gray-600">
                    Continue my studies to better understand how psychology,
                    spirituality, and technology can work together to improve
                    mental health, and share my findings to help others.
                  </p>
                  <h4 className="mt-4 text-base md:text-lg leading-relaxed font-semibold text-gray-600">
                    Sharing Spiritual and Mental Health Wisdom
                  </h4>
                  <p className="mt-1 mb-4text-base md:text-lg leading-relaxed text-gray-600">
                    Offer programs and workshops to teach how spiritual
                    practices, especially from Islamic teachings, can play a key
                    role in improving both mental and emotional health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <InfoSection />
        <WhatsAppButton />
      </main>
      <Footer />
    </>
  );
};

export default About;
