import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/../assets/psychology-counseling-in-multan.webp";

function CounselingExpertise() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="Counseling" breadcrumbTitle="Expertise" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            loading="lazy"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        <div className="py-10 px-4 sm:px-8 md:px-16 lg:px-32">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-2xl font-extrabold mb-6">Counseling</h1>
            <p className="text-black mb-4">
              <strong>Understanding and Supporting Counseling Needs</strong>
            </p>
            <p className="text-black mb-6">
              Life is a journey filled with joys and challenges. For many,
              navigating these challenges can feel overwhelming. Counseling
              serves as a bridge to understanding, growth, and resolution during
              such times. At Mindful Connection Hub, we are dedicated to
              providing professional and compassionate counseling services to
              individuals of all ages. Our mission is to empower individuals and
              families with the tools and insights needed to overcome life’s
              hurdles and achieve emotional well-being.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Recognizing the Need for Counseling
            </h4>
            <p className="text-black mb-6">
              Every person’s journey is unique, and so are the struggles they
              face. Counseling can be beneficial for anyone navigating stress,
              grief, conflict, or life transitions. It is also essential for
              those experiencing mental health concerns such as anxiety,
              depression, or trauma. Recognizing when to seek help is the first
              step toward positive change. Counseling is not just for those in
              crisis; it is also a proactive way to build resilience and enhance
              personal growth.
            </p>

            <h4 className="text-lg font-semibold mb-3">How We Help</h4>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we provide a welcoming and confidential
              environment where individuals can share their experiences without
              fear of judgment. Our team of skilled counselors employs
              evidence-based approaches tailored to meet each client’s specific
              needs.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Our Services Include:
            </h4>
            <ul className="list-disc list-inside text-black mb-6">
              <li>
                <strong>Individual Counseling:</strong> Personalized sessions
                focusing on mental health, emotional regulation, and
                self-discovery.
              </li>
              <li>
                <strong>Family Counseling:</strong> Helping families navigate
                conflicts, improve communication, and strengthen relationships.
              </li>
              <li>
                <strong>Couples Counseling:</strong> Supporting partners in
                building trust, resolving conflicts, and enhancing their
                connection.
              </li>
              <li>
                <strong>Group Counseling:</strong> Creating a shared space for
                individuals with similar challenges to find support and
                solidarity.
              </li>
              <li>
                <strong>Career Counseling:</strong> Guiding individuals in
                exploring their professional interests, strengths, and
                opportunities.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">Types of Counseling</h4>
            <p className="text-black mb-6">
              Counseling is a diverse field, offering support for a variety of
              life situations and mental health concerns:
            </p>

            <ul className="list-disc list-inside text-black mb-6">
              <li>
                <strong>Mental Health Counseling: </strong>Focused on addressing
                issues like anxiety, depression, and stress.
              </li>
              <li>
                <strong>Trauma Counseling: </strong>Helping individuals process
                and recover from traumatic experiences.
              </li>
              <li>
                <strong>Child and Adolescent Counseling: </strong>Addressing
                developmental, emotional, and behavioral concerns in younger
                clients.
              </li>
              <li>
                <strong>Substance Abuse Counseling: </strong>Supporting
                individuals in overcoming addiction and maintaining sobriety.
              </li>
              <li>
                <strong>Rehabilitation Counseling:</strong>Assisting individuals
                with disabilities or chronic illnesses to achieve independence.
              </li>
              <li>
                <strong>Grief Counseling: </strong>Providing comfort and coping
                strategies for those dealing with loss.
              </li>
              <li>
                <strong>Stress Management Counseling: </strong>Teaching
                techniques to handle everyday pressures effectively.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">
              Empathy at the Core of Our Approach
            </h4>
            <p className="text-black mb-6">
              We understand that seeking counseling can be a difficult decision.
              At Mindful Connection Hub, we prioritize empathy, respect, and
              active listening. Our counselors work collaboratively with clients
              to explore their concerns, identify goals, and celebrate progress.
              Each session is a step toward healing and growth.
            </p>

            <h4 className="text-lg font-semibold mb-3">Signs to Watch For</h4>
            <p className="text-black mb-6">
              If you or someone you care about is experiencing the following,
              counseling may be beneficial:
            </p>
            <ul className="list-disc list-inside text-black mb-6">
              <li>Persistent feelings of sadness, anger, or hopelessness</li>
              <li>Difficulty managing stress or anxiety</li>
              <li>Struggles with relationships or communication</li>
              <li>Loss of interest in activities once enjoyed</li>
              <li>Trouble coping with major life changes or trauma</li>
            </ul>
            <p className="text-black mb-6">
              Early intervention can make a significant impact on mental and
              emotional well-being.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              A Journey Toward Healing and Growth
            </h4>
            <p className="text-black mb-6">
              We believe everyone has the potential to lead a fulfilling life,
              no matter the obstacles. Counseling is a partnership that fosters
              self-awareness, resilience, and transformation. At Mindful
              Connection Hub, we are here to guide you on this journey,
              providing the tools and support you need to thrive.
            </p>
            <p className="text-black mb-6">
              Reach out to us today to learn more about our counseling programs.
              Together, we can build a path toward a brighter future.
            </p>
          </div>
        </div>

        <Pagination
          prevLink="/special-needs-disorder"
          prevText="Special Needs Disorder"
          nextLink="/psychological-disorder"
          nextText="Psychological Disorder"
        />
        
        <Footer />
      </div>
    </>
  );
}

export default CounselingExpertise;
