import React, { useRef, useState, forwardRef } from "react";
import { PopupModal } from "react-calendly";
import emailjs from "emailjs-com";

const BookingSection = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const form = useRef();

  // Handle input change and clear errors
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear validation error as user types
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // Validation Function
  const validateForm = () => {
    let newErrors = {};
    if (!formData.from_name.trim()) newErrors.from_name = "Name is required!";
    if (!formData.from_email.trim())
      newErrors.from_email = "Email is required!";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required!";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // Handle Booking
  const handleBooking = () => {
    if (!validateForm()) return;
    setIsOpen(true);
  };

  // Handle Online Session
  const handleOnlineSession = (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!validateForm()) return; // Stop if validation fails

    emailjs
      .sendForm(
        "service_9zf32n4", // Your EmailJS Service ID
        "template_f6hey6s", // Your EmailJS Template ID
        form.current,
        "Jm-LJ2CPrEQ_7f06V" // Your EmailJS Public Key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          form.current.reset();
          setFormData({ from_name: "", from_email: "", phone: "" });
          setErrors({}); // Clear errors after success

          // Open session link only after success
          window.open(
            "https://www.marham.pk/doctors/multan/clinical-psychologist/dr-habiba-nazim",
            "_blank"
          );
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Error sending message. Please try again.");
        }
      );
  };

  return (
    <section ref={ref} className="py-12 scroll-mt-24">
      <div className="relative z-10 max-w-4xl mx-auto text-center">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 my-3 sm:my-4 px-1 sm:px-2">
          Want To{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
            Avail the Offer?
          </span>
        </h2>
      </div>

      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-12 px-4 md:p-8 ">
        {/* Left Section */}
        <div className="flex-1 flex flex-col text-center md:text-left">
          <h2 className="text-xl sm:text-2xl md:text-4xl font-bold leading-snug text-[#509775] mt-2 sm:mt-4">
            Don't Let Stress Control
            <br className="hidden md:block" /> Your Life!
            <br />
            <span className="text-[#264b39] text-lg sm:text-xl md:text-3xl">
              Multan Walo Ab Tension Na Lo
            </span>
          </h2>
          <p className="mt-1 sm:mt-2 text-gray-500 text-base sm:text-lg md:text-xl font-semibold">
            Start your journey to a happier, healthier you today!
          </p>
        </div>

        {/* Right Section - Appointment Form */}
        <div className="flex-1 bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#3a7257] mb-4 sm:mb-6">
            Book an Appointment
          </h2>

          <form ref={form} className="w-full flex flex-col items-center">
            <input
              type="text"
              placeholder="Full Name"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              className={`w-full p-3 border rounded-lg mb-3 sm:mb-4 focus:outline-none focus:ring-2 ${
                errors.from_name ? "border-red-500" : "focus:ring-indigo-400"
              }`}
            />
            {errors.from_name && (
              <p className="text-red-500 text-sm">{errors.from_name}</p>
            )}

            <input
              type="tel"
              placeholder="Contact Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={`w-full p-3 border rounded-lg mb-3 sm:mb-4 focus:outline-none focus:ring-2 ${
                errors.phone ? "border-red-500" : "focus:ring-indigo-400"
              }`}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm">{errors.phone}</p>
            )}

            <input
              type="email"
              placeholder="Email Address"
              name="from_email"
              value={formData.from_email}
              onChange={handleChange}
              className={`w-full p-3 border rounded-lg mb-3 sm:mb-4 focus:outline-none focus:ring-2 ${
                errors.from_email ? "border-red-500" : "focus:ring-indigo-400"
              }`}
            />
            {errors.from_email && (
              <p className="text-red-500 text-sm">{errors.from_email}</p>
            )}

            {/* Buttons */}
            <div className="w-full flex flex-col sm:flex-row gap-3 sm:gap-4 mt-4">
              <button
                onClick={handleBooking}
                type="button"
                id="bookphysical"
                className="w-full sm:w-1/2 px-6 py-3 text-white font-semibold rounded-lg shadow-md transition-all duration-300 bg-gradient-to-r from-[#71a371] to-[#61987a] hover:brightness-90"
              >
                Physical Session
              </button>

              <button
                onClick={handleOnlineSession}
                type="submit"
                id="bookonline"
                className="w-full sm:w-1/2 px-6 py-3 text-white font-semibold rounded-lg shadow-md transition-all duration-300 bg-gradient-to-r from-[#71a371] to-[#61987a] hover:brightness-90"
              >
                Online Session
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Calendly Popup Modal */}
      {isOpen && (
        <PopupModal
          url="https://calendly.com/habibanazimkhan/30min"
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={document.body}
          prefill={{
            name: formData.from_name,
            email: formData.from_email,
            customAnswers: {
              a1: formData.phone, // Assuming "a1" is the field for phone number in Calendly
            },
          }}
        />
      )}
    </section>
  );
});

export default BookingSection;
