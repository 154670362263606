import React, { useState, useEffect, useRef } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import Header from "./Comp/Header";


function PrivacyPolicy({ color }) {
  const [openTab, setOpenTab] = useState(1); // Tracks the active tab
  const sectionsRef = useRef([]);

 
  // Effect to handle tab changes when sections are scrolled into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionsRef.current.indexOf(entry.target);
            setOpenTab(index + 1);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionsRef.current.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  // Function to handle tab click and scroll to the corresponding section
  const handleTabClick = (index) => {
    setOpenTab(index);
    if (sectionsRef.current[index - 1]) {
      sectionsRef.current[index - 1].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <TopNav />
      <Header
        leftTitle="Your Privacy Matters to Us"
        breadcrumbTitle="Privcy Policy"
      />
     <main>
      <section>
        <div className="flex flex-wrap my-12 py-10">
          {/* Tabs Section */}
          <div className="w-full flex lg:flex-row flex-col md:gap-x-12 gap-y-8 lg:gap-y-0 px-4 sm:px-8 md:px-16 ">
            <ul
              className="flex mb-0 list-none flex-wrap py-10  flex-col lg:w-1/4 px-4 2xl:px-8 lg:mt-20"
              role="tablist"
            >
              {[
                "Privacy Policy",
                "Information We Collect",
                "How We Use Your Information",
                "How We Protect Your Information",
                "Sharing Your Information",
                "Your Rights and Choices",
                "Changes to This Privacy Policy",
                "Contact Us",
              ].map((tabName, index) => (
                <li className="mb-2" key={index}>
                  <a
                    className={`text-sm font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                      openTab === index + 1
                        ? "text-white bg-[#61987a]"
                        : "text-white bg-[#bdb5c4] hover:bg-[#bcd2c4]"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick(index + 1); // Pass index to handleTabClick
                    }}
                    href={`#link${index + 1}`}
                    role="tab"
                  >
                    {tabName}
                  </a>
                </li>
              ))}
            </ul>

            {/* Content Section */}
            <div className="relative flex flex-col  w-full lg:w-3/4 min-w-0  break-words bg-white lg:py-5">
              <div className="px-4 lg:py-5 flex-auto">
                <div className="custom-scrollbar tab-content tab-space leading-relaxed overflow-y-auto max-h-[500px]  md:px-4 lg:pr-8 pr-2">
                  {[
                    {
                      id: 1,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Our Privacy Policy
                          </h3>
                          <p className="mb-2">
                            At{" "}
                            <span className="font-semibold">
                              Mindful Connection Hub,
                            </span>{" "}
                            we understand the importance of privacy and are
                            committed to maintaining the confidentiality and
                            security of your health information. This Privacy
                            Policy outlines how we collect, use, protect, and
                            share your health information in accordance with the
                            Health Insurance Portability and Accountability Act
                            (HIPAA) and other applicable privacy laws. By
                            accessing and using our website or services, you
                            consent to the practices described in this policy.
                          </p>
                          <ul className="list-disc list-inside text-black px-4">
                            <li className="mb-2">
                              This Privacy Policy serves as a comprehensive
                              guide to inform you about the types of information
                              we collect, the reasons we collect it, and the
                              methods we employ to safeguard your privacy. We
                              are dedicated to using your information solely to
                              improve your experience and deliver the services
                              you seek. We strictly adhere to privacy standards
                              and legal requirements to ensure your data is
                              never misused or shared without your explicit
                              consent.
                            </li>
                            <li className="mb-2">
                              Our goal is to build trust and foster confidence
                              in our digital environment. By accessing or using
                              our website, you acknowledge and agree to the
                              practices described in this Privacy Policy. We
                              encourage you to read this document carefully to
                              understand your rights and our obligations.
                            </li>
                            <li className="mb-2">
                              As a provider of psychological services, we
                              understand the sensitive nature of the data you
                              may share with us. We handle this information with
                              the utmost care, maintaining confidentiality and
                              implementing stringent security protocols to
                              protect it from unauthorized access.
                            </li>
                            <li className="mb-4">
                              If you have any concerns, questions, or require
                              further clarity regarding how we handle your
                              information, please do not hesitate to reach out.
                              Your privacy matters to us, and we are committed
                              to ensuring a safe and secure online experience
                              for all our users.
                            </li>
                          </ul>
                        </>
                      ),
                    },
                    {
                      id: 2,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Information We Collect
                          </h3>
                          <p className="text-base font-normal">
                            At Mindful Connection Hub, we are committed to
                            delivering services that meet your needs effectively
                            while ensuring your privacy is protected. In
                            compliance with HIPAA, we collect the following
                            information to provide you with services, manage
                            your health needs, and communicate with you
                            effectively:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  {" "}
                                  Personal Information:
                                </span>{" "}
                                We collect personally identifiable information
                                such as your name, contact details, and other
                                information you voluntarily provide when
                                scheduling appointments, filling out forms, or
                                signing up for updates.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Health-Related Information:{" "}
                                </span>
                                If you seek psychological services or
                                consultation, you may provide sensitive health
                                information, including mental health concerns,
                                treatment history, or other medical details. We
                                only collect this information with your explicit
                                consent, and we are committed to safeguarding
                                this data under HIPAA guidelines.
                              </li>
                              <li className="mb-4">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Non-Personal Information:{" "}
                                </span>{" "}
                                We may collect non-identifiable information such
                                as your IP address, browser type, and device
                                information when you interact with our website.
                                This data is used solely for improving the
                                functionality of the website and user
                                experience.
                              </li>
                            </ul>
                          </div>
                        </>
                      ),
                    },
                    {
                      id: 3,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            How We Use Your Information
                          </h3>
                          <p className="text-base font-normal">
                            We use the information collected to provide
                            services, enhance user experience, and ensure
                            compliance with legal requirements. Below is a
                            breakdown of how we use your data:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Provide Services:{" "}
                                </span>{" "}
                                We use your personal and health information to
                                schedule appointments, offer consultations, and
                                provide necessary resources. We ensure that all
                                data is used for health-related services in
                                accordance with HIPAA regulations.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Improve User Experience:{" "}
                                </span>
                                Non-personal data, such as website usage
                                patterns, helps us gain valuable insights into
                                how users interact with our platform. For
                                instance, by analyzing which pages are visited
                                most frequently or the time spent on specific
                                resources, we can identify areas that may need
                                improvement.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Communication:{" "}
                                </span>{" "}
                                We may use your contact details to send you
                                updates, newsletters, or health-related
                                information, including reminders about
                                appointments or relevant mental health
                                resources. You can opt-out of these
                                communications at any time.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Security:{" "}
                                </span>{" "}
                                We use the information to detect and prevent
                                fraud or unauthorized access. Our security
                                measures are implemented to safeguard your
                                health data from breaches and comply with
                                HIPAA's security standards.
                              </li>
                              <li className="mb-4">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Compliance:{" "}
                                </span>{" "}
                                We may be required to use or disclose your
                                information to comply with HIPAA, or other state
                                or federal laws. This could include reporting
                                incidents to law enforcement or responding to
                                legal inquiries.
                              </li>
                            </ul>
                          </div>
                        </>
                      ),
                    },
                    {
                      id: 4,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            How We Protect Your Information
                          </h3>
                          <p className="text-base font-normal">
                            We take the protection of your personal and health
                            information seriously. In accordance with HIPAA, we
                            implement a variety of security measures to
                            safeguard your data:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Data Encryption:{" "}
                                </span>{" "}
                                All health-related information is encrypted both
                                during transmission and at rest to prevent
                                unauthorized access.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Secure Servers:{" "}
                                </span>
                                Your data is stored on secure servers that are
                                protected by advanced firewalls and strict
                                access controls.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Access Restrictions:{" "}
                                </span>{" "}
                                Access to your personal information is limited
                                to authorized personnel who require it to
                                perform their job responsibilities, such as
                                providing services or managing inquiries. These
                                individuals are bound by strict confidentiality
                                agreements and undergo training on data privacy
                                and security protocols.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Regular Security Audits:{" "}
                                </span>{" "}
                                We maintain logs of all access to your health
                                data, as required by HIPAA. These audit trails
                                help us ensure compliance with privacy and
                                security standards.
                              </li>
                            </ul>
                          </div>
                          <p className="text-base font-normal mb-4">
                            We recommend taking personal precautions, such as
                            using strong passwords and avoiding sharing
                            sensitive data over unsecured networks. Your trust
                            is critical to us, and we continuously invest in
                            technologies, training, and policies to ensure that
                            your information is handled with the highest level
                            of care and security.
                          </p>
                        </>
                      ),
                    },
                    {
                      id: 5,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Sharing Your Information
                          </h3>
                          <p className="text-base font-normal">
                            We do not sell, rent, or share your personal health
                            information with third parties except as permitted
                            by HIPAA and as outlined below:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Legal Compliance:{" "}
                                </span>{" "}
                                If required by law, we may disclose your health
                                information to appropriate authorities,
                                government agencies, or legal bodies to comply
                                with HIPAA or other legal requirements. This
                                includes situations such as mandatory reporting
                                of certain health conditions.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Service Providers:{" "}
                                </span>
                                We may share your health information with
                                trusted service providers such as electronic
                                health record (EHR) systems, payment processors,
                                or communication services to facilitate services
                                related to your care. These third parties are
                                required to comply with HIPAA regulations and
                                ensure the confidentiality of your data.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Your Consent:{" "}
                                </span>{" "}
                                In cases where sharing your information is not
                                covered by the scenarios above, we will seek
                                your explicit consent before proceeding. For
                                example, if we wish to share your data for
                                marketing or promotional activities, we will
                                provide clear details and give you the
                                opportunity to opt in or decline.
                              </li>
                            </ul>
                          </div>
                          <p className="text-base font-normal mb-4">
                            By maintaining transparency about when and why we
                            share your information, we aim to build trust and
                            provide you with peace of mind.If you have any
                            concerns or questions about how your information is
                            shared, please feel free to contact us.
                          </p>
                        </>
                      ),
                    },
                    {
                      id: 6,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Your Rights and Choices
                          </h3>
                          <p className="text-base font-normal">
                            As a user, you have specific rights regarding your
                            personal information. We are committed to respecting
                            and protecting these rights. Below are the key
                            rights you can exercise:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Access to Information:{" "}
                                </span>{" "}
                                You can request access to the personal
                                information we hold about you at any time. If
                                you want to see or verify the data we have,
                                simply reach out to us, and we will provide it.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Correction of Information:{" "}
                                </span>
                                If any of your personal data is inaccurate or
                                incomplete, you have the right to request
                                corrections. Please inform us of any errors, and
                                we will update it promptly.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Deletion of Information:{" "}
                                </span>{" "}
                                You can request the deletion of your personal
                                information. While we will delete most data upon
                                request, some data may need to be retained for
                                legal or operational reasons.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Data Portability:{" "}
                                </span>{" "}
                                You have the right to request a copy of your
                                data in a format that can be transferred to
                                another provider if feasible. This right applies
                                to data collected based on consent or
                                contractual necessity.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Lodging a Complaint:{" "}
                                </span>{" "}
                                If you feel your rights have been violated, you
                                can lodge a complaint with the relevant data
                                protection authority. We encourage you to
                                contact us first, and we will work with you to
                                resolve the issue.
                              </li>
                            </ul>
                          </div>
                          <p className="text-base font-normal mb-4">
                            To exercise any of these rights or if you have
                            questions, please contact us. We will respond
                            promptly and in accordance with data protection
                            laws.
                          </p>
                        </>
                      ),
                    },
                    {
                      id: 7,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Changes to This Privacy Policy
                          </h3>
                          <p className="text-base font-normal mb-2">
                            We reserve the right to update, modify, or revise
                            this Privacy Policy at any time in order to reflect
                            changes in our practices, legal obligations, or
                            technological advancements. As our services evolve
                            or as new privacy laws are enacted, we may need to
                            adjust the way we handle your information. These
                            updates may include, but are not limited to, changes
                            in the types of data we collect, how we use your
                            information, or our security measures.
                          </p>
                          <p className="text-base font-normal mb-2">
                            When we make significant updates to this Privacy
                            Policy, we will notify you of these changes in the
                            following ways:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Email Notifications:{" "}
                                </span>{" "}
                                If we have your contact details, we may send you
                                an email informing you of the changes,
                                especially if they affect your rights or the way
                                we process your data.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Website Notice:{" "}
                                </span>
                                We may also place a prominent notice on our
                                website to inform users of the changes. This
                                notice will be easily visible when you visit our
                                site, ensuring that you are aware of any
                                important modifications.
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Clear "Last Updated" Date:{" "}
                                </span>{" "}
                                Every time we update the policy, the "Last
                                Updated" date at the top of the page will
                                reflect the date of the most recent revisions.
                                This helps you know when the policy was last
                                reviewed or changed.
                              </li>
                            </ul>
                          </div>
                          <p className="text-base font-normal mb-4">
                            We encourage you to review this Privacy Policy
                            periodically to stay informed about how we collect,
                            use, and protect your personal information. By
                            continuing to use our services after any changes are
                            made, you are agreeing to the revised terms. If you
                            disagree with any updates, you may choose to
                            discontinue your use of our website and services.
                          </p>
                        </>
                      ),
                    },
                    {
                      id: 8,
                      description: (
                        <>
                          <h3 className="text-xl font-semibold mb-4 mt-4">
                            Contact Us
                          </h3>
                          <p className="text-base font-normal mb-2">
                            If you have any questions, concerns, or requests
                            regarding this Privacy Policy or our data handling
                            practices, please contact us:
                          </p>
                          <div className="py-4 px-4">
                            <ul className="list-disc list-inside text-black">
                              <li className="mb-2">
                                <span className="text-base font-semibold mt-2">
                                  Email:{" "}
                                </span>{" "}
                                <a
                                  href="mailto: Contact@mindfulch.com"
                                  className=" hover:underline text-blue-500"
                                >
                                   Contact@mindfulch.com
                                </a>
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  Call:{" "}
                                </span>
                                <a
                                  href="https://wa.me/923339120600"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-lg  hover:underline text-blue-500"
                                >
                                  +92 3339120600 (WhatsApp)
                                </a>
                              </li>
                              <li className="mb-2">
                                <span className="text-base font-semibold">
                                  {" "}
                                  Address:{" "}
                                </span>{" "}
                                Model Town, Haseeb Street, 42-43 B, Multan,
                                Pakistan.
                              </li>
                            </ul>
                          </div>
                          <p className="text-base font-normal mb-4">
                            We are committed to addressing your concerns
                            promptly and transparently.
                          </p>
                        </>
                      ),
                    },
                  ].map((section, index) => (
                    <div
                      key={section.id}
                      ref={(el) => (sectionsRef.current[index] = el)} // Set ref for each section
                      id={`link${section.id}`}
                      className="tab-pane fade pt-20"
                    >
                      {section.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
