import React from "react";

function BrowseButton() {
  return (
    <>
      <div className="flex flex-col md:flex-row h-auto px-4 sm:px-8 md:px-16 lg:px-32 gap-y-4 md:gap-x-12">
        <div className="flex flex-col w-full md:w-1/2 py-10">
          <h2 className="text-2xl sm:text-3xl text-[#4a755d]  font-extrabold bg-border border-b-4 border-b-[#4a755d]  py-5">
            Related Services
          </h2>
        </div>
        <div className="flex flex-col w-full md:w-1/2 pt-12 items-center md:items-start">
          <a
            href="/services"
            className="bg-[#4a755d] text-white active:bg-[#18261f] font-semibold py-2 px-4 rounded-lg mt-8 mb-3 outline-none focus:outline-none  hover:shadow-2xl ease-linear transition-all duration-150 text-center"
          >
            Browse All Services
          </a>
        </div>
      </div>
    </>
  );
}

export default BrowseButton;
