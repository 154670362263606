import React, { useState,  } from "react";

const FaqForStress = () => {
      const [activeIndex, setActiveIndex] = useState(null);
    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
      };
      const questions = [
        {
          "question": "How do I know if therapy will actually help me?",
          "answer": "Our stress therapy in Multan is designed to provide practical solutions tailored to your unique challenges. Whether you're struggling with anxiety, depression, or feeling overwhelmed, our proven techniques can deliver noticeable improvements in your daily life."
        },
        {
          "question": "Will my personal information remain confidential?",
          "answer": "Absolutely. Your privacy is our top priority. Every psychology counseling session in Multan is 100% confidential, ensuring your personal struggles remain safe and secure."
        },
        {
          "question": "What happens during the session?",
          "answer": "In your 45-minute session, we’ll discuss your concerns, identify root causes, and provide actionable steps for managing stress, anxiety, or depression. This personalized approach ensures you leave with a clear plan for improving your mental well-being."
        },
        {
          "question": "I’ve never tried therapy before. Is it awkward or uncomfortable?",
          "answer": "Not at all. Our experienced mental health psychologist in Multan creates a welcoming space where you can speak freely without fear of judgment. The session feels like a relaxed conversation with someone who genuinely wants to help."
        },
        {
          "question": "How soon can I expect results?",
          "answer": "Many clients report feeling calmer and more in control after just one session. While deeper concerns may require additional sessions, our focus is to deliver effective anxiety treatment in Multan and depression treatment in Multan with practical strategies."
        },
        {
          "question": "What if I’m too busy to attend a session in person?",
          "answer": "We offer both in-person and online psychotherapy for your convenience. Whether you prefer face-to-face support or a virtual session from home, we’re here to help."
        },
        {
          "question": "Will I be pressured into long-term therapy?",
          "answer": "Not at all. Our 45-minute session is designed to provide effective solutions in one sitting. If you feel additional support is helpful, we’ll discuss that—but the decision is entirely yours."
        },
        {
          "question": "Do I need to take medication to feel better?",
          "answer": "No. Our focus is on proven psychotherapy techniques and practical solutions to improve your mental well-being—without relying on medication."
        },
        {
          "question": "What if I feel uncomfortable sharing my personal problems?",
          "answer": "That’s completely normal. Our compassionate mental health psychologist in Multan is trained to guide conversations gently. You control what you wish to share—there’s no pressure to disclose anything that makes you uncomfortable."
        },
        {
          "question": "Why should I choose this therapy over free advice from friends or family?",
          "answer": "While loved ones mean well, professional psychology counseling in Multan offers structured strategies backed by years of experience and proven methods. We provide personalized solutions tailored to your emotional well-being—because your peace of mind deserves expert care."
        }
      ];
      
  return (
    
    <section>
          <div className="container mx-auto px-4 md:px-8 lg:px-32 py-12">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold mb-10 px-2">
              Do you have any questions?
            </h2>
            <div className="space-y-2">
              {questions.map((question, index) => (
                <div
                  key={index}
                  className=" border-b-2 border-gray-200 rounded-lg p-4"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h3 className="text-lg font-medium">{question.question}</h3>{" "}
                     
                    <span className="text-gray-500">
                      {activeIndex === index ? "▲" : "▼"}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className="mt-2 text-gray-700">{question.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
  )
}

export default FaqForStress;
