import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import defaultImage from "../../assets/Silhouette-banner-1.webp";
import Pagination from "../Comp/Pagination";
import img1 from "../../assets/panic-attack.webp";

function Psychological() {
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Psychological Disorders"
          breadcrumbTitle="Expertise"
        />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 lg:px-32 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            loading="lazy"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1]"
          />
        </div>

        <div className="py-10 px-4 sm:px-8 md:px-16 lg:px-32">

          <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-extrabold mb-6">
            Psychological Disorders
          </h1>
            <p className="text-black mb-4">
              <strong>Understanding Psychological Disorders</strong>
            </p>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we understand that psychological
              disorders can profoundly impact lives, relationships, and personal
              well-being. Our mission is to provide compassionate,
              evidence-based care that helps individuals navigate these
              challenges with strength and resilience.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              What Are Psychological Disorders?
            </h4>
            <p className="text-black mb-6">
              Psychological disorders are complex conditions that influence
              thoughts, emotions, and behaviors. They can manifest in various
              forms, often creating obstacles in daily life. However, with the
              right support and treatment, individuals can overcome these
              challenges and thrive.
            </p>

            <h4 className="text-lg font-semibold mb-3">
              Common psychological disorders we address include:
            </h4>
            <ul className="list-disc list-inside text-black mb-6">
              <li>
                Anxiety Disorders: Persistent and overwhelming feelings of worry
                or fear.
              </li>
              <li>
                Mood Disorders: Conditions such as depression or bipolar
                disorder that affect emotional balance.
              </li>
              <li>
                Obsessive-Compulsive Disorder (OCD): Recurrent, intrusive
                thoughts paired with compulsive behaviors.
              </li>
              <li>
                Post-Traumatic Stress Disorder (PTSD): Emotional distress
                triggered by traumatic experiences.
              </li>
              <li>
                Eating Disorders: Unhealthy relationships with food, body image,
                and self-esteem.
              </li>
              <li>
                Personality Disorders: Long-standing patterns of behavior and
                thinking that impact interpersonal relationships.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">
              Our Approach to Healing
            </h4>
            <p className="text-black mb-6">
              At Mindful Connection Hub, we don’t see psychological disorders as
              labels or limitations. Instead, we focus on understanding the
              individual behind the diagnosis. Our holistic and personalized
              care is designed to address the root causes of distress and foster
              long-term well-being.
            </p>

            <h5 className="text-md font-semibold mb-3">What Sets Us Apart:</h5>
            <ul className="list-disc list-inside text-black mb-6">
              <li>
                <strong>Tailored Care Plans:</strong> We develop individualized
                strategies to meet your specific needs and goals.
              </li>
              <li>
                <strong>Compassionate Expertise:</strong> Our team of trained
                professionals approaches every case with empathy and
                understanding.
              </li>
              <li>
                <strong>Innovative Therapies:</strong> We integrate traditional
                practices such as Cognitive Behavioral Therapy (CBT) and
                Dialectical Behavior Therapy (DBT) with mindfulness techniques
                and modern therapeutic tools to provide comprehensive care.
              </li>
            </ul>

            <h4 className="text-lg font-semibold mb-3">
              A Path to Recovery and Growth
            </h4>
            <p className="text-black mb-6">
              Living with a psychological disorder can feel isolating, but it’s
              important to remember that help is available. At Mindful
              Connection Hub, we are committed to walking this journey with you,
              offering unwavering support and guidance every step of the way.
            </p>

            <p className="text-black mb-6">
              If you or someone you know is seeking help, don’t hesitate to
              reach out. Let’s work together to create meaningful change and
              open the door to a healthier, more fulfilling life.
            </p>
          </div>
        </div>

        <Pagination
          prevLink="/counseling-exp"
          prevText="Counseling"
          nextLink="/childhood-disorder"
          nextText="Childhood Disorder"
        />
        
        <Footer />
      </div>
    </>
  );
}

export default Psychological;
