/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import img2 from "../assets/Clinical Psychologist - Habiba Nazim Khan.webp";
import img3 from "../assets/Psychologist and therapist near me.webp";
import imgcounseling from "../assets/psychology-counseling-in-multan.webp";
import img4 from "../assets/Stress Therapy Multan-2.webp";
import img5 from "../assets/mental health doctor.webp";
import img6 from "../assets/Multan Mental health clinic.webp";
import img0 from "../assets/flowers-crop-4.webp";
import img7 from "../assets/health-problems.jpg";
import img8 from "../assets/Mental Health psychologist in-Multan.webp";
import imgx from "../assets/Habiba Nazim Khan Psychologist.webp";
import imgy from "../assets/Psychologist-and-therapist-near-me.png";

import InfoSection from "./Comp/InfoSection";
import "../style/style.css";
import UpcomingEventsBar from "./Comp/UpcomingEventsBar";
import WhatsAppButton from "./Comp/WhatsAppButton";
import VideoSlider from "./Comp/VideoSlider";
import BlogSectionWithCarousel from "./Comp/BlogSectionWithCarousel";
import { Helmet } from "react-helmet-async";
import PricingPlans from "./Comp/PricingPlans";
import InfiniteCarousel from "./ImageCarousel";
import HeroSectionMain from "./Comp/SliderForHero";
export default function Home() {
  const [showThumbnail, setShowThumbnail] = useState(true);

  const handleThumbnailClick = () => {
    setShowThumbnail(false);
  };
  const videoSrc = "https://www.youtube.com/embed/YrAIW5GOUOU?autoplay=1";

  const [activeTab, setActiveTab] = useState("Psychotherapy");
  const [activeIndex, setActiveIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const tabNames = [
    "Psychotherapy",
    "Stress Management",
    "Psychology Counseling",
    "Speech Therapy",
    "Dialectical Behavior Therapy (DBT)",
    "Cognitive Behavior Therapy (CBT)",
    "Couple Therapy",
    "Eye Movement Desensitization and Reprocessing (EMDR)",
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    if (isHovered) return; // Pause if hovered

    const interval = setInterval(() => {
      setActiveTab((prevTab) => {
        const currentIndex = tabNames.indexOf(prevTab);
        const nextIndex = (currentIndex + 1) % tabNames.length;
        return tabNames[nextIndex];
      });
    }, 3000);

    return () => clearInterval(interval); // Clean up on unmount or hover change
  }, [isHovered, tabNames]);

  const questions = [
    {
      question: "What services do you offer?",
      answer:
        "As one of the Best Mental health services Multan, we offer a wide range of services, including individual therapy, Stress Management Psychotherapy, couple counseling, family therapy, speech therapy, and assistance for children with special needs. Whatever your concern, we are ready to listen and help.",
    },
    {
      question: "Who can benefit from therapy?",
      answer:
        "Therapy is for everyone. Whether you are facing emotional struggles, relationship challenges or simply seeking personal growth, We are here to walk with you every step of the way.",
    },
    {
      question: "How do I know which therapy is right for me?",
      answer:
        "You dont have to figure this out alone. During our initial session we will discuss your concerns and recommend the best approach for your sitiation.",
    },
    {
      question: "Do you work with special needs children?",
      answer:
        "Yes, We are committed to helping children with autism, ADHD, speech delays and other developmental neeeds. Every child is unique and we celebrate their strength while supporting their growth.",
    },
    {
      question: "Is therapy confidential?",
      answer:
        "Absolutely. Your privacy is our top priority. What you share stays between us, except in rare situations involving safety concerns. We are here to provide a judgement-free, secure environment. ",
    },
    {
      question: "How do I book an appointment?",
      answer:
        "Booking is simple and flexible. You can schedule an appointment online through our website, or by calling us directly. We are here to make the process as easy as possible for you. ",
    },
    {
      question: "Do you offer online therapy options?",
      answer:
        "Yes, We understand that life can be busy or circumstances might limit travel. Our secure online sessions allow you to connect with us from the comfort of your own space.",
    },
  ];
  const slides = [
    {
      imgSrc: img8,
      title: "Mental Health Psychologist in Multan",
      subtitle: "Computer Consulting and Software Development",
      description:
        "Mindful Connection Hub is a dedicated mental health clinic, led by PhD scholar Habiba Nazim Khan. Passionate about improving mental well-being, she is actively working to promote psychology counseling in Multan.",
      buttonText: "Book a Session Now!",
      buttonLink: "./booknow",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [slides.length]);

  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.mindfulch.com/#website",
        url: "https://www.mindfulch.com/",
        name: "Mindfulch",
        publisher: {
          "@id": "https://www.mindfulch.com/#organization ",
        },
        inLanguage: ["en", "ur"],
      },
      {
        "@type": "Organization",
        "@id": "https://www.mindfulch.com/#organization",
        name: "Mindful Connection Hub",
        url: "https://www.mindfulch.com/",
        logo: "https://www.mindfulch.com/static/media/Logoo.1797d109abd253ceea64.webp",
        sameAs: [
          "https://www.facebook.com/profile.php?id=61574094790029",
          "https://www.linkedin.com/company/mindful-connection-hub",
          "https://www.instagram.com/habibanazimkhan/",
          "https://www.tiktok.com/@habibanazimkhan1",
          "https://www.youtube.com/@HabibaNazimKhan",
        ],
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "+92 3040186444",
          contactType: "customer support",
          areaServed: "Worldwide",
          availableLanguage: ["English", "Urdu"],
        },
      },
      {
        "@type": "WebPage",
        "@id": "https://www.mindfulch.com/#webpage",
        url: "https://www.mindfulch.com/",
        name: "Mindfulch",
        isPartOf: {
          "@id": "https://www.mindfulch.com/#website",
        },
        breadcrumb: {
          "@id": "https://www.mindfulch.com/#breadcrumb",
        },
        inLanguage: ["en", "ur"],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.mindfulch.com/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Mindfulch",
            item: "https://www.mindfulch.com/",
          },
        ],
      },
      {
        "@type": "Person",
        "@id": "https://www.mindfulch.com/#founder",
        name: "Habiba Nazim Khan",
        url: "https://www.mindfulch.com/about",
        image:
          "https://www.mindfulch.com/static/media/09369008-e051-4fc0-93c1-13bd9fc9f7f0-removebg-preview.cbc252f4b376ff9f70f5.png",
        jobTitle: "Founder",
        worksFor: {
          "@id": "https://www.mindfulch.com/#organization",
        },
      },
      {
        "@type": "Person",
        "@id": "https://www.mindfulch.com/#team-member-1",
        name: "Zarnab Kamal",
        jobTitle: "Assistant Clinical Psychologist",
        image:
          "https://www.mindfulch.com/static/media/d17e4984-6ff1-4d04-90e9-2d4f182d9062-removebg-preview.8f139377544a1ee1d6a6.png",
        worksFor: {
          "@id": "https://www.mindfulch.com/#organization",
        },
      },
      {
        "@type": "ItemList",
        "@id": "https://www.mindfulch.com/#expertise",
        name: "Our Expertise",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Psychological Disorders",
            url: "https://www.mindfulch.com/psychological-disorder",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Childhood Disorders",
            url: "https://www.mindfulch.com/childhood-disorder",
          },
          {
            "@type": "ListItem",
            position: 3,
            name: "Special-Needs Disorders",
            url: "https://www.mindfulch.com/special-needs-disorder",
          },
          {
            "@type": "ListItem",
            position: 4,
            name: "Counseling",
            url: "https://www.mindfulch.com/counseling-exp",
          },
        ],
      },
      {
        "@type": "VideoObject",
        "@id": "https://www.mindfulch.com/#founder-video",
        name: "Meet Our Founder",
        description: "All about MindfulConnection Hub",
        thumbnailUrl:
          "https://i.ytimg.com/vi/vKFqukLT9C4/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLA7Wih_AgK5AsfD61tNk0a_-Lqrkg",
        uploadDate: "2024-09-02",
        embedUrl: "https://www.youtube.com/embed/vKFqukLT9C4",
      },

      {
        "@type": "FAQPage",
        "@id": "https://www.mindfulch.com/#faq",
        mainEntity: [
          {
            "@type": "Question",
            name: "What services do you offer?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "We are here to support you with a wide range of services, including individual therapy, couple counseling, family therapy, speech therapy, and assistance for children with special needs. Whatever your concern, we are ready to listen and help.",
            },
          },
          {
            "@type": "Question",
            name: "Who can benefit from therapy?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Therapy is for everyone. Whether you are facing emotional struggles, relationship challenges or simply seeking personal growth, We are here to walk with you every step of the way.",
            },
          },
        ],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Mental Health Psychologist in Multan | MCH By Habiba Nazim Khan
        </title>
        <meta
          name="description"
          content="Looking for a trusted Mental Health Psychologist in Multan? At MCH by Habiba Nazim Khan, we offer expert counseling and therapy services for stress, anxiety, and depression. If you're searching for a psychologist and therapist near me, your path to mental well-being starts here."
        />

        <link rel="canonical" href="https://www.mindfulch.com/" />

        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      {/* <MetaTags page="home" /> */}
      <TopNav />
      <main>
        <HeroSectionMain />
        {/* <section id="carouselHeader" className="relative overflow-hidden">
          <div className="relative h-[800px]">
            <div className="relative w-full h-full overflow-hidden">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ${
                    index === currentIndex ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <img
                    src={slide.imgSrc}
                    className="w-full h-full object-cover zoom-animation"
                    alt="Carousel Slide"
                    loading={index === 0 ? "eager" : "lazy"}
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60">
                    <div className="container mx-auto text-center px-4">
                      <h1 className="text-white font-semibold mt-10 text-2xl sm:text-4xl md:text-5xl lg:text-6xl">
                        {slide.title}
                      </h1>
                      {slide.description && (
                        <p
                          className="mt-8 text-gray-50 text-sm md:text-base lg:text-xl"
                          dangerouslySetInnerHTML={{
                            __html: slide.description,
                          }}
                        ></p>
                      )}
                      {slide.buttonText && (
                        <a
                          href={slide.buttonLink}
                          className="inline-block font-semibold bg-[#d2e8dcef] text-[#255b39] text-sm uppercase tracking-widest py-3 ml-2 px-8 mt-8 hover:bg-[#407d56] hover:text-white transition-colors duration-200 ease-in-out rounded-md"
                        >
                          {slide.buttonText}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}

        <section className=" relative ">
          <div className="absolute inset-0 z-0">
            <img
              src={img0}
              alt="Background"
              className="w-auto h-auto object-cover lg:opacity-70"
            />
          </div>

          {/* Content Section */}
          <div className="relative container mx-auto px-4 lg:px-8 py-10 ">
            {" "}
            {/* Ensure content is on top of overlay */}
            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">
                {/* Profile Image */}
                <div className="w-full lg:w-1/3  mt-10 py-10 px-4 transition-transform duration-500 hover:scale-105 items-center">
                  <img
                    alt="Clinical Psychologist - Habiba Nazim Khan"
                    className="max-w-full rounded-lg transform transition-transform duration-1000 hover:scale-100 lg:pl-10 h-auto lg:h-[400px] "
                    src={img2}
                  />
                </div>

                {/* Text Content */}
                <div className="w-full lg:w-2/3 lg:px-12 items-center ">
                  <div className="md:pr-10">
                    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
                      Habiba Nazim Khan
                    </h2>
                    <p className="mt-4 text-normal lg:text-lg xl:text-xl  leading-relaxed px-2 text-stone-700 font-medium">
                      I am Dr. Habiba Nazim Khan, founder of Mindful Connection
                      Hub, with over a decade of experience in{" "}
                      <a
                        href="https://www.apa.org/ed/graduate/specialize/clinical"
                        className="text-blue-800 hover:underline hover:text-blue-600 hover:underline-offset-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clinical Psychology.
                      </a>{" "}
                      I specialize in various therapies, including
                      psychotherapy, depression treatment, couple therapy, and
                      interpersonal therapy.
                    </p>
                    <p className="mt-4 text-normal lg:text-lg xl:text-xl leading-relaxed px-2 text-stone-700 font-medium">
                      If you're looking for a trusted psychologist or therapist
                      near me in Multan, your search ends here!
                    </p>
                    <a
                      href="/about"
                      className="inline-block border-2 border-[#447458] font-semibold  text-[#447458] text-sm uppercase tracking-widest py-3 px-8 mt-8 hover:bg-#3d6b50] hover:text-white hover:bg-[#447458] transition-colors duration-200 ease-in-out"
                      aria-label="Learn more about us"
                    >
                      About
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <InfiniteCarousel />

        <section>
          <div className="max-w-xl mx-auto text-center py-10 mt-10">
            <div className="w-24 h-2 bg-[#5d9676] mb-4 mx-auto"></div>

            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
              Our Expertise
            </h2>
          </div>
          <div className="flex flex-wrap w-full">
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Psychology Counselling
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Psychology Counselling
                </h3>
                <p className="text-center text-lg mb-4">
                  Most effective yet affordable psychology counseling in Multan
                </p>
                <a
                  href="/counseling-exp"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={imgcounseling}
                alt="psychology counseling in multan"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
            {/*  Stress Management Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Stress Management
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#262525] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Stress Management
                </h3>
                <p className="text-center text-lg mb-4">
                  We help stressful people with anxiety or depression treatment
                  so they can lead a happy life.
                </p>
                <a
                  href="/stresstherapymultan"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img4}
                alt="Stress Therapy Multan-2"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>

            {/* Special Needs Disorder Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Special Needs Disorder
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Special Needs Disorder
                </h3>
                <p className="text-center text-lg mb-4">
                  Gain insights into special needs disorders and therapeutic
                  strategies.
                </p>
                <a
                  href="/special-needs-disorder"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img5}
                alt="mental health doctor"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>

            {/* Counseling */}

            {/* Psychological Disorder Section */}
            <div className="relative w-full md:w-auto md:flex-1 flex items-center justify-center h-96 bg-black group">
              {/* Heading - Default on image */}
              <div className="absolute inset-0 flex items-center justify-center text-2xl font-medium uppercase text-white z-10 group-hover:hidden">
                Psychological Disorder
              </div>

              {/* Hover State with Background and Content */}
              <div className="absolute inset-0 bg-[#3f4140] bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white p-4 z-20">
                <h3 className="text-2xl font-medium uppercase mb-4">
                  Psychological Disorder
                </h3>
                <p className="text-center text-lg mb-4">
                  Explore various psychological disorders and their treatment
                  approaches.
                </p>
                <a
                  href="/psychological-disorder"
                  className="inline-flex items-center text-sm font-semibold bg-white hover:bg-[#5a826c] hover:text-white text-black py-2 px-4 rounded"
                >
                  Read More
                </a>
              </div>

              {/* Image */}
              <img
                src={img3}
                alt="Psychologist and therapist near me"
                className="absolute inset-0 w-full h-full object-cover opacity-50 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2  py-10">
            {/* Video Section */}
            <div className="bg-white p-8 px-12 py-20 flex justify-end items-center">
              <div className="relative w-full aspect-video lg:h-[500px]">
                {showThumbnail ? (
                  <img
                    src={img6}
                    alt="Multan Mental health clinic.webp"
                    className="w-full h-full object-contain cursor-pointer hover:scale-105 duration-100"
                    onClick={handleThumbnailClick}
                    loading="lazy"
                  />
                ) : (
                  <iframe
                    src={videoSrc}
                    className="w-full h-full"
                    title="YouTube video player"
                    allow="autoplay"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>

            {/* Experience Section */}
            <div className="p-8 sm:p-12 md:p-10 2xl:p-20 flex justify-start ">
              <div className="w-full xl:max-w-4xl">
                <div className="w-24 h-2 bg-[#5d9676] mb-4"></div>
                <h2 className="text-3xl sm:text-4xl  font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]  mb-12">
                  Bringing Proven Experience In...
                </h2>
                <div className="md:flex max-w-3xl relative">
                  {/* Tabs Section */}

                  <div className="w-full rounded-xl shadow-xl py-6 my-6 bg-gradient-to-b from-[#71a371] to-[#61987a] md:relative z-20 md:-mt-2 md:-mr-20">
                    <ul className="flex flex-col space-y-1 text-sm font-medium text-gray-500 mb-4">
                      {tabNames.map((tabName, index) => (
                        <li key={index} className="w-full">
                          <button
                            onClick={() => setActiveTab(tabName)}
                            className={`inline-flex items-center px-4 py-3 rounded-lg w-full text-left ${
                              activeTab === tabName
                                ? "text-white bg-[#79c379] text-base font-semibold"
                                : "text-white hover:bg-[#6ea96e]"
                            }`}
                            aria-label={`Switch to ${tabName} tab`} // Added ARIA label
                            role="tab" // Defines the button as a tab
                            aria-selected={activeTab === tabName} // Indicates which tab is active
                          >
                            {tabName}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Tab Content Section */}
                  <div
                    className="p-6 md:pl-20 bg-[#d6e8d6] text-lg rounded-xl shadow-2xl text-gray-600  md:rounded-tr-lg md:rounded-br-lg w-full md:mt-16 md:ml-4 relative z-10"
                    onMouseEnter={() => setIsHovered(true)} // Pause on hover
                    onMouseLeave={() => setIsHovered(false)} // Resume when hover ends
                  >
                    {activeTab === "Psychotherapy" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          Psychotherapy
                        </h3>
                        <p className="mb-2">
                          Psychotherapy involves guided conversations and
                          therapeutic techniques to help individuals manage
                          emotional, behavioral, and mental health challenges.
                        </p>
                        <p className="mb-4  line-clamp-3">
                          {" "}
                          It can address issues like anxiety, depression,
                          trauma, and stress, empowering individuals to
                          understand and change patterns that impact their
                          well-being.
                        </p>
                        <a
                          href="/psychotherapy"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Stress Management" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          <a href="/stresstherapymultan">Stress Management</a>
                        </h3>
                        <p className="mb-2">
                          Stress management as a service provides individuals
                          with anxiety and depression treatment tools,
                          techniques and support to reduce feelings of anger and
                          stress, aiming to improve mental well-being and
                          overall quality of life.
                        </p>
                        <a
                          href="/stresstherapymultan"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Couple Therapy" && (
                      <div>
                        <h3 className="text-lg font-bold  mb-4">
                          Couple Therapy
                        </h3>
                        <p className="mb-2">
                          Couple therapy is a form of counseling designed to
                          help couples improve their relationship by addressing
                          various emotional and psychological issues. It helps
                          couples navigate challenges.
                        </p>
                        <p className="mb-4 line-clamp-2">
                          By focusing on the dynamics between partners, couple
                          therapy aims to rebuild intimacy, strengthen the
                          emotional connection and fulfilling relationship.
                        </p>
                        <a
                          href="/coupletherapy"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Psychology Counseling" && (
                      <div>
                        <h3 className="text-lg font-bold  mb-4">
                          Psychology Counseling
                        </h3>
                        <p className="mb-2 line-clamp-7">
                          Counseling offers a safe space for individuals to
                          address personal, emotional, or psychological
                          concerns. It helps clients understand their feelings,
                          develop coping mechanisms, and work towards making
                          positive changes to enhance their overall mental
                          health.
                        </p>
                        <a
                          href="/counseling"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Speech Therapy" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          Speech Therapy
                        </h3>
                        <p className="mb-2">
                          Speech therapy is a specialized treatment that helps
                          individuals with speech, language, and communication
                          challenges.
                        </p>
                        <p className="mb-2">
                          It is beneficial for those with speech delays,
                          stuttering, articulation issues, voice disorders.
                        </p>
                        <p className="mb-4 line-clamp-1">
                          The therapy may also address difficulties with
                          understanding and expressing thoughts, as well as
                          social communication skills.
                        </p>
                        <a
                          href="/speechtherapy"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Dialectical Behavior Therapy (DBT)" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          Dialectical Behavior Therapy (DBT)
                        </h3>
                        <p className="mb-2">
                          Dialectical Behavior Therapy (DBT) is a form of
                          cognitive-behavioral therapy that focuses on helping
                          individuals manage intense emotions and improve
                          interpersonal relationships.
                        </p>
                        <p className="mb-4 line-clamp-2">
                          The therapy encourages acceptance of difficult
                          emotions while also promoting change through practical
                          skills and strategies.
                        </p>
                        <a
                          href="/dbt"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab === "Cognitive Behavior Therapy (CBT)" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          Cognitive Behavior Therapy (CBT)
                        </h3>
                        <p className="mb-4 line-clamp-7">
                          Cognitive Behavioral Therapy (CBT) is a structured,
                          goal-oriented therapeutic approach that helps
                          individuals identify and change negative thought
                          patterns. and behaviors that contribute to emotional
                          distress...
                        </p>

                        <a
                          href="/cbt"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                    {activeTab ===
                      "Eye Movement Desensitization and Reprocessing (EMDR)" && (
                      <div>
                        <h3 className="text-lg font-bold   mb-4">
                          Eye Movement Desensitization and Reprocessing (EMDR)
                        </h3>
                        <p className="mb-2">
                          Eye Movement Desensitization and Reprocessing (EMDR)
                          is a psychotherapy technique designed to help
                          individuals process and heal from traumatic memories
                          and experiences.
                        </p>
                        <p className="mb-4 line-clamp-1">
                          This process helps to integrate the traumatic
                          memories, reducing their emotional charge.
                        </p>
                        <a
                          href="/emdr"
                          className="font-semibold hover:text-[#2f902f] duration-300 ease-in-out scale-105 hover:font-semibold underline-offset-2 hover:underline-offset-4 underline"
                        >
                          Read more →
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <a
                  href="/services"
                  className="inline-block border-2 border-[#447458] font-semibold  text-[#447458] text-sm uppercase tracking-widest py-3 px-8 mt-8 hover:bg-#3d6b50] hover:text-white hover:bg-[#447458] transition-colors duration-200 ease-in-out"
                  aria-label="Learn more about our services"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </section>

        <VideoSlider />

        <section>
          <div className="max-w-xl mx-auto text-center md:py-6 mt-20">
            <div className="w-24 h-2 bg-[#5D9676] mb-4 mx-auto"></div>
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
              Meet Our Team
            </h2>
            <h2 className="font-display font-bold text-xl md:text-2xl text-gray-600 mb-5 mt-2 px-4">
              Best Mental Health psychologist in Multan
            </h2>
          </div>
          <div className="w-full flex flex-wrap justify-center px-4">
            {/* Team Member 1 */}
            <div className="relative group w-80 h-[400px] mx-6 mb-8">
              {/* Image */}
              <img
                alt="Habiba Nazim Khan Psychologist"
                src={imgx}
                className="w-full h-full object-cover rounded-lg shadow-lg transition-all duration-300"
                loading="lazy"
              />
              {/* Information Overlay */}
              <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/100 to-transparent p-5 rounded-b-lg  transition-opacity duration-300">
                <h3 className="text-white text-lg text-center font-bold mb-2">
                  Habiba Nazim Khan
                </h3>
                <p className="text-gray-100 text-sm uppercase font-semibold text-center">
                  Consultant Clinical Psychologist <br />
                  Speech Language Pathologist <br />
                  DBT Practitioner
                </p>
                <div className="mt-4 flex justify-center space-x-4">
                  <a
                    href="https://www.instagram.com/habibanazimkhan/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/habiba-nazim-khan-6a42188b"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61561362483687"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaFacebook />
                  </a>
                </div>
              </div>
            </div>
            {/* Team Member 2 */}
            <div className="relative group w-80 h-[400px] mx-6 mb-8">
              {/* Image */}
              <img
                alt="Psychologist and therapist near me"
                src={imgy}
                className="w-full h-full object-cover rounded-lg shadow-lg transition-all duration-300"
                loading="lazy"
              />
              {/* Information Overlay */}
              <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent p-5 rounded-b-lg transition-opacity duration-300">
                <h3 className="text-white text-lg text-center font-bold mb-2">
                  Zarnab Kamal
                </h3>
                <p className="text-gray-100 text-sm uppercase font-semibold text-center">
                  Assistant Clinical Psychologist <br />
                  Mindfulness Trainer <br />
                  DBT Practitioner
                </p>
                <div className="mt-4 flex justify-center space-x-4">
                  <a
                    href="https://www.instagram.com/syedaz.k?igsh=bzNlY3N6MXE4MGFt"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/zarnab-kamal-a1208631a/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    href="https://www.facebook.com/zarnab.kamal.5?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                    className="text-white text-2xl hover:text-[#A7CCB7] transition-colors p-2"
                  >
                    <FaFacebook />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <BlogSectionWithCarousel />

        <section>
          <div className="container mx-auto px-4 md:px-8 lg:px-32 py-12">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold mb-10 px-2">
              Do you have any questions?
            </h2>
            <div className="space-y-2">
              {questions.map((question, index) => (
                <div
                  key={index}
                  className=" border-b-2 border-gray-200 rounded-lg p-4"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h3 className="text-lg font-medium">{question.question}</h3>{" "}
                     
                    <span className="text-gray-500">
                      {activeIndex === index ? "▲" : "▼"}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <p className="mt-2 text-gray-700">{question.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        <PricingPlans />
        <InfoSection />
        <WhatsAppButton />
        <UpcomingEventsBar />
      </main>
      <Footer />
    </>
  );
}
