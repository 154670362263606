import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import img from "../assets/logoo-2.webp";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="relative bg-[#e0e7e3] pt-8 pb-6 z-0 ">
      <div className="mx-auto px-2 md:px-6">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="mb-2">
              <img
                src={img}
                alt="Mindful Connection Hub Logo"
                className="h-12 w-52 inline-block"
                width="128"
                height="48"
              />
            </div>
            <h3 className="text-base md:text-lg mt-1 mb-2 text-stone-700">
              Our vision at MCH is to create a compassionate world. We strive to
              make mental health services accessible and transformative, helping
              people everywhere lead balanced, fulfilling lives with hope and
              dignity.
            </h3>
            <div
              className="mt-6 flex space-x-3"
              role="navigation"
              aria-label="Social Media Links"
            >
              <a
                href="https://www.instagram.com/habibanazimkhan/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-[#a7ccb7] hover:bg-[#a7ccb7] duration-300"
                aria-label="Follow us on Instagram"
                title="Instagram"
              >
                <FaInstagram className="text-3xl text-gray-800" />
              </a>
              <a
                href="https://www.linkedin.com/company/mindful-connection-hub"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-[#a7ccb70] hover:bg-[#a7ccb7] duration-300"
                aria-label="Follow us on LinkedIn"
                title="LinkedIn"
              >
                <FaLinkedin className="text-3xl text-gray-800" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61574094790029"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 flex items-center justify-center rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-[#a7ccb7] hover:bg-[#a7ccb7] duration-300"
                aria-label="Follow us on Facebook"
                title="Facebook"
              >
                <FaFacebook className="text-3xl text-gray-800" />
              </a>
            </div>
          </div>

          <div className="w-full lg:w-6/12 mt-10 sm:mt-0">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-[#2d5742] text-lg font-semibold mb-2">
                  Important Links
                </span>
                <nav aria-label="Important Links">
                  <ul
                    className="list-unstyled"
                    role="navigation"
                    aria-label="Important Links"
                  >
                    <li>
                      <a
                        className="text-stone-800 hover:text-[#396750] font-semibold block pb-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#2d5742]"
                        href="/"
                        aria-label="Home page"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-stone-800 hover:text-[#396750] font-semibold block pb-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#2d5742]"
                        href="/about"
                        aria-label="About us"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-stone-800 hover:text-[#396750] font-semibold block pb-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#2d5742]"
                        href="/Contact-MCH-By-Habiba-Nazim-Khan"
                        aria-label="Contact us"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-stone-800 hover:text-[#396750] texr font-semibold block pb-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#2d5742]"
                        href="/privacypolicy"
                        aria-label="Privacy Policy"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-stone-500 border-x-4" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <h1 className="text-stone-700 text-sm md:text-base ">
              © All Rights Reserved.{" "}
              <a
                href="https://www.arwatechnologies.com"
                className="text-green-700  hover:text-green-800   font-medium"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit Arwa Technologies"
              >
                Arwa Technologies
              </a>{" "}
              {currentYear}
            </h1>
          </div>
        </div>
      </div>
    </footer>
  );
}
