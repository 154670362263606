import React from "react";

const articles = [
  {
    id: 1,
    title:
      "The Rise of ‘Situationships’: Why Social Media Makes It Harder to Define Relationships ",
    date: "Feb 7, 2025",
    author: "Zarnab Kamal",
    excerpt:
      "Ever found yourself texting someone all day, every day, sharing memes, deep conversations...",
    image: require("../../assets/close-up-couple-standing.jpg"),
    link: "/the-rise-of-situationships",
  },

  {
    id: 2,
    title:
      "The TikTok Effect: How Social Media is Reshaping Mental Health in Youth ",
    date: "Feb 6, 2025",
    author: "Zarnab Kamal",
    excerpt:
      "In today’s digital age, social media platforms have become integral to daily life. Among them...",
    image: require("../../assets/young-people-using-reels_23-2150038634.jpg"),
    link: "/the-tiktok-effect",
  },
  {
    id: 3,
    title: "The Science of Happiness: How to Train Your Brain for Positivity",
    date: "Feb 14, 2025",
    author: "Zarnab Kamal",
    excerpt:
      "What if I told you that happiness isn’t just a random feeling but a skill you can train...",
    image: require("../../assets/mental-health-concept-illustration_114360-2589.jpg"),
    link: "/the-science-of-happiness",
  },
  {
    id: 4,
    title:
      "The Power of Micro-Actions: Small Changes That Have a Big Psychological Impact ",
    date: "Feb 4, 2025",
    author: "Zarnab Kamal",
    excerpt:
      "Have you ever set a big goal, felt motivated for a few days, and then completely abandoned it...",
    image: require("../../assets/futuristic-image-womans-profile-with-digital-brain-overlay-showcasing-ai-neuroscience-inno_715671-15861.jpg"),
    link: "/the-power-of-micro-actions",
  },
];

const Sidebar = () => {
  return (
    <div className="sidebar w-full md:w-1/4 p-4 rounded-lg hidden md:block">
      <h3 className="text-2xl font-bold mb-4 text-gray-800">
        Related Articles
      </h3>
      <ul>
        {articles.map((article) => (
          <li key={article.id} className="mb-6">
            <a
              href={article.link}
              className="block bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-[1.05] hover:shadow-2xl duration-300"
            >
              <div className="relative">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-40 object-cover transition-opacity duration-300"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div className="p-4">
                <h4 className="text-lg font-semibold text-gray-900 hover:text-blue-500 transition duration-200">
                  {article.title}
                </h4>
                <p className="text-sm text-gray-600">
                  {article.date} • {article.author}
                </p>
                <p className="text-sm text-gray-700 mt-2">
                  {article.excerpt.substring(0, 60)}...
                </p>
                <span className="inline-block mt-2 text-[#3c9140] font-medium hover:underline transition-all duration-200">
                  Read More →
                </span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
