import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function WhatsAppButton() {
  return (
    <>
      <a
        href="https://wa.me/923040186444"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-24 right-6 bg-green-500 p-3 rounded-full shadow-lg hover:bg-green-600 transition z-20 focus:outline-none focus:ring-2 focus:ring-green-300"
        aria-label="Chat with us on WhatsApp"
        title="Chat with us on WhatsApp"
      >
        <FaWhatsapp className="text-white text-4xl" aria-hidden="true" />
      </a>
    </>
  );
}

export default WhatsAppButton;
