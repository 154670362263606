// SocialMediaLinks.js
import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram, // Replacing FaTwitter with FaInstagram
  FaLinkedin,
  FaWhatsapp,
  FaLink,
} from "react-icons/fa";

function SocialMediaLinks({ url, title, description }) {
  const [shareUrl, setShareUrl] = useState("");
  const [shareTitle, setShareTitle] = useState("");
  const [shareDescription, setShareDescription] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setShareUrl(url || window.location.href);
      setShareTitle(title || document.title);
      setShareDescription(description || "Check out this amazing blog post!");
    }
  }, [url, title, description]);

  const socialLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`,

    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      shareUrl
    )}&title=${encodeURIComponent(shareTitle)}&summary=${encodeURIComponent(
      shareDescription
    )}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${shareTitle} - ${shareDescription} ${shareUrl}`
    )}`,
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <div
      className="flex items-center gap-4 mt-8 px-4 sm:px-8 md:px-16 lg:px-32"
      aria-label="Social Media Share Links"
    >
      <span className="text-gray-600" id="share-label">
        Share:
      </span>

      {/* Facebook */}
      <a
        href={socialLinks.facebook}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800 transition-colors"
        aria-label="Share on Facebook"
        title="Share on Facebook"
      >
        <FaFacebook className="w-6 h-6" aria-hidden="true" />
      </a>

      {/* LinkedIn */}
      <a
        href={socialLinks.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-700 hover:text-blue-900 transition-colors"
        aria-label="Share on LinkedIn"
        title="Share on LinkedIn"
      >
        <FaLinkedin className="w-6 h-6" aria-hidden="true" />
      </a>

      {/* WhatsApp */}
      <a
        href={socialLinks.whatsapp}
        target="_blank"
        rel="noopener noreferrer"
        className="text-green-500 hover:text-green-700 transition-colors"
        aria-label="Share on WhatsApp"
        title="Share on WhatsApp"
      >
        <FaWhatsapp className="w-6 h-6" aria-hidden="true" />
      </a>

      {/* Copy Link Button */}
      <button
        onClick={copyToClipboard}
        className="text-gray-600 hover:text-gray-800 transition-colors"
        aria-label="Copy link to clipboard"
        title="Copy link"
      >
        <FaLink className="w-6 h-6" aria-hidden="true" />
      </button>
    </div>
  );
}

export default SocialMediaLinks;
