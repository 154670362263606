import React from 'react'
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GalleryModal({
    clickedImg,
    setClickedImg,
    handelRotationRight,
    handelRotationLeft,
    currentIndex,
    length,
  }) {
    const handleClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
          setClickedImg(null);
        }
      };

  return (
    <>
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center dismiss"
      onClick={handleClick}
    >
      <div className="relative">
        <img
          src={clickedImg}
          alt="bigger pic"
          width={600}
          height={500}
          className="rounded-lg"
        />
        <p className="absolute bottom-4 left-4 text-white text-lg">
          {currentIndex + 1} / {length}
        </p>
      </div>
      <span
        className="absolute top-4 right-4 text-white text-2xl cursor-pointer dismiss"
        onClick={handleClick}
      >
        X
      </span>
      <div
        onClick={handelRotationLeft}
        className="absolute left-8 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
      >
        <FontAwesomeIcon icon={faCaretLeft} size="2xl" />
      </div>
      <div
        onClick={handelRotationRight}
        className="absolute right-8 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
      >
        <FontAwesomeIcon icon={faCaretRight} size="2xl" />
      </div>
    </div>
    </>
  )
}

export default GalleryModal