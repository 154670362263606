import React from "react";
import Header from "./Comp/Header";
import TopNav from "./TopNav";
import Footer from "./Footer";
import img0 from "../assets/flowers-crop-4.webp";
import img1 from "../assets/psychology-therapy.jpg";
import img2 from "../assets/couple-wearing-masks-3.jpg";
import img3 from "../assets/vector-collection.jpg";
import img4 from "../assets/pngtree3.png";
import img5 from "../assets/business-illustrations.png";
import img7 from "../assets/featured.jpg";
import img8 from "../assets/business-people.jpg";
import img9 from "../assets/ethical-dilemma.png";
import img10 from "../assets/flat-mental-health.png";
import img13 from "../assets/meditation-work.jpg";
import img14 from "../assets/cognitive-behavioral-therapy.webp";
import img15 from "../assets/drawings_Kensington_11_EMDR-big-1.png";
import InfoSection from "./Comp/InfoSection";
import WhatsAppButton from "./Comp/WhatsAppButton";
import { Helmet } from "react-helmet-async";
function Services() {
  const cardsData = [
    {
      id: 1,
      imgSrc: img1,
      title: "Psychotherapy",
      description:
        "Psychotherapy Personalized therapy to address mental health issues.",
      url: "/psychotherapy",
    },
    {
      id: 2,
      imgSrc: img8,
      title: "Stress Management",
      description:
        "Techniques to reduce stress, improve well-being, and enhance mental resilience.",
      url: "/stresstherapymultan",
    },
    {
      id: 3,
      imgSrc: img2,
      title: "Couple Therapy",
      description:
        "Helping couples improve communication and resolve conflicts.",
      url: "/coupletherapy",
    },
    {
      id: 4,
      imgSrc: img3,
      title: "Interpersonal Therapy",
      description: "Enhancing social functioning and relationships.",
      url: "/interpersonal",
    },
    {
      id: 5,
      imgSrc: img4,
      title: "Counseling",
      description:
        "Providing guidance and support for various life challenges.",
      url: "/counseling",
    },
    {
      id: 6,
      imgSrc: img5,
      title: "Career Counseling",
      description: "Assisting with career decisions and transitions.",
      url: "/career",
    },
    // {
    //   id: 7,
    //   imgSrc: img6,
    //   title: "Speech Therapy",
    //   description: "Addressing speech and communication issues.",
    //   url: "/speechtherapy",
    // },
    {
      id: 8,
      imgSrc: img7,
      title: "Dialectical Behavior Therapy (DBT)",
      description:
        "Managing emotions and improving interpersonal effectiveness.",
      url: "/dbt",
    },

    {
      id: 9,
      imgSrc: img9,
      title: "Anger Management",
      description: "Strategies to control and express anger healthily.",
      url: "/angermanage",
    },
    {
      id: 10,
      imgSrc: img10,
      title: "Disorder Treatments",
      description:
        "Specialized treatment plans for various mental health disorders.",
      url: "/disorder",
    },
    // {
    //   id: 11,
    //   imgSrc: img11,
    //   title: "Speech Issues",
    //   description: "Therapy to improve speech and language skills.",
    //   url: "/speechissues",
    // },
    // {
    //   id: 12,
    //   imgSrc: img12,
    //   title: "Special Needs Issues",
    //   description:
    //     "Providing Individual Care and Support to nurture the Potential of each Child.",
    //   url: "/specialneeds",
    // },
    {
      id: 13,
      imgSrc: img13,
      title: "Relaxation Techniques",
      description:
        "Providing techniques for your path to inner calm and resilience.",
      url: "/relaxationtechniques",
    },
    {
      id: 14,
      imgSrc: img14,
      title: "Cognitive Behavior Therapy (CBT)",
      description:
        "Helps individuals identify and change negative thought patterns and behaviors.",
      url: "/cbt",
    },
    {
      id: 15,
      imgSrc: img15,
      title: "Eye Movement Desensitization and Reprocessing (EMDR)",
      description:
        "Helps individuals alleviate distress from traumatic memories through guided eye movements.",
      url: "/emdr",
    },
  ];

  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.mindfulch.com/#website",
        url: "https://www.mindfulch.com/",
        name: "Mindful Connection Hub",
        publisher: {
          "@id": "https://www.mindfulch.com/#organization",
        },
      },
      {
        "@type": "WebPage",
        "@id": "https://www.mindfulch.com/services#webpage",
        url: "https://www.mindfulch.com/services",
        name: "Our Services",
        isPartOf: {
          "@id": "https://www.mindfulch.com/#website",
        },
        breadcrumb: {
          "@id": "https://www.mindfulch.com/services#breadcrumb",
        },
        inLanguage: ["en", "ur"],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.mindfulch.com/services#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.mindfulch.com/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Services",
            item: "https://www.mindfulch.com/services",
          },
        ],
      },
      {
        "@type": "Organization",
        "@id": "https://www.mindfulch.com/#organization",
        name: "Mindful Connection Hub",
        url: "https://www.mindfulch.com/",
        logo: "https://www.mindfulch.com/static/media/Logoo.1797d109abd253ceea64.webp",
        sameAs: [
          "https://www.facebook.com/profile.php?id=61574094790029",
          "https://www.linkedin.com/company/mindful-connection-hub",
          "https://www.instagram.com/habibanazimkhan/",
          "https://www.tiktok.com/@habibanazimkhan1",
          "https://www.youtube.com/@HabibaNazimKhan",
        ],
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "+92 3040186444",
          contactType: "customer support",
          areaServed: "Worldwide",
          availableLanguage: ["English", "Urdu"],
        },
      },
      {
        "@type": "ItemList",
        "@id": "https://www.mindfulch.com/services#services-list",
        name: "Services",
        itemListElement: [
          {
            "@type": "Service",
            name: "Stress Therapy Multan",
            url: "https://www.mindfulch.com/stresstherapymultan",
            description:
              "Experience expert Stress Therapy Multan to overcome anxiety and regain peace of mind. Our proven techniques help you relax and refocus. Book your session today!",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },

          {
            "@type": "Service",
            name: "Psychotherapy",
            url: "https://www.mindfulch.com/psychotherapy",
            description:
              "Professional psychotherapy services to support mental well-being and personal growth.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Cognitive Behavioral Therapy (CBT)",
            url: "https://www.mindfulch.com/cbt",
            description:
              "CBT therapy sessions to help manage thoughts, emotions, and behaviors effectively.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Couple Therapy",
            url: "https://www.mindfulch.com/coupletherapy",
            description:
              "Supportive therapy for partners to improve communication, resolve conflicts, and strengthen relationships.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Interpersonal Therapy",
            url: "https://www.mindfulch.com/interpersonal",
            description:
              "Guidance to help resolve conflicts, manage transitions, and navigate social challenges.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Counseling",
            url: "https://www.mindfulch.com/counseling",
            description:
              "Expert counseling for stress, anxiety, relationships, and personal growth.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Career Counseling",
            url: "https://www.mindfulch.com/career",
            description:
              "Professional career guidance for students, graduates, and professionals.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Dialectical Behavior Therapy (DBT)",
            url: "https://www.mindfulch.com/dbt",
            description:
              "DBT therapy for emotional regulation, distress tolerance, and mindfulness.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Anger Management",
            url: "https://www.mindfulch.com/angermanage",
            description:
              "Therapy to help control emotions, reduce stress, and improve relationships.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Psychological Disorders",
            url: "https://www.mindfulch.com/disorder",
            description:
              "Comprehensive therapy for psychological disorders and mental health challenges.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "Relaxation Techniques",
            url: "https://www.mindfulch.com/relaxationtechniques",
            description:
              "Guided relaxation techniques to reduce stress and enhance well-being.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
          {
            "@type": "Service",
            name: "EMDR (Eye Movement Desensitization and Reprocessing)",
            url: "https://www.mindfulch.com/emdr",
            description:
              "EMDR therapy for trauma, PTSD, and emotional healing.",
            provider: {
              "@id": "https://www.mindfulch.com/#organization",
            },
          },
        ],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.mindfulch.com/services" />

        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <TopNav />
      <main>
        <Header leftTitle="Our Services" breadcrumbTitle="Services" />
        <section>
          <div className="flex items-center justify-center space-x-4 relative ">
            {/* Image on the left, fixed in place */}
            <div className="absolute inset-0 -z-10">
              <img
                src={img0}
                alt="Background"
                className="w-auto h-auto object-cover opacity-40 lg:opacity-50 "
              />
            </div>

            {/* Text in the center */}
            <div className="relative z-10 text-center w-full max-w-2xl px-8 lg:py-4 ">
              <h2 className="mt-20  text-2xl lg:text-3xl py-1 text-transparent bg-clip-text bg-gradient-to-r from-[#68d468] to-[#5aa37b] font-sans font-semibold rounded-lg mb-2">
                Therapies & Treatments
              </h2>
              <p className="text-base lg:text-lg font-semibold text-gray-600 px-4 ">
                Explore Our Comprehensive Therapy Services for Every Need
              </p>
            </div>
          </div>
        </section>

        <section>
          <div
            id="expComp"
            className="py-8 px-6 sm:px-8 md:px-16 lg:px-28 2xl:px-36 mt-20 md:mt-10 lg:mt-5"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:gap-20 py-5">
              {cardsData.map((card) => (
                <a
                  key={card.id}
                  href={card.url}
                  className="flex flex-col h-full shadow-2xl hover:shadow-lg hover:shadow-[#e0ebe4] p-8 items-center justify-center self-stretch rounded-2xl bg-[#d6eadc] transform transition duration-500 hover:scale-105 ease-in-out  relative"
                >
                  {/* Outer div for border around the image */}
                  <div className="w-28 h-28 rounded-full border border-[#90ab99] flex items-center justify-center -mt-20 mb-4">
                    <div className="w-24 h-24 rounded-full overflow-hidden shadow-[#ffffff] shadow-[0_0_25px_18px_#def47b]">
                      <img
                        src={card.imgSrc}
                        className="w-full h-full object-cover"
                        alt={card.title}
                      />
                    </div>
                  </div>
                  <p className="text-gray-400 text-xl font-bold pt-3 text-center">
                    {card.number}
                  </p>
                  <p className="font-semibold text-lg text-stone-800 pt-3 text-center">
                    {card.title}
                  </p>
                  <p className="text-base text-stone-600 pt-3 text-center">
                    {card.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>

        <InfoSection />
        <WhatsAppButton />
      </main>
      <Footer />
    </>
  );
}

export default Services;
