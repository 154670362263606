import { motion } from "framer-motion";
import { Users, Clock, Shield, Brain, Laptop2 } from "lucide-react";
import Img1 from "../assets/Stress Therapy Multan.webp";

const features = [
  {
    icon: <Users className="w-10 h-10 text-[#6eb68e]" />,
    title: "100+ Clients Served Successfully",
    description:
      "Our proven methods have already helped dozens of Multan residents regain peace of mind. Now it’s your turn.",
  },
  {
    icon: <Clock className="w-10 h-10 text-[#6eb68e]" />,
    title: "Complete Solution in One 45-Minute Session",
    description:
      "We assess your concerns, provide actionable strategies, and guide you in one session.",
  },
  {
    icon: <Shield className="w-10 h-10 text-[#6eb68e]" />,
    title: "100% Private & Confidential Therapy",
    description:
      "Your personal struggles are safe with us. No judgments, no pressure—just compassionate guidance.",
  },

  {
    icon: <Laptop2 className="w-10 h-10 text-[#6eb68e]" />,
    title: (
      <>
        <a
          href="https://www.marham.pk/doctors/multan/clinical-psychologist/dr-habiba-nazim"
          className="underline text-blue-400 hover:text-blue-600 hover:underline-offset-2 transition duration-300"
          target="_blank"
          rel="noopener noreferrer"
        >
          Online
        </a>{" "}
        & In-Person Sessions Available
      </>
    ),
    description:
      "Choose what suits you best—from the comfort of your home or at our secure psychology counseling location in Model Town Multan.",
  },
];

const WhyChooseUs = () => {
  return (
    <section className="relative bg-cover bg-center py-20" style={{ backgroundImage: `url('${Img1}')` }}>
      {/* Hidden Image for Accessibility */}
      <img src={Img1} alt="Stress Therapy Multan" className="hidden" aria-hidden="true" />
      
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>

      {/* Content */}
      <div className="relative z-10 text-center">
        <h2 className="text-4xl font-bold text-white">
          Why{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
            Choose Us?
          </span>
        </h2>

        {/* Cards Grid */}
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 max-w-5xl mx-auto px-6 lg:px-4">
          {features.map((item, index) => (
            <motion.div
              key={index}
              className="p-6 bg-white bg-opacity-10 rounded-lg shadow-md backdrop-blur-lg transition-all duration-500 ease-in-out flex flex-col items-center text-center"
              initial={{ scale: 1.2, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              viewport={{ once: true, amount: 0.2 }}
            >
              {/* Icon */}
              <div className="mb-3">{item.icon}</div>
              
              {/* Title */}
              <h3 className="text-xl font-semibold text-white">{item.title}</h3>
              
              {/* Description */}
              <p className="mt-3 text-gray-200">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
