import { useState, useEffect } from "react";

// Icons for Events
const eventIcons = {
  "Children's Mental Health Awareness Week": "👶🧠",
  "Eating Disorder Awareness Day": "🍽️",
  "International Day of Happiness": "😊🌍",
  "World Bipolar Day": "🔄🧠",
  "National Mental Health Awareness Month": "🧠📅",
  "International PTSD Awareness Day": "💥🧠",
  "World Schizophrenia Day": "🧑‍⚕️🧠",
  "World Suicide Prevention Day": "💔🚫",
  "World Mental Illness Awareness Week": "🧠🔍",
  "World Mental Health Day": "🌍🧠",
};

function UpcomingEventsBar() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(true); // Always show the popup

  useEffect(() => {
    const events = [
      {
        id: 1,
        date: "February 3",
        description: "Children's Mental Health Awareness Week",
        link: "/child-awareness",
      },
      {
        id: 2,
        date: "February 24",
        description: "Eating Disorder Awareness Day",
        link: "/eating-disorders-awareness",
      },
      {
        id: 3,
        date: "March 20",
        description: "International Day of Happiness",
        link: "/happiness-day-awareness",
      },
      {
        id: 4,
        date: "March 30",
        description: "World Bipolar Day",
        link: "/bipolar-awareness",
      },
      {
        id: 5,
        date: "May 1",
        description: "National Mental Health Awareness Month",
        link: "/national-mental-health-awareness",
      },
      {
        id: 6,
        date: "June 27",
        description: "International PTSD Awareness Day",
        link: "/ptsd-awareness",
      },
      {
        id: 7,
        date: "July 25",
        description: "World Schizophrenia Day",
        link: "/schizophrenia-awareness",
      },
      {
        id: 8,
        date: "September 10",
        description: "World Suicide Prevention Day",
        link: "/world-suicide-awareness",
      },
      {
        id: 9,
        date: "October 5",
        description: "World Mental Illness Awareness Week",
        link: "/mental-illness-awareness",
      },
      {
        id: 11,
        date: "October 10",
        description: "World Mental Health Day",
        link: "/mental-health-day",
      },
    ];

    // Get current date
    const today = new Date();

    // Function to parse event date with year, assuming current year for the event
    const parseEventDate = (eventDateStr) => {
      const currentYear = today.getFullYear();
      return new Date(`${eventDateStr} ${currentYear}`);
    };

    // Filter events that are in the future
    const futureEvents = events.filter((event) => {
      const eventDate = parseEventDate(event.date);
      return eventDate >= today;
    });

    // Sort events by date
    futureEvents.sort((a, b) => {
      const dateA = parseEventDate(a.date);
      const dateB = parseEventDate(b.date);
      return dateA - dateB;
    });

    // Set the closest upcoming event(s)
    if (futureEvents.length > 0) {
      setUpcomingEvents([futureEvents[0]]); // Show only the next event
    } else {
      setUpcomingEvents([]); // No upcoming events
    }
  }, []);

  return (
    <>
      {showPopup && upcomingEvents.length > 0 && (
        <div
          role="dialog"
          aria-labelledby="popup-title"
          aria-modal="true"
          className="fixed bottom-4 left-4 z-50 p-4  w-60 sm:w-72  min-h-[100px] text-gray-900 shadow-lg bg-gradient-to-t from-[#80ab91] to-[#c2e4d2] rounded-lg transition-opacity duration-500 ease-in-out animate-fadeIn 
      before:absolute before:-top-3 before:left-6 before:w-10 before:h-10 before:bg-[#b587d3] before:rounded-full before:shadow-lg 
            after:absolute after:-top-4 after:left-16 after:w-12 after:h-12 after:bg-[#8455a4] after:rounded-full after:shadow-lg"
          style={{
            clipPath: "ellipse(90% 140% at 40% 100%)",
            borderRadius: "20px",
            boxShadow: "0px 4px 8px rgba(0.3, 0.2, 0.1, 0.4)",
          }}
        >
          {/* Close Button */}
          <button
            onClick={() => setShowPopup(false)}
            className="absolute top-2 right-3 text-gray-700 text-lg sm:text-xl font-bold hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-md"
            aria-label="Close upcoming events popup"
          >
            &times;
          </button>

          {/* Title */}
          <h2
            id="popup-title"
            className="text-sm sm:text-lg font-medium text-[#522c61] z-10 text-center relative mt-4 sm:mt-6 font-serif"
          >
            Upcoming Events
          </h2>

          {/* Events List */}
          <div className="mt-2 text-xs sm:text-sm text-gray-800 px-2 sm:px-3">
            <ul className="space-y-2">
              {upcomingEvents.map((event) => (
                <li key={event.id} className="flex flex-col items-center">
                  <span className="text-base sm:text-lg">
                    {eventIcons[event.description]}
                  </span>
                  <div className="text-center">
                    <strong className="text-green-900 text-xs sm:text-sm font-serif">
                      {event.date}
                    </strong>
                    <p className="text-green-900 text-xs sm:text-base font-serif">
                      {event.description}
                    </p>
                  </div>
                  {/* Know More Link */}
                  {/* <div className="mt-1 text-xs sm:text-sm text-gray-700">
                    <a
                      href={event.link}
                      className="underline hover:text-green-900 hover:underline-offset-2 focus:outline-none focus:ring-2 focus:ring-green-700 rounded-md hover:font-semibold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Know More
                    </a>
                  </div> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default UpcomingEventsBar;
