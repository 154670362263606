import React from "react";
import { Carousel } from "flowbite-react";
import img1 from "../../assets/collage-people-using-reels_23-2149416504.jpg";
import img2 from "../../assets/close-up-couple-standing.jpg";
import img3 from "../../assets/young-people-using-reels_23-2150038634.jpg";
import img4 from "../../assets/futuristic-image-womans-profile-with-digital-brain-overlay-showcasing-ai-neuroscience-inno_715671-15861.jpg";
import img5 from "../../assets/concept-person-suffering-from-cybersickness.jpg";
import img6 from "../../assets/woman-mask-stands-by-window_1157-31619.jpg";
import img7 from "../../assets/technology-concept-with-young-kids_23-2147782136.jpg";
import img8 from "../../assets/mobile_677411-2575.jpg";

function BlogSectionWithCarousel() {
  const slides = [
    {
      title:
        "The Rise of Social Media Influencers: How Gen Z is Redefining Fame and Success",
      link: "/rise-of-social-media-influencers",
      image: img1,
    },
    {
      title:
        "The Rise of ‘Situationships’: Why Social Media Makes It Harder to Define Relationships",
      link: "/the-rise-of-situationships",
      image: img2,
    },
    {
      title:
        "The TikTok Effect: How Social Media is Reshaping Mental Health in Youth",
      link: "/the-tiktok-effect",
      image: img3,
    },
    {
      title:
        "The Power of Micro-Actions: Small Changes That Have a Big Psychological Impact",
      link: "/the-power-of-micro-actions",
      image: img4,
    },
    {
      title:
        "Doomscrolling & Mental Health: Why We Can’t Stop and How It’s Messing with Us",
      link: "/doomscrolling-and-mental-health",
      image: img5,
    },
    {
      title: "The Silent Epidemic: Why Anxiety is the New Normal",
      link: "/the-silent-epidemic",
      image: img6,
    },

    {
      title:
        "Gen Alpha and Emotional Intelligence: More Empathetic or Losing Touch?",
      link: "/gen-alpha-and-emotional-intelligence",
      image: img7,
    },
    {
      title:
        "Virtual Autism: Understanding the Impact of Screen Time on Child Development",
      link: "/virtual-autism",
      image: img8,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row  py-8 md:py-20">
      {/* Blogs Info Section (First on larger screens) */}
      <div className="md:order-1 bg-gradient-to-t from-[#d0f3d0] to-[#e4f5ec]  px-8 sm:px-12 md:px-16 lg:px-24 flex justify-center items-center w-full md:w-1/2 py-8 ">
        
        <div className="max-w-xl">
          <div className="w-24 h-2 bg-[#5d9676] mb-4 mt-4 md:mt-0"></div>
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] ">
                      Blogs & Articles
                    </h2>
          <p className="md:text-lg mb-6 mt-4 text-sm sm:text-base leading-relaxed text-stone-700 font-medium">
            We provide a variety of resources to support your mental health
            journey, including articles, videos, and self-help tools....
          </p>
          <a
            href="/blogs"
            className="inline-block border-2 border-[#447458] font-semibold  text-[#447458] text-sm uppercase tracking-widest py-3 px-8 mt-8 hover:bg-#3d6b50] hover:text-white hover:bg-[#447458] transition-colors duration-200 ease-in-out"
            aria-label="Explore our latest blog posts"
          >
            Read more
          </a>
        </div>
      </div>

      {/* Carousel Section (Second on larger screens) */}
      <div className="md:order-2 bg-white flex justify-center items-center w-full md:w-1/2 relative no-rounded">
        <Carousel
          slideInterval={2000}
          pauseOnHover
          className="w-full h-[350px] sm:h-[400px] md:h-[450px]"
          rightControl={
            <button
              className="absolute bottom-4 -right-1 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold text-4xl hover:bg-white/20 transition-colors"
              aria-label="Previous Slide"
            >
              ‹
            </button>
          }
          leftControl={
            <button
              className="absolute bottom-4 -right-8 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold text-4xl hover:bg-white/20 transition-colors"
              aria-label="Next Slide"
            >
              ›
            </button>
          }
        >
          {slides.map(({ title, link, image }, index) => (
            <div key={index} className="relative w-full h-full">
              <a
                href={link}
                className="block w-full h-full"
                aria-label={`Go to ${title}`}
              >
                <div className="w-full h-full overflow-hidden relative">
                  <img
                    src={image}
                    alt={title}
                    className="h-full w-full object-cover"
                    loading="lazy"
                  />
                  {/* Overlay with title and button */}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center px-10">
                    <h1
                      className="text-[#ffffff] font-medium text-sm sm:text-lg md:text-xl leading-relaxed mb-4"
                      aria-label={title}
                    >
                      {title}
                    </h1>
                    <a
                      href={link}
                      className="bg-[#61987a] text-white font-semibold py-2 px-4 hover:bg-[#4d7a64] transition duration-300 text-sm sm:text-base"
                      aria-label={`Learn more about ${title}`}
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default BlogSectionWithCarousel;
