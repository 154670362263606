import React from "react";

function Form() {
  return (
    <>
    <div className="flex flex-col xl:w-3/4 py-14 px-4 md:px-8 lg:px-16 xl:px-32 mb-10 mx-auto">
  <div className="flex bg-slate-100 rounded-xl">
    <form className="mt-10 space-y-4 px-4 md:px-10 mb-14 w-full" aria-labelledby="comment-heading">
      
      {/* Form Heading */}
      <h1 id="comment-heading" className="text-3xl text-dark font-semibold py-6 text-left">
        Submit a Comment
      </h1>

      {/* Instructions */}
      <p id="comment-desc" className="text-lg text-dark font-normal py-6 text-left">
        Your email address will not be published. Required fields are marked *
      </p>

      {/* Comment Textarea */}
      <label htmlFor="comment" className="sr-only">Comment (required)</label>
      <textarea
        id="comment"
        rows={4}
        className="w-full p-5 rounded-xl bg-white text-dark border border-gray-300 focus:border-[#61987a] focus:ring-[#61987a]"
        placeholder="Comment*"
        aria-required="true"
        aria-describedby="comment-desc"
      />

      {/* Name Input */}
      <label htmlFor="name" className="sr-only">Name (required)</label>
      <input
        id="name"
        type="text"
        className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark border border-gray-300 focus:border-[#61987a] focus:ring-[#61987a]"
        placeholder="Name*"
        aria-required="true"
      />

      {/* Email Input */}
      <label htmlFor="email" className="sr-only">Email (required)</label>
      <input
        id="email"
        type="email"
        className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark border border-gray-300 focus:border-[#61987a] focus:ring-[#61987a]"
        placeholder="Email*"
        aria-required="true"
      />

      {/* Website Input */}
      <label htmlFor="website" className="sr-only">Website (optional)</label>
      <input
        id="website"
        type="url"
        className="w-full md:w-2/3 rounded-lg p-3 bg-white text-dark border border-gray-300 focus:border-[#61987a] focus:ring-[#61987a]"
        placeholder="Website"
      />

      {/* Save Data Checkbox */}
      <div className="flex py-2 space-x-2">
        <input
          id="save-info"
          type="checkbox"
          className="h-4 w-4 text-gray-600 border-gray-300 rounded focus:ring-gray-500"
          aria-labelledby="save-label"
        />
        <label id="save-label" htmlFor="save-info" className="text-left text-sm text-gray-500">
          Save my name, email, and website in this browser for the next time I comment.
        </label>
      </div>

      {/* Submit Button */}
      <div className="flex flex-row-reverse">
      <button
          id="submit-comment"
          className="bg-[#57866c] text-white active:bg-lime-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-2xl outline-none focus:outline-none mt-10 ease-linear transition-all duration-150 focus:ring focus:ring-[#61987a]"
          type="submit"
          aria-label="Submit your comment"
        >
          Send
        </button>
      </div>

    </form>
  </div>
</div>

    </>
  );
}

export default Form;
