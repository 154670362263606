import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import Img1 from "../../assets/Mental Health psychologist in multan.jpg";
import Img2 from "../../assets/Stress Therapy Multan.webp";

const slides = [
  {
    img: Img1,
    title: "Mental Health Psychologist in Multan ",
    subtitle:
      "Mindful Connection Hub is a dedicated mental health clinic, led by PhD scholar Habiba Nazim Khan. Passionate about improving mental well-being, she is actively working to promote psychology counseling in Multan.",
    buttonText: "Book a Session Now!",
    buttonLink: "/Contact-MCH-By-Habiba-Nazim-Khan",
    ariaLabel: "Mental Health psychologist in multan",
  },
  {
    img: Img2,
    title: "Multan Walo Ab Tension Na Lo!",
    subtitle:
      "Professional Psychology Counseling in Multan for Stress, Anxiety & Depression. Feel lighter, happier, and in control with expert stress therapy.",
    buttonText: "Book a Session Now!",
    buttonLink: "/Contact-MCH-By-Habiba-Nazim-Khan",
    ariaLabel: "Stress Therapy Multan",
  },
];

const HeroSectionMain = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <section className="relative w-full min-h-[650px] flex items-center justify-center overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center transition-opacity duration-1000"
        style={{ backgroundImage: `url('${slides[currentIndex].img}')` }}
        role="img"
        aria-label={slides[currentIndex].ariaLabel}
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50"></div>

      {/* Animated Content */}
      <motion.div
        key={currentIndex}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.8 }}
        className="relative z-10 flex flex-col items-center justify-center w-full max-w-4xl px-8 py-24 text-center mt-24"
      >
        {/* Text Content */}
        <motion.div className="text-white space-y-4">
          <span className="bg-white/20 text-white px-4 py-2 rounded-full text-xs sm:text-sm font-medium uppercase tracking-widest font-[Manrope]">
            Clinical Psychologist
          </span>

          <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tight tracking-wide">
            {slides[currentIndex].title}
          </h1>

          <p className="text-lg sm:text-2xl opacity-100 font-light font-[Manrope] leading-relaxed">
            {slides[currentIndex].subtitle}
          </p>

          {/* Button with proper spacing */}
          <motion.a
            href="/Contact-MCH-By-Habiba-Nazim-Khan"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            id="CTAhero"
            className="inline-block mt-6 px-8 py-3 bg-gradient-to-r from-[#71a371] to-[#61987a] hover:bg-green-600 text-white font-semibold rounded-full shadow-lg transition tracking-wide mx-auto"
          >
            Book a Session Now!
          </motion.a>

          {/* Limited Time Offer */}
          <motion.p
            animate={{ y: [0, -7, 0] }} // Smooth up & down motion
            transition={{ duration: 1.5, ease: "easeInOut", repeat: Infinity }}
            className="mt-3 text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#ff8c00] to-[#ff4500] italic"
          >
            60% OFF! Awareness Initiative Offer 🎉
          </motion.p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default HeroSectionMain;
