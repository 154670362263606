import React from "react";
import imgx from "../assets/Habiba Nazim Khan Psychologist.webp";
import { motion } from "framer-motion";

const PsychologistProfile = () => {
  return (
    <div className="relative w-full h-auto lg:h-[500px] flex items-center justify-center px-2 sm:px-4 md:px-16 py-6 sm:py-10 my-3 sm:my-6">
  {/* Content Section */}
  <div className="relative z-10 flex flex-col md:flex-row items-center gap-4 sm:gap-16 max-w-5xl mx-auto">
    {/* Profile Image */}
    <div className="flex-shrink-0">
      <img
        src={imgx}
        alt="Habiba Nazim Khan Psychologist"
        loading="lazy"
        className="object-contain w-24 h-24 sm:w-40 sm:h-40 md:w-72 md:h-72 rounded-full border-4 border-[#71a371] shadow-lg"
      />
    </div>

    {/* Info Section */}
    <div className="text-center md:text-left">
      {/* Name with Gradient */}
      <h2 className="text-2xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
        Habiba Nazim Khan
      </h2>
      {/* Experience & Scholar Buttons */}
      <div className="flex flex-wrap justify-center md:justify-start gap-2 sm:gap-3 mt-3">
        <button className="px-3 sm:px-6 py-1 sm:py-2 text-white font-semibold rounded-full shadow-lg bg-gradient-to-r from-[#71a371] to-[#61987a] text-xs sm:text-base">
          14+ Years of Experience
        </button>
        <button className="px-3 sm:px-6 py-1 sm:py-2 text-white font-semibold rounded-full shadow-lg bg-gradient-to-r from-[#71a371] to-[#61987a] text-xs sm:text-base">
          PhD Scholar
        </button>
      </div>

      {/* Description */}
      <p className="mt-3 sm:mt-6 text-sm sm:text-lg md:text-xl font-medium text-[#5d815d] leading-relaxed">
        Helping individuals in Multan lead happier lives.
      </p>

      {/* Qualifications */}
      <div className="p-4 sm:p-6 rounded-lg">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
          <div className="flex items-center gap-2">
            <span className="text-blue-500 text-xl sm:text-2xl">🎓</span>
            <p className="text-[#5d815d] text-left text-xs sm:text-base">
              Consultant Clinical Psychologist
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-blue-500 text-xl sm:text-2xl">🗣️</span>
            <p className="text-[#5d815d] text-left text-xs sm:text-base">
              Speech-Language Pathologist
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-green-500 text-xl sm:text-2xl">🏛</span>
            <p className="text-[#5d815d] text-left text-xs sm:text-base">
              Psychologist –{" "}
              <a
                href="https://sed.punjab.gov.pk/"
                className="underline hover:text-blue-600 hover:underline-offset-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Special Education Department, Punjab
              </a>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-purple-500 text-xl sm:text-2xl">🧠</span>
            <p className="text-[#5d815d] text-left text-xs sm:text-base">
              DBT Practitioner & EMDR Specialist
            </p>
          </div>
        </div>
        <hr className="my-3 border-gray-300" />
        <div className="text-center">
          <p className="text-[#5d815d] font-medium text-xs sm:text-base">
            ADCP - MPhil Applied Psychology - PhD Scholar
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default PsychologistProfile;
