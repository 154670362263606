import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/speech-therapy2-600x400.jpg";
import imgy from "../../assets/167-1679675_psychedelic-therapy-negative.png";
import imgz from "../../assets/istockphoto-978274922-612x612.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/cbt_v2-1.webp";
import img2 from "../../assets/CBT+Flowchart-1920w.webp";
import img3 from "../../assets/113d81a5-da0b-48c9-a4cd-358c78cc63e5.webp";
import img4 from "../../assets/Cognitive-Behavioral-Therapy-CBT-for-Teens-800x500.jpg";
import img5 from "../../assets/d366dce4-d262-459b-92e9-c49f7cb0902e.webp";

function Cbt() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Couple Therapy",
      description:
        "Couple therapy, also known as couples counseling or marriage therapy, is a type of psychotherapy ...",
      link: "/coupletherapy",
    },
    {
      imgSrc: imgy,
      title: "Dialectical Behavior Therapy (DBT)",
      description:
        "Dialectical Behavior Therapy (DBT) is a type of cognitive-behavioral therapy developed by...",
      link: "/dbt",
    },
    {
      imgSrc: imgz,
      title: "Speech Issues",
      description:
        "Speech issues, also known as speech disorders, encompass a variety of problems related to the...",
      link: "/speechissues",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Cognitive Behavioral Therapy (CBT)
          </h1>
          <p className="text-base text-black leading-relaxed">
            Cognitive Behavioral Therapy (CBT) is a type of psychotherapy that
            helps individuals identify and change negative thought patterns and
            behaviors that contribute to emotional distress. CBT is structured,
            goal-oriented, and short-term, focusing on addressing current issues
            by changing unhelpful thinking and behavior.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Components of CBT
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Cognitive Restructuring
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Behavioral Activation
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Mindfulness
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Problem-Solving
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Exposure Therapy
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Hearing Impairment
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Speech
            Impairment
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Uses of CBT
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Depression
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Anxiety Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Obsessive-Compulsive Disorder (OCD)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Post-Traumatic Stress Disorder (PTSD)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Phobias
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Stress
            Management
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Sleep
            Disorders
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Benefits of CBT
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Effective and Short-Term
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Empowerment
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Long-Lasting Impact
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Evidence-Based
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Improved Coping
          </p>
        </>
      ),
    },
    {
      img: img5,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            What CBT is Used For
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Depression
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Anxiety Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Obsessive-Compulsive Disorder (OCD)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Post-Traumatic Stress Disorder (PTSD)
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Phobias
          </p>

          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Eating
            Disorders
          </p>

          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Addictions
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Sleep
            Problems
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Anger
            Management
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Cognitive Behavior Therapy"
          breadcrumbTitle="Services"
        />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/relaxationtechniques"
          prevText="Relaxation Techniques"
          nextLink="/emdr"
          nextText="Eye Movement Desensitization and Reprocessing (EMDR)"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default Cbt;
