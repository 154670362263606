import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import img1 from "../../assets/woman-mask-stands-by-window_1157-31619.jpg";
import SocialMediaLinks from "../Comp/SocialMediaLinks";
import WhatsAppButton from "../Comp/WhatsAppButton";
import Sidebar from "../Comp/BlogSideBar";

function SilentEpidemic() {
  const pageTitle = "Welcome to Our Website!";
  const pageDescription = "This is a brief description of the homepage.";
  const pageUrl = window.location.href; // You can dynamically fetch the URL
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="The Silent Epidemic" breadcrumbTitle="Blogs" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16  h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1] h-[300px] md:h-[400px] lg:h-[500px]"
          />
        </div>
        <div className="flex py-10 px-4 sm:px-8 md:px-16 text-gray-700">
          {/* The Silent Epidemic: Why Anxiety is the New Normal */}
          <div className="content w-full md:w-3/4 p-8 ">
            <h2 className="text-2xl font-semibold mb-2 mt-4">
              The Silent Epidemic: Why Anxiety is the New Normal
            </h2>
            <p className="text-base font-normal">
              In today’s fast-paced, hyper-connected world, there’s an invisible
              epidemic quietly spreading—<strong>anxiety</strong>. It doesn’t
              announce itself with flashing lights or visible symptoms. It hides
              behind{" "}
              <strong>
                forced smiles, packed schedules, and the default response we’ve
                all mastered—"I’m fine."
              </strong>
            </p>
            <p className="text-base font-normal">
              But here’s the truth: <strong>most of us aren’t fine."</strong>
            </p>
            <p className="text-base font-normal">
              Anxiety has become the background noise of modern life, woven into
              our routines, relationships, and even our ambitions. It’s the
              silent epidemic that’s shaping how we think, feel, and
              live—whether we realize it or not.
            </p>

            {/*  The Unseen Struggle: What Anxiety Really Looks Like  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              The Unseen Struggle: What Anxiety Really Looks Like
            </h2>
            <p className="text-base font-normal">
              We live in an era where being{" "}
              <strong>"busy" is worn like a badge of honor</strong>.
              Productivity is glorified, while rest is often mistaken for
              laziness. We chase deadlines, juggle responsibilities, and fill
              every spare moment with tasks, believing that slowing down means
              falling behind.
            </p>
            <p className="text-base font-normal">
              But beneath the surface of this hustle culture is a harsh
              reality—chronic stress and anxiety.
            </p>
            <p className="text-base font-normal">Think about it:</p>
            <ul className="list-disc list-inside mt-3 font-bold">
              <li className="text-base text-black leading-relaxed">
                How often do you check your phone without realizing it?
              </li>
              <li className="text-base text-black leading-relaxed">
                When was the last time you truly felt "present" without worrying
                about the next thing on your to-do list?
              </li>
              <li className="text-base text-black leading-relaxed">
                Do you ever feel restless even when you’re supposed to be
                relaxing?
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              These small, everyday behaviors are often fueled by
              anxiety—anxiety we’ve normalized.{" "}
              <strong>
                We call it being "productive," but it’s really just being
                overwhelmed
              </strong>
              .
            </p>
            <p className="text-base font-normal">
              And then there’s social media anxiety. We scroll through perfectly
              curated feeds, comparing our behind-the-scenes to someone else’s
              highlight reel. It’s subtle but powerful. We start feeling like
              we’re not doing enough, not achieving enough, not "being" enough.
            </p>
            <p className="text-base font-normal">
              This constant comparison chips away at our self-esteem, creating a
              cycle of anxiety that feels impossible to escape.
            </p>

            {/*  The Illusion of Control: Why Technology Isn’t Always Our Friend  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              The Illusion of Control: Why Technology Isn’t Always Our Friend
            </h2>
            <p className="text-base font-normal">
              When smartphones first came into our lives, they promised
              convenience and connection. But somewhere along the way, they
              became both our lifeline and our leash.
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Notifications
                </span>{" "}
                Notifications demand our attention like tiny alarms for things
                that often aren’t urgent.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Emails
                </span>{" "}
                Emails expect immediate replies, blurring the lines between work
                hours and personal time.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Apps
                </span>{" "}
                Apps compete for our focus, making us feel like we’re always
                missing out on something.
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              Welcome to the world of <strong>"always-on" anxiety</strong>.
            </p>
            <p className="text-base font-normal">
              Ironically, the technology designed to make life easier has made
              it harder to disconnect. We’re constantly connected, yet
              increasingly isolated. We scroll through endless content, but
              real, meaningful connection often feels out of reach.
            </p>
            <p className="text-base font-normal">
              Anxiety thrives in this environment. The fear of missing out
              (FOMO), the pressure to curate a perfect online persona, and the
              endless stream of information create a mental load that’s
              exhausting to carry.
            </p>

            {/*  The Cultural Stigma: Why We’re Afraid to Talk About It  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              Unilateral vs. Bilateral Hearing Loss: The Balance of Perception
            </h2>
            <p className="text-base font-normal">
              Despite how common anxiety is, it’s still shrouded in stigma.
              We’re taught to be "strong," to "push through," and to avoid
              showing vulnerability. Admitting you’re anxious can feel like
              admitting you’re weak—even though the opposite is true.
            </p>
            <p className="text-base font-normal">Statements like:</p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <strong>"Just relax."</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>"You’re overthinking.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>"Others have it worse."</strong>
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              These dismissive responses don’t help. In fact, they often make
              people feel more isolated. Mental health isn’t a competition. Your
              feelings are valid, even if someone else’s situation looks
              different.
            </p>
            <p className="text-base font-normal">
              The stigma around anxiety keeps people suffering in silence. It’s
              time to change that.
            </p>

            {/*  Finding Balance in the Chaos: Practical Strategies for Coping  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              Finding Balance in the Chaos: Practical Strategies for Coping
            </h2>
            <p className="text-base font-normal">
              While anxiety might feel overwhelming, it’s not undefeatable. The
              first step? Acknowledging it. The next? Taking small, intentional
              steps toward healing.
            </p>

            {/*  1. Digital Detox: Reclaim Your Peace */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              1. Digital Detox: Reclaim Your Peace
            </h2>
            <p className="text-base font-normal">
              Unplug regularly. Designate tech-free zones in your home or
              schedule screen-free hours during your day. Even a 10-minute break
              from your phone can reduce stress levels.
            </p>
            <p className="text-base font-normal">Try this:</p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <strong>Turn off non-essential notifications.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>
                  Resist the urge to scroll first thing in the morning.
                </strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>Go for a walk without your phone.</strong>
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              <strong>Disconnect to reconnect—with yourself.</strong>
            </p>

            {/*  2. Mindfulness Practices: Be Here Now */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              2. Mindfulness Practices: Be Here Now
            </h2>
            <p className="text-base font-normal">
              Mindfulness isn’t just about meditation (though that helps). It’s
              about being present, fully engaged with whatever you’re doing.
            </p>
            <p className="text-base font-normal">Simple practices include:</p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Deep breathing exercises:
                </span>{" "}
                Inhale for four counts, hold for four, exhale for four.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Grounding techniques:
                </span>{" "}
                Notice five things you can see, four you can feel, three you can
                hear, two you can smell, and one you can taste.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Mindful walking:
                </span>{" "}
                Focus on the sensation of your feet touching the ground.
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              These techniques help anchor you when anxiety feels overwhelming.
            </p>

            {/*  3. Set Boundaries: Protect Your Energy */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              3. Set Boundaries: Protect Your Energy
            </h2>
            <p className="text-base font-normal">
              Saying <strong>"no"</strong> is a form of self-care. You don’t
              have to attend every event, reply to every message instantly, or
              take on more than you can handle.
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <strong>Set clear work-life boundaries.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>Communicate your limits without guil</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>
                  Prioritize your well-being, even if it disappoints others.
                </strong>
              </li>
            </ul>

            {/*   4. Seek Support: You’re Not Alone */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              4. Seek Support: You’re Not Alone
            </h2>
            <p className="text-base font-normal">
              Talking helps. Whether it’s a friend, family member, or mental
              health professional, sharing your feelings can be incredibly
              freeing.
            </p>
            <p className="text-base font-normal">Remember:</p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <strong>Therapy isn’t just for "serious" problems.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>Vulnerability is a strength, not a weakness.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>You’re not a burden for needing support.</strong>
              </li>
            </ul>

            {/*    5. Create a Routine: Find Stability */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              5. Create a Routine: Find Stability
            </h2>
            <p className="text-base font-normal">
              Anxiety often thrives in uncertainty. A simple routine can bring
              structure and predictability to your day, reducing feelings of
              overwhelm.
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <strong>Set regular sleep and wake times.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>Schedule breaks and downtime.</strong>
              </li>
              <li className="text-base text-black leading-relaxed">
                <strong>Incorporate activities that bring you joy.</strong>
              </li>
            </ul>

            {/*  A Shared Experience: You Are Not Alonee  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              A Shared Experience: You Are Not Alone
            </h2>
            <p className="text-base font-normal">
              Anxiety doesn’t discriminate. It affects:
            </p>
            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Students
                </span>{" "}
                worried about grades and the future.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Parents
                </span>{" "}
                juggling responsibilities while trying to hold it all together.
              </li>

              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Professionals
                </span>{" "}
                Professionals chasing success but feeling burnt out inside.
              </li>
              <li className="text-base text-black leading-relaxed">
                <span className="text-base mb-2 text-black font-semibold">
                  Even those who "seem fine" on the outside.
                </span>{" "}
              </li>
            </ul>
            <p className="text-base font-normal my-2">
              The common thread? None of us are truly alone in this.
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                So, if you’re feeling anxious—
                <strong>it’s okay</strong>.
              </li>
              <li className="text-base text-black leading-relaxed">
                If you’re overwhelmed—breathe.
                <strong>breathe</strong>.
              </li>
              <li className="text-base text-black leading-relaxed">
                If you’re struggling—reach out.
                <strong>reach out</strong>.
              </li>
            </ul>

            <p className="text-base font-normal my-2">
              Anxiety may be common, but it doesn’t have to be your constant
              companion. The first step to healing is acknowledging that it’s
              there—and realizing that healing is possible.
            </p>
            <p className="text-base font-normal my-2">
              In a world that rarely pauses, give yourself permission to:
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                Take a breath
              </li>
              <li className="text-base text-black leading-relaxed">
                Take a break.
              </li>
              <li className="text-base text-black leading-relaxed">
                Take care of you.
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              <strong>
                Because you deserve peace—not just to survive, but to truly
                live.
              </strong>
            </p>
          </div>
          <Sidebar />
        </div>

        <SocialMediaLinks
          url={pageUrl}
          title={pageTitle}
          description={pageDescription}
        />
        <WhatsAppButton />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default SilentEpidemic;
