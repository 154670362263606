/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { motion } from "framer-motion";
import SingleImage from "../assets/psychology counseling in Multan.webp";

import img0 from "../assets/flowers-crop-4.webp";
const YourFirstPsychotherapy = () => {
  return (
    <section className="relative overflow-hidden">
      <div className="flex flex-col md:flex-row items-center justify-between bg-white px-10 py-16 max-w-7xl mx-auto ">
        <div className="absolute inset-0 z-0">
          <img
            src={img0}
            alt="Background"
            className="w-auto h-80 object-cover opacity-40 lg:opacity-100"
          />
        </div>

        {/* Left Side - Image Grid */}
        <motion.div
          className="w-1/2 z-10 lg:block hidden"
          initial={{ scale: 1 }}
          animate={{ scale: 1.1 }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <img
            src={SingleImage}
            alt="psychology counseling in Multan"
            className="w-full h-full object-cover rounded-lg"
          />
        </motion.div>

        {/* Right Side - Text Content */}
        <div className="lg:w-1/2 mt-10 md:mt-0 md:pl-10 z-20">
          <span className="bg-gradient-to-r from-[#71a371] to-[#61987a]  text-white px-4 py-1 rounded-full text-sm font-semibold">
            Your First Psychotherapy
          </span>
          <h2 className="text-4xl font-bold mt-4">
            What to Expect in  <br />
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
               Psychotherapy Session
            </span>
          </h2>

          <ul className="mt-4 space-y-2">
            <li className="flex items-center text-gray-700">
              <span className="text-green-500 mr-2">✔</span> Relaxed,
              Judgment-Free Discussion
            </li>
            <li className="flex items-center text-gray-700">
              <span className="text-green-500 mr-2">✔</span>Complete
              Understanding of Your Concerns
            </li>
            <li className="flex items-center text-gray-700">
              <span className="text-green-500 mr-2">✔</span> Expert Guidance for
              Stress Relief
            </li>
            <li className="flex items-center text-gray-700">
              <span className="text-green-500 mr-2">✔</span> Personalized
              Recommendations for Long-Term Happiness commodo amet.
            </li>
          </ul>
          <p className="text-gray-700 mt-4 font-medium">
           <span className="font-semibold text-xl text-[#71a371]">We are Confident</span><br />Get full refund if you're not satisfied.
            
          </p>
          {/* Ratings */}
          <div className="flex items-center mt-6">
            <span className="font-bold text-lg">Excellent</span>
            <div className="ml-3 text-yellow-500 flex">★★★★★</div>
            <span className="text-gray-500 ml-2 text-sm">
              Based on <strong>85 reviews</strong>
            </span>
          </div>

          {/* Trustpilot Logo */}
          <div className="mt-3 flex items-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a] italic text-lg font-semibold">
              ★ Trustpilot
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YourFirstPsychotherapy;
