import React, { useState } from "react";
import { motion } from "framer-motion";
import img1 from "../assets/stressed-pakistani-woman.jpg";
import img2 from "../assets/psychotherapy and counselling.webp";
import img3 from "../assets/anxiety treatment for job holders.webp";
import img4 from "../assets/Couple therapy.webp";
import img5 from "../assets/Self Improvement.webp";
import img6 from "../assets/Depression treatment.webp";
import img0 from "../assets/flowers-crop-4.webp";

// Services Data
const services = [
  {
    title: "Persistent Stress & Overthinking",
    description: "Break free from overthinking with guided stress therapy.",
    image: img1,
    alt: "Stress Therapy Multan",
  },
  {
    title: "Feeling Unsupported and Drained",
    description:
      "Talk to a professional Mental Health Psychologist who truly listens—without judgment.",
    image: img2,
    alt: "psychotherapy and counselling",
  },
  {
    title: "Work & Career - Pressure",
    description: "Learn stress management techniques for a balanced work-life.",
    image: img3,
    alt: "anxiety treatment for job holders",
  },
  {
    title: "Relationships & Family Conflicts",
    description:
      "Strengthen your relationships with expert-guided anxiety and depression treatment.",
    image: img4,
    alt: "Couple therapy",
  },
  {
    title: "Fear of Failure & - Self-Doubt",
    description:
      "Build self-confidence and decision-making skills with psychotherapy techniques.",
    image: img5,
    alt: "Self Improvement",
  },
  {
    title: "Struggling with Social Media Addiction",
    description:
      "Break free from endless scrolling. Get guidance from a professional Mental Health Psychologist today.",
    image: img6,
    alt: "Depression treatment",
  },
];


// Main Component
const AreYouStruggling = () => {
  return (
    <section className="py-16 relative overflow-hidden">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img
          src={img0}
          alt="Background"
          className="w-auto h-80 object-cover opacity-40 lg:opacity-100"
        />
      </div>

      {/* Content Wrapper */}
      <div className="relative z-10 max-w-4xl mx-auto text-center">
        <h2 className="text-5xl font-extrabold text-gray-900 mt-4">
          We{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#71a371] to-[#61987a]">
            Help  with...
          </span>
        </h2>
      </div>

      {/* Services Grid */}
      <div className="relative z-10 mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto px-12">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className={`${[1, 4, 5].includes(index) ? "hidden sm:block" : ""}`}
          >
            <ServiceCard service={service} />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

// Service Card Component
const ServiceCard = ({ service }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <motion.div
      className="relative overflow-hidden rounded-2xl shadow-lg cursor-pointer group"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Background Image */}
      <img
        src={service.image}
        alt={service.alt}
        className="w-full h-80 object-cover transition-transform duration-500 group-hover:scale-110"
      />

      {/* Overlay & Description */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: hovered ? 1 : 0 }}
        className="absolute inset-0 bg-black/50 flex items-center justify-center px-6 transition-opacity duration-500"
      >
        <p className="text-white text-lg font-medium text-center">
          {service.description}
        </p>
      </motion.div>

      {/* Title at Bottom with Background */}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: hovered ? 0 : 1 }}
        className="absolute bottom-0 left-0 w-full text-white text-center py-3 transition-opacity duration-500"
      >
        {/* Stylish Background */}
        <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-[#71a371] to-[#61987a] opacity-100 rounded-t-[30%] clip-path-custom"></div>

        {/* Title Text with Line Break */}
        <h3 className="relative text-2xl font-semibold z-10">
          {service.title.split(" - ").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== service.title.split(" & ").length - 1 && <br />}
            </React.Fragment>
          ))}
        </h3>
      </motion.div>
    </motion.div>
  );
};

export default AreYouStruggling;
