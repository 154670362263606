import React, { useState } from "react";
import {
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaRegClock,
  FaTimes,
} from "react-icons/fa";

function InfoSection() {
  const [isMapOpen, setIsMapOpen] = useState(false);

  const openMap = () => {
    setIsMapOpen(true);
  };

  const closeMap = () => {
    setIsMapOpen(false);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row mt-10">
        {/* Working Hours Section */}
        <div
          className="flex flex-col w-full md:w-1/3 bg-[#579c54] hover:bg-[#538c51] p-6 min-h-[200px] justify-center items-center text-center"
          aria-label="Working hours"
        >
          <div className="flex flex-col items-center justify-center text-sm">
            <span className="text-white text-4xl" aria-hidden="true">
              <FaRegClock />
            </span>
            <p className="text-lg font-sans text-white font-medium leading-relaxed mt-4">
              <span aria-label="Working days">Mon - Sat :</span> 11AM - 9PM
              <br />
              <span aria-label="Online session duration">
                Online Session : 45 minutes
              </span>
            </p>
          </div>
        </div>

        {/* Location Section */}
        <div
          className="flex flex-col w-full md:w-1/3 bg-[#477a61] hover:bg-[#456f57] p-6 min-h-[200px] justify-center items-center text-center"
          aria-label="Office location"
        >
          <div className="flex flex-col items-center justify-center text-sm">
            <span className="text-white text-4xl" aria-hidden="true">
              <FaMapMarkerAlt />
            </span>
            <p className="text-lg font-sans text-white font-medium leading-relaxed mt-4">
              <span aria-label="Office address">
                Model Town, Haseeb Street, 42-43 B,
              </span>
              <br />
              <span aria-label="City and country">Multan, Pakistan.</span>
            </p>
            <button
              onClick={openMap}
              aria-label="Open Google Maps for directions"
              className="text-white text-base font-semibold underline hover:underline-offset-2 hover:font-bold transition"
            >
              Get Direction
            </button>
          </div>
          {isMapOpen && (
            <div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
              role="dialog"
              aria-labelledby="map-title"
              aria-describedby="map-description"
              aria-live="polite"
            >
              <div className="relative w-full h-full">
                <h2 id="map-title" className="sr-only">
                  Location Map
                </h2>
                <p id="map-description" className="sr-only">
                  Google Maps showing the location of Mindful Connection Hub.
                </p>
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.540169040174!2d71.49348654902591!3d30.24473063565357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b35d1895d240f%3A0xf4f27e5b3de72dbb!2sMindfulConnection%20Hub!5e0!3m2!1sen!2s!4v1699195768584!5m2!1sen!2s"
                  allowFullScreen
                  aria-hidden="false"
                ></iframe>
                <button
                  onClick={closeMap}
                  aria-label="Close map"
                  className="absolute top-4 right-4 bg-white text-black py-2 px-4 rounded-full shadow-md hover:bg-gray-200 transition focus:ring-2 focus:ring-gray-500 focus:outline-none"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Contact Section */}
        <div
          className="flex flex-col w-full md:w-1/3 bg-[#7b9789] hover:bg-[#789082] p-6 min-h-[200px] justify-center items-center text-center"
          aria-label="Contact details"
        >
          <div className="flex flex-col items-center justify-center text-sm">
            <span className="text-white  text-4xl" aria-hidden="true">
              <FaPhoneAlt />
            </span>
            <p className="text-lg  font-sans text-white font-medium leading-relaxed mt-4">
              <a
                href="tel:0616510016"
                className=" underline hover:underline-offset-2"
                aria-label="Call us at 061-6510016"
              >
                061-6510016
              </a>
              <br />
              <a
                href="mailto:Contact@mindfulch.com"
                className=" underline hover:underline-offset-2"
                aria-label="Email us at Contact@mindfulch.com"
              >
                Contact@mindfulch.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoSection;
