import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Header from "../Comp/Header";
import img1 from "../../assets/mental-health-concept-illustration_114360-2589.jpg";
import SocialMediaLinks from "../Comp/SocialMediaLinks";
import WhatsAppButton from "../Comp/WhatsAppButton";
import Sidebar from "../Comp/BlogSideBar";


function ScienceOfHappiness() {
  const pageTitle = "Welcome to Our Website!";
  const pageDescription = "This is a brief description of the homepage.";
  const pageUrl = window.location.href; // You can dynamically fetch the URL
  return (
    <>
      <div>
        <TopNav transparent />
        <Header leftTitle="The Science of Happiness" breadcrumbTitle="Blogs" />
        {/* Image */}
        <div className="flex justify-center items-center py-10 mt-4 px-4 sm:px-8 md:px-16 h-full w-full">
          <img
            src={img1}
            alt="Centered Example"
            className="object-fill rounded-xl shadow-xl shadow-[#f7ece1] h-[300px]  md:h-[400px] lg:h-[500px]"
          />
        </div>

        <div className="flex py-10 px-4 sm:px-8 md:px-16 text-gray-700">
          <div className="content w-full md:w-3/4 p-8">
            <h2 className="text-2xl font-semibold mb-2 mt-4">
              The Science of Happiness: How to Train Your Brain for Positivity
            </h2>
            <p className="text-base font-normal">
              What if I told you that happiness isn’t just a random feeling but
              a skill you can train—just like learning a new language or
              building muscle at the gym? Sounds intriguing, right? Well,
              science backs it up! Your brain has the incredible ability to
              rewire itself through neuroplasticity and happiness, meaning you
              can actively shape your mindset for more joy, resilience, and
              positivity.
            </p>
            <p className="text-base font-normal">
              But how do we do it? Let’s explore the science of happiness and
              uncover some real-life, practical ways to train your brain for
              lasting positivity.
            </p>

            {/*  The Brain’s Happiness Formula (Yes, It Exists!)  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              The Brain’s Happiness Formula (Yes, It Exists!)
            </h2>
            <p className="text-base font-normal">
              Ever wonder why negative thoughts stick like glue while positive
              ones slip away? Blame it on negativity bias and positivity—an
              ancient survival mechanism that kept our ancestors alert to
              dangers like lurking predators. While that was great for avoiding
              saber-toothed tigers, it’s not so useful when we’re overanalyzing
              an awkward conversation from last week.
            </p>
            <p className="text-base font-normal">
              Luckily, the brain is like Play-Doh—it can be reshaped with
              intentional effort. When we focus on daily happiness habits, we
              strengthen neural pathways associated with positive thinking
              techniques, making joy and optimism our brain’s default setting.
            </p>
            <p className="text-base font-normal">
              So, let’s get into the happiness workout plan—no gym membership
              required!
            </p>

            {/* 1. The Power of Gratitude: Rewire Your Brain in 5 Minutes a Day */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              1. The Power of Gratitude: Rewire Your Brain in 5 Minutes a Day
            </h2>
            <p className="text-base font-normal">
              Gratitude and positivity go hand in hand. Studies show that
              practicing gratitude boosts serotonin and dopamine—the same
              neurotransmitters activated by chocolate, love, and winning the
              lottery (minus the financial windfall).
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: Sarah, a stressed-out corporate worker, started a "3
              Blessings Journal" where she wrote down three good things that
              happened every night. At first, it felt forced. But within two
              weeks, she noticed her mood improving, stress levels dropping, and
              even her colleagues seemed friendlier (or maybe she was just
              noticing the good more).
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
              Try This: Every night, write down three things you’re grateful
              for—big or small. The smell of morning coffee? A kind text from a
              friend? Your pet’s goofy antics? Your brain will start scanning
              for positivity naturally.
            </p>

            {/*    2. Flip the Script: Reframe Negative Thoughts Like a Pro */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              2. Flip the Script: Reframe Negative Thoughts Like a Pro
            </h2>
            <p className="text-base font-normal">
              Your thoughts create your reality. Ever notice how one bad moment
              (spilling coffee on your shirt) can make an entire day feel awful?
              That’s because our brain clings to negativity bias, making it hard
              to stay positive. But here’s the good news: You can reframe
              negative thoughts and shift your perspective.
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: John, a university student, bombed an important exam.
              Instead of spiraling into self-doubt, he told himself, “This is
              just a learning curve. I’ll use this as motivation to improve.” He
              changed his study techniques, sought guidance, and aced the next
              one.
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
              Try This: Next time a negative thought creeps in, challenge it.
              Ask yourself:
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                Is this thought 100% true?
              </li>
              <li className="text-base text-black leading-relaxed">
                What’s another way to look at this?.
              </li>
              <li className="text-base text-black leading-relaxed">
                What lesson can I learn?
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              Your brain is listening. Train it to find solutions, not problems.
            </p>

            {/*  3. Acts of Kindness: The Secret Shortcut to Happiness  */}
            <h2 className="text-xl font-semibold mb-2 mt-6">
              3. Acts of Kindness: The Secret Shortcut to Happiness
            </h2>
            <p className="text-base font-normal">
              Ever felt a warm glow after helping someone? That’s because
              kindness triggers a happiness cascade in your brain, releasing
              oxytocin and serotonin—also known as the "helper’s high."
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: Lisa struggled with loneliness. She decided to start
              volunteering at a local shelter once a week. Not only did she form
              new friendships, but her mood skyrocketed—all because she shifted
              her focus from her own worries to making a difference.
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
              Try This: Do one small act of kindness today. Compliment someone.
              Hold the door open. Send a “just thinking of you” message. The
              ripple effect is real!
            </p>

            {/*  4. Mindfulness & Meditation: The Ultimate Brain Gym */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              4. Mindfulness & Meditation: The Ultimate Brain Gym
            </h2>
            <p className="text-base font-normal">
              Mindfulness and meditation are powerful happiness
              neurotransmitters that can shrink the amygdala (the brain’s fear
              center) and boost the prefrontal cortex (the decision-making,
              rational-thinking part). Translation? Less stress, more clarity.
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: David, a high-stakes businessman, found himself
              constantly overwhelmed. He started meditating for just 10 minutes
              a day using a mindfulness app. Within a month, he noticed better
              focus, lower anxiety, and more patience in high-pressure meetings.
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                Spend one minute focusing on your breath.
              </li>
              <li className="text-base text-black leading-relaxed">
                Eat your meal without distractions (yes, that means no
                scrolling).
              </li>
              <li className="text-base text-black leading-relaxed">
                Try a guided meditation (tons of free ones online).
              </li>
            </ul>

            {/*  5. The People You Surround Yourself With Matter  */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              5. The People You Surround Yourself With Matter
            </h2>
            <p className="text-base font-normal">
              Science proves it: Happiness is contagious! Surrounding yourself
              with positive, supportive people boosts dopamine levels and
              enhances mental well-being strategies.
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: Emma moved to a new city and felt lonely. Instead of
              isolating herself, she joined a local book club and started
              attending social events. Within months, she had built strong
              friendships that completely transformed her emotional state.
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                Connect with positive people who uplift you.
              </li>
              <li className="text-base text-black leading-relaxed">
                Limit exposure to negativity (toxic news, social media, or
                people who drain you).
              </li>
              <li className="text-base text-black leading-relaxed">
                Be that positive energy for others.
              </li>
            </ul>

            {/*  6. Move Your Body, Boost Your Mood */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              6. Move Your Body, Boost Your Mood
            </h2>
            <p className="text-base font-normal">
              You don’t need to run a marathon, but moving your body
              supercharges happiness by releasing endorphins (natural mood
              boosters).
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Example:
              </span>{" "}
              Example: Michael was stuck in a rut, feeling unmotivated and
              anxious. Instead of forcing himself into an intense workout, he
              started with a 15-minute daily walk. The fresh air, movement, and
              routine transformed his mindset—and soon, he felt healthier and
              happier.
            </p>
            <p className="text-base font-normal mt-2">
              <span className="text-base mb-2 text-black font-semibold">
                Try This:
              </span>{" "}
            </p>

            <ul className="list-disc list-inside mt-3">
              <li className="text-base text-black leading-relaxed">
                Dance to your favorite song.
              </li>
              <li className="text-base text-black leading-relaxed">
                Take a walk outside (bonus points for nature).
              </li>
              <li className="text-base text-black leading-relaxed">
                Stretch for 5 minutes.
              </li>
            </ul>
            <p className="text-base font-normal mt-2">
              Your body and brain will thank you.
            </p>

            {/* Final Thoughts: Happiness is a Skill—Train It Daily! */}
            <h2 className="text-2xl font-semibold mb-2 mt-6">
              Final Thoughts: Happiness is a Skill—Train It Daily!
            </h2>
            <p className="text-base font-normal">
              The science of happiness shows us that joy isn’t about waiting for
              the perfect job, partner, or bank balance—it’s about what you do
              every day. By practicing gratitude, reframing negative thoughts,
              being kind, staying mindful, nurturing relationships, and moving
              your body, you rewire your brain for joy.
            </p>
            <p className="text-base font-normal">
              <span className="text-base mb-2 text-black font-semibold">
                Start small. Stay consistent. Watch your happiness grow.
              </span>{" "}
            </p>
          </div>
         <Sidebar />
        </div>
        <SocialMediaLinks
          url={pageUrl}
          title={pageTitle}
          description={pageDescription}
        />
        <WhatsAppButton />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default ScienceOfHappiness;
