import { useState } from "react";
import TopNav from "./TopNav";
import Header from "./Comp/Header";
import Footer from "./Footer";
import InfoSection from "./Comp/InfoSection";
import ArticleSection from "./Comp/ArticleSection";
import WhatsAppButton from "./Comp/WhatsAppButton";
import img0 from "../assets/flowers-crop-4.webp";

const articles = [
  {
    id: 1,
    title: "The Science of Happiness: How to Train Your Brain for Positivity",
    date: "Feb 14, 2025",
    author: "Zarnab Kamal",
    excerpt: "What if I told you that happiness isn’t just a random feeling but a skill you can train...",
    image: require("../assets/mental-health-concept-illustration_114360-2589.jpg"),
    link: "/the-science-of-happiness",
  },

  {
    id: 2,
    title: "The Rise of ‘Situationships’: Why Social Media Makes It Harder to Define Relationships ",
    date: "Feb 7, 2025",
    author: "Zarnab Kamal",
    excerpt: "Ever found yourself texting someone all day, every day, sharing memes, deep conversations...",
    image: require("../assets/close-up-couple-standing.jpg"),
    link: "/the-rise-of-situationships",
  },

  {
    id: 3,
    title: "The TikTok Effect: How Social Media is Reshaping Mental Health in Youth ",
    date: "Feb 6, 2025",
    author: "Zarnab Kamal",
    excerpt: "In today’s digital age, social media platforms have become integral to daily life. Among them...",
    image: require("../assets/young-people-using-reels_23-2150038634.jpg"),
    link: "/the-tiktok-effect",
  },
  {
    id: 4,
    title: "The Power of Micro-Actions: Small Changes That Have a Big Psychological Impact ",
    date: "Feb 4, 2025",
    author: "Zarnab Kamal",
    excerpt: "Have you ever set a big goal, felt motivated for a few days, and then completely abandoned it...",
    image: require("../assets/futuristic-image-womans-profile-with-digital-brain-overlay-showcasing-ai-neuroscience-inno_715671-15861.jpg"),
    link: "/the-power-of-micro-actions",
  },
  {
    id: 5,
    title: "Doomscrolling & Mental Health: Why We Can’t Stop and How It’s Messing with Us ",
    date: "Feb 3, 2025",
    author: "mindfulch.com",
    excerpt:
      "You open your phone to “just check something real quick,” and suddenly—BAM—you’re an hour deep into...",
    image: require("../assets/concept-person-suffering-from-cybersickness.jpg"),
    link: "/doomscrolling-and-mental-health",
  },
  {
    id: 6,
    title: "The Silent Epidemic: Why Anxiety is the New Normal",
    date: "Feb 2, 2025",
    author: "mindfulch.com",
    excerpt:
      "In today’s fast-paced, hyper-connected world, there’s an invisible epidemic quietly spreading—anxiety...",
    image: require("../assets/woman-mask-stands-by-window_1157-31619.jpg"),
    link: "/the-silent-epidemic",
  },
  {
    id: 7,
    title: "The Rise of Social Media Influencers: How Gen Z is Redefining Fame and Success",
    date: "Jan 30, 2025",
    author: "mindfulch.com",
    excerpt:
      "Fame has evolved in the digital age, thanks to the rise of social media influencers. No longer just movie stars...",
    image: require("../assets/collage-people-using-reels_23-2149416504.jpg"),
    link: "/rise-of-social-media-influencers",
  },
  {
    id: 8,
    title: "Gen Alpha and Emotional Intelligence: More Empathetic or Losing Touch?",
    date: "Jan 16, 2025",
    author: "mindfulch.com",
    excerpt:
      "Have you ever noticed a toddler expertly swiping through a smartphone before they can even form full sentences...",
    image: require("../assets/technology-concept-with-young-kids_23-2147782136.jpg"),
    link: "/gen-alpha-and-emotional-intelligence",
  },
  {
    id: 9,
    title: "Understanding Hearing Loss: A Psychological Perspective",
    date: "Dec 24, 2024",
    author: "mindfulch.com",
    excerpt:
      "Hearing loss, an often-overlooked condition, carries profound implications for communication, development...",
    image: require("../assets/portrait-distraught-girl-covering-her-ears-with-two-hands_23-2148026301.jpg"),
    link: "/hearing-loss",
  },
  {
    id: 10,
    title: "Virtual Autism: Understanding the Impact of Screen Time on Child Development",
    date: "Nov 04, 2024",
    author: "mindfulch.com",
    excerpt:
      "Introduction With technology becoming an integral part of our lives, children are often exposed to...",
    image: require("../assets/mobile_677411-2575.jpg"),
    link: "/virtual-autism",
  },
  {
    id: 11,
    title: "Digital Detox: Key to IT Professionals’ Health & Productivity",
    date: "Aug 20, 2024",
    author: "mindfulch.com",
    excerpt:
      "Introduction In today's sociotechnical world, IT professionals often spend more than 10 hours a day in...",
    image: require("../assets/anxiety-inducing-imagery-with-angst-feelings.jpg"),
    link: "/digital-detox",
  },
  
];

const ArticleCard = ({ article }) => {
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 px-10">
      <a href={article.link}>
        <div className="relative flex flex-col min-w-0 break-words w-full h-full shadow-lg hover:shadow-[#cce1d4] transition-all ease-in-out duration-300 ">
          <div className="overflow-hidden rounded-t-lg">
            <img
              alt={article.title}
              src={article.image}
              loading="lazy"
              className="w-full h-80 object-cover transition-transform duration-300 transform hover:scale-105"
            />
          </div>
          <blockquote className="relative p-4 lg:p-6 xl:p-8 mb-4 flex-grow">
            <h4 className="text-lg font-bold text-stone-700">{article.title}</h4>
            <p className="text-md font-normal mt-2 text-stone-500">
              by {article.author} | {article.date}
            </p>
            <p className="text-md font-normal mt-2 text-stone-700">
              {article.excerpt}
            </p>
          </blockquote>
        </div>
      </a>
    </div>
  );
};

function Resources() {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const totalPages = Math.ceil(articles.length / articlesPerPage);

  const currentArticles = articles.slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage
  );

  return (
    <>
      <TopNav transparent />
      <main>
        <Header leftTitle="Blogs & Articles" breadcrumbTitle="Blogs" />
        <section>
         

          <div className="flex items-center justify-center space-x-4 relative ">
            {/* Image on the left, fixed in place */}
            <div className="absolute inset-0 -z-10">
              <img
                src={img0}
                alt="Background"
                className="w-auto h-auto object-cover opacity-40 lg:opacity-50 "
              />
            </div>

            {/* Text in the center */}
            <div className="relative z-10 text-center w-full max-w-2xl px-8 lg:py-4 ">
              <h2 className="mt-20  text-2xl lg:text-3xl py-1 text-transparent bg-clip-text bg-gradient-to-r from-[#68d468] to-[#5aa37b] font-sans font-semibold rounded-lg mb-2">
              Mindful Reads
              </h2>
              <p className="text-base lg:text-lg font-semibold text-gray-600 px-4 ">
              Explore Our Latest Thoughts on Mental Health and Well-Being
              </p>
            </div>
          </div>
        </section>

        <section className="mx-auto px-4 md:px-8 py-20 mb-12">
        <div className="flex flex-wrap gap-y-16">
          {currentArticles.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </div>
      </section>

     {/* Pagination */}
     <div className="flex justify-center items-center space-x-2 my-8">
        {/* Previous Button */}
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`px-3 py-1 rounded-md transition-all ${
            currentPage === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {/* Page Numbers */}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-3 py-1 rounded-md transition-all ${
              currentPage === index + 1
                ? "bg-[#459459] text-white font-bold"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Button */}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          className={`px-3 py-1 rounded-md transition-all ${
            currentPage === totalPages
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

        <ArticleSection />
        <InfoSection />
        <WhatsAppButton />
      </main>
      <Footer />
    </>
  );
}

export default Resources;