import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/GettyImages-1335839577-43aabea707074ae59e49d709f83c77b4.jpg";
import imgy from "../../assets/pexels-photo-4101143.jpeg";
import imgz from "../../assets/360_F_477102217_aP7E3WdgPKK3sxv9beylmt7rDWEgIPvw.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/GettyImages-1198265963-1024x683.webp";
import img2 from "../../assets/depositphotos_236221024-stock-photo-depressed-man-hiding-his-eyes.jpg";
import img3 from "../../assets/ai-generative-a-man-practicing-mindfulness-and-meditation-in-a-peaceful-natural-environment-sony-a7s-realistic-image-ultra-hd-high-design-very-detailed-free-photo.jpg";
import img4 from "../../assets/istockphoto-930377682-612x612.jpg";

function Disorder() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Interpersonal Therapy",
      description:
        "Interpersonal Therapy (IPT) is a structured and time-limited psychotherapy that focuses...",
      link: "/interpersonal",
    },
    {
      imgSrc: imgy,
      title: "Counseling",
      description:
        "Counseling is a professional, therapeutic relationship aimed at helping individuals navigate...",
      link: "/counseling",
    },
    {
      imgSrc: imgz,
      title: "Speech Therapy",
      description:
        "Speech therapy is a specialized field focused on diagnosing and treating communication and...",
      link: "/speechtherapy",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Psychological Disorder
          </h1>
          <p className="text-base text-black leading-relaxed">
            Psychological disorders, also known as mental disorders or mental
            illnesses, are patterns of behavior or psychological symptoms that
            impact multiple areas of life. These disorders create distress for
            the person experiencing these symptoms and/or impair their ability
            to function. Psychological disorders can affect mood, thinking, and
            behavior.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Common Types of Psychological Disorders
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Anxiety Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Mood
            Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Obsessive-Compulsive and Related Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Trauma- and Stressor-Related Disorders.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Psychotic Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Eating
            Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Personality Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Neurodevelopmental Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Neurocognitive Disorders
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Substance-Related and Addictive Disorders
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Symptoms
          </h1>
          <p className="text-base text-black leading-relaxed">
            Symptoms of psychological disorders vary widely depending on the
            specific condition but often include:
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Changes in mood, such as depression or elation.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Excessive fears or worries.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Withdrawal from friends and activities.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Significant tiredness, low energy, or problems sleeping.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Delusions or hallucinations.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Inability to cope with daily problems or stress.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Trouble understanding and relating to situations and people.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Alcohol or drug abuse.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span> Major
            changes in eating habits.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Suicidal thinking.
          </p>
        </>
      ),
    },
    {
      img: img4,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Treatment
          </h1>
          <p className="text-base text-black leading-relaxed">
            Treatment for psychological disorders often includes a combination
            of:
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Psychotherapy
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Medication
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Lifestyle Changes
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-3xl mb-2 text-black font-bold">.</span>{" "}
            Support Groups
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Psychological Disorder Treatment"
          breadcrumbTitle="Services"
        />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/angermanage"
          prevText="Anger Management"
          nextLink="/speechissues"
          nextText="Speech Issues"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default Disorder;
