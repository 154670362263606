import React from "react";
import Header from "../Comp/Header";
import defaultImage from "../../assets/womans-head.jpg";
import ArticleCards from "../Comp/ArticleCards";
import imgx from "../../assets/360_F_82442519_3dyKdM0EXzhBO027kDLkeJHuPLeplLlf.jpg";
import imgy from "../../assets/istockphoto-472977300-612x612.jpg";
import imgz from "../../assets/pexels-olly-3755761.jpg";
import TopNav from "../TopNav";
import Footer from "../Footer";
import Form from "../Comp/Form";
import Pagination from "../Comp/Pagination";
import BrowseButton from "../Comp/BrowseButton";
import Text from "../Comp/Text";
import img1 from "../../assets/dialectical-behavior-therapy-overview-guide-1440x810.jpg";
import img2 from "../../assets/dbt-chart.jpg";
import img3 from "../../assets/suiciderisk6-1024x683.jpg";

function Dbt() {
  const cards = [
    {
      imgSrc: imgx,
      title: "Career Counseling",
      description:
        "Career counseling is a professional service that helps individuals understand their strengths, interests...",
      link: "/career",
    },
    {
      imgSrc: imgy,
      title: "Special Needs Issues",
      description:
        "Special needs issues refer to a variety of physical, developmental, behavioral, or emotional conditions...",
      link: "/specialneeds",
    },
    {
      imgSrc: imgz,
      title: "Stress Management",
      description:
        "Stress is a natural physical and emotional response to challenges, demands, or threats...",
      link: "/stressmanage",
    },
  ];
  const cardData = [
    {
      img: img1,
      text: (
        <>
          <h1 className="text-3xl font-semibold mb-4 text-black leading-relaxed">
            Dialectical Behavior Therapy (DBT)
          </h1>
          <p className="text-base text-black leading-relaxed">
            Dialectical Behavior Therapy (DBT) is a type of cognitive-behavioral
            therapy developed by Dr. Marsha Linehan in the late 1980s. It was
            originally designed to treat borderline personality disorder (BPD)
            but has since been adapted to treat a variety of mental health
            conditions, including depression, anxiety, suicidal ideation, eating
            disorders, and substance abuse.
          </p>
        </>
      ),
    },
    {
      img: img2,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Key Components of DBT
          </h1>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Mindfulness:
            </span>{" "}
            This involves being fully aware and present in the moment.
            Mindfulness skills help individuals to observe their thoughts and
            feelings without judgment, improving emotional regulation and
            decreasing reactivity.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Distress Tolerance:
            </span>{" "}
            These skills are aimed at helping individuals tolerate and survive
            crises without resorting to self-destructive behaviors. Techniques
            include distraction, self-soothing, and improving the moment.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Emotion Regulation:
            </span>{" "}
            This focuses on understanding and reducing vulnerability to intense
            emotions, identifying and labeling emotions, and developing
            strategies to manage and change emotions.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">
              Interpersonal Effectiveness:
            </span>{" "}
            These skills help individuals to communicate more effectively and
            assertively while maintaining self-respect and strengthening
            relationships. Techniques include how to ask for what one needs,
            saying no, and coping with interpersonal conflict.
          </p>
        </>
      ),
    },
    {
      img: img3,
      text: (
        <>
          <h1 className="text-2xl font-semibold mb-4 text-black leading-relaxed">
            Goals of DBT
          </h1>
          <p className="text-base text-black leading-relaxed">
            To help individuals build a life worth living by balancing
            acceptance and change.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">1.</span> To
            reduce self-destructive behaviors and improve emotional and
            cognitive regulation.
          </p>
          <p className="text-base text-black leading-relaxed">
            <span className="text-base text-black font-semibold">2.</span> To
            enhance the ability to manage stress and improve relationships.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopNav transparent />
        <Header
          leftTitle="Dialectical Behaviour Therapy"
          breadcrumbTitle="Services"
        />
        {cardData.map((card, index) => (
          <Text
            key={index}
            img={card.img}
            heading={card.heading}
            text={card.text}
            isReversed={index % 2 !== 0} // Reverse layout for the second card (index 1)
          >
            {card.text} {/* Passing the text content as children */}
          </Text>
        ))}
        <Pagination
          prevLink="/speechtherapy"
          prevText="Speech Therapy"
          nextLink="/stressmanage"
          nextText="Stress Management"
        />
        <BrowseButton />
        <ArticleCards cards={cards} />
        
        <Footer />
      </div>
    </>
  );
}

export default Dbt;
