import React, { useState } from "react";
import { motion } from "framer-motion";
import { X } from "lucide-react";

// Import images
import Img1 from "../assets/clinical-psychologist-in-multan.webp";
import Img2 from "../assets/Mental Health psychologist in-Multan.webp";
import Img3 from "../assets/psychotherapy-and-counselling.webp";
import Img4 from "../assets/psychology counseling in Multan-3.webp";
import Img5 from "../assets/Mental Health psychologist.webp";
import Img6 from "../assets/Habiba Nazim Khan MCH Clinic.webp";
import Img7 from "../assets/MCH By Habiba Nazim Khan.webp";
import Img8 from "../assets/Psychologist - Habiba Nazim Khan.webp";
import Img9 from "../assets/Mental-Health-Doctor.webp";
import Img10 from "../assets/Psychologist and therapist.webp";

// Array of images
const images = [
  { src: Img1, alt: "Clinical psychologist in multan" },
  { src: Img2, alt: "Mental Health psychologist in-Multan" },
  { src: Img3, alt: "psychotherapy and counselling" },
  { src: Img9, alt: "mental health doctor" },
  { src: Img5, alt: "Mental Health psychologist" },
  { src: Img8, alt: "Psychologist - Habiba Nazim Khan" },
  { src: Img7, alt: "MCH By Habiba Nazim Khan" },
  { src: Img6, alt: "Habiba Nazim Khan MCH Clinic" },
  { src: Img10, alt: "Psychologist and therapist" },
  { src: Img4, alt: "psychology counseling in Multan" },
];

export default function InfiniteCarousel() {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="relative overflow-hidden w-full border-b-8 border-t-8 border-[#71a371]">
      {/* Image Carousel */}
      <motion.div
        className="flex"
        animate={isHovered ? {} : { x: ["0%", "-100%"] }}
        transition={{
          ease: "linear",
          duration: 20,
          repeat: Infinity,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {[...images, ...images].map((image, index) => (
          <div
            key={index}
            className="relative flex-shrink-0  group cursor-pointer"
            onClick={() => setSelectedImage(image.src)}
          >
            {/* Image */}
            <img
              src={image.src}
              alt={image.alt}
              className="h-32 md:h-44 lg:h-72 w-auto object-cover"
            />

            {/* Overlay + Plus Icon (Appears on Hover) */}
            <div className="absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition duration-300">
              <span className="text-black text-4xl font-bold">+</span>
            </div>
          </div>
        ))}
      </motion.div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <div className="relative">
            <button
              className="absolute top-4 right-4 bg-white rounded-full p-2"
              onClick={() => setSelectedImage(null)}
            >
              <X size={24} />
            </button>
            <img src={selectedImage} alt="Selected" className="max-w-full max-h-screen" />
          </div>
        </div>
      )}
    </div>
  );
}
