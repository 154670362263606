import React from "react";

function ArticleCards({ cards }) {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 py-14 px-8 lg:px-32 mb-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="flex flex-col shadow-md shadow-[#e0ebe4] transition ease-in-out duration-300 rounded-lg"
          >
            <a href={card.link}>
              <div className="flex flex-col overflow-hidden">
                {" "}
                {/* Add overflow-hidden here */}
                <img
                  src={card.imgSrc}
                  alt="Icon"
                  loading="lazy"
                  className="h-64 transition-transform duration-300 transform hover:scale-110"
                />
              </div>
              <p className="font-semibold text-lg text-stone-800 pt-5 text-left px-2">
                {card.title}
              </p>
              <p className="text-base text-stone-600 pt-3 mb-6 text-left px-2">
                {card.description}
              </p>
            </a>
          </div>
        ))}
      </div>
    </>
  );
}

export default ArticleCards;
